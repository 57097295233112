import { Collapse, IconButton, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CmtImage from '../../@coremat/CmtImage';
import AuthWrapper from '../../@jumbo/components/Common/authComponents/AuthWrapper';
import ContentLoader from '../../@jumbo/components/ContentLoader';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants';
import { setProcessing } from '../../redux/actions/Processing';
import Translation from '../../i18n/entries/Service';
import Swal from 'sweetalert2';
import cryptoJS from 'crypto-js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {NavLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const PhoneVerification = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [open, setOpen] = React.useState(false);
  const [countryCodeList, setCountyCodeList] = useState([]);
  const [phonenumber, setPhonenumber] = useState('');
  const [countryCode, setCountryCode] = useState(62);
  const [step, setStep] = useState('STEP1');
  const [otpcode, setOtpcode] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const { is_processing } = useSelector(({ processing }) => processing);

  const verifyOtpCode = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));
    let token = await handleGetTokenRecaptcha();

    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += otpcode.toString();

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tableuserphoneverificationcode: otpcode.toString(),
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/phonenumberverify',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          const user = {
            usernick: index.usernick,
            username: index.username,
            userindex: index.userindex,
            usertoken: index.usertoken,
            userref: index.userref,
            user2fatype: index.user2fatype,
            userphonenumber: index.userphonenumber,
            userphonecountrycode: countryCode,
            userphonenumbershort: phonenumber,
          };
          localStorage.setItem('user', JSON.stringify(user));

          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: Translation.getServiceLanguage(res.status.code),
          });
          setTimeout(() => {
            window.location.href = '/profile';
          }, 2000);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(function(error) {
        alert(error);
      });
  };

  // const storePhonenumber = async () => {
  //   dispatch(setProcessing(true));
  //   let index = JSON.parse(localStorage.getItem('user'));
  //   let token = await handleGetTokenRecaptcha();

  //   var String = '';

  //   String += 'WEBSITE';
  //   String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
  //   String += index.userindex;
  //   String += index.usertoken;
  //   String += countryCode.toString();
  //   String += phonenumber.toString();

  //   let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

  //   let parameters = {
  //     platform: 'WEBSITE',
  //     gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
  //     userindex: index.userindex,
  //     username: index.username,
  //     tokenlogin: index.usertoken,
  //     tableuserphonecountrycode: parseInt(countryCode),
  //     tableuserphonenumbershort: parseInt(phonenumber),
  //     signature: signature.toString(),
  //   };

  //   console.log({ postParams: parameters });

  //   await axios
  //     .post(
  //       process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/phonenumberstore',
  //       parameters,
  //     )
  //     .then(response => {
  //       let res = response.data;
  //       console.log(res);
  //       if (res.status.code === '080000000000') {
  //         // var oldStorageUser = JSON.parse(localStorage.getItem('user')) || [];

  //         // var newItem = {
  //         //   userphonecountrycode: countryCode,
  //         //   userphonenumbershort: phonenumber,
  //         // };

  //         // oldStorageUser.push(newItem);

  //         // localStorage.setItem('user', JSON.stringify(oldStorageUser));

  //         const user = {
  //           usernick: index.usernick,
  //           username: index.username,
  //           userindex: index.userindex,
  //           usertoken: index.usertoken,
  //           userref: index.userref,
  //           user2fatype: index.user2fatype,
  //           userphonenumber: index.userphonenumber,
  //           userphonecountrycode: countryCode,
  //           userphonenumbershort: phonenumber,
  //         };
  //         localStorage.setItem('user', JSON.stringify(user));

  //         // setTimeout(() => {
  //         //   window.location.href = '/profile';
  //         // }, 1000);
  //       } else {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Oops!',
  //           text: Translation.getServiceLanguage(res.status.code),
  //         });
  //       }
  //       dispatch(setProcessing(false));
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //     });
  // };

  const requestOtpCode = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));
    // let token = await handleGetTokenRecaptcha();

    // var String = '';

    // String += 'WEBSITE';
    // String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    // String += index.userindex;
    // String += index.usertoken;
    // String += countryCode.toString();
    // String += phonenumber.toString();

    // let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let headers = {
      headers:{
        platform: 'WEBSITE',
        gtoken: "PASSS",
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: "ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7"
      }
    };

    let parameters = {

    }


    //let response = await ProfileControllers.basic.getProfileData(parameters);

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/requestotp',
        parameters,
        headers
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.status === 1) {
          setStep('STEP2');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(function(error) {
        alert(error);
      });
  };

  const loadCountryCodeList = async token => {
    let index = JSON.parse(localStorage.getItem('user'));

    // var String = '';

    // String += 'WEBSITE';
    // String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    // String += index.userindex;
    // String += index.usertoken;

    // let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    

    let headers = {
      headers:{
        platform: 'WEBSITE',
        gtoken: "PASSS",
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: "ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7"
      }
    };

    let parameters = {
    };


    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/insertchangephonenumber',
        parameters,
        headers
      )
      .then(response => {
        // console.log(response);
        let res = response.data;
        setCountyCodeList(res.support);
      })
      .catch(function(error) {
        alert(error);
      });
  };

  const handleChange = event => {
    setCountryCode(event.target.value);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(
    () => {
      // load the script by passing the URL

      const loadScriptRecaptcha = async () => {
        await loadScriptByURL(
          'recaptcha-key',
          `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
          async function() {
            // let recapToken = await handleGetTokenRecaptcha();
            await loadCountryCodeList();
          },
        );
      };
      loadScriptRecaptcha().catch(console.error);
    },
    // eslint-disable-next-line
    [ dispatch ]
  );

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Phone Verification
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            We have send verification code to registered phone number, Please check message inbox.
          </Alert>
        </Collapse>
        {step === 'STEP1' ? (
          <form>
            <div>
              <FormControl style={{ width: '100%' }} variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  {countryCodeList.length <= 0 ? 'Getting data...' : 'Country Code'}
                </InputLabel>

                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={countryCode}
                  onChange={handleChange}
                  label="Country Code">
                  {countryCodeList.length > 1 ? (
                    <MenuItem key={0} value="">
                      <em>-- Select Country Code --</em>
                    </MenuItem>
                  ) : null}
                  {countryCodeList.length > 0 &&
                    countryCodeList.map((data, index) => {
                      return (
                        <MenuItem key={index + 1} value={data.tablecountryphonecodephonecode}>
                          {data.tablecountryphonecodephonecode} - {data.tablecountryphonecodecountryname}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>

            <div>
              <div className={'mb-5'}>
                <TextField
                  type="text"
                  label={'Phone number'}
                  fullWidth
                  onChange={event => setPhonenumber(event.target.value)}
                  defaultValue={phonenumber}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </div>

              <div className={'mb-5'}>
                {is_processing ? (
                  <Button variant="contained" color="primary">
                    Processing...
                  </Button>
                ) : (
                  <Button onClick={requestOtpCode} variant="contained" color="primary">
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : null}

        {step === 'STEP2' ? (
          <form>
            <div>
              <FormControl style={{ width: '100%' }} variant="outlined">
                <TextField
                  type="text"
                  label={'OTP Code'}
                  fullWidth
                  onChange={event => setOtpcode(event.target.value)}
                  defaultValue={otpcode}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </FormControl>
            </div>

            <div>
              <div className={'mb-5'}>
                {is_processing ? (
                  <Button variant="contained" color="primary">
                    Processing...
                  </Button>
                ) : (
                  <Button onClick={verifyOtpCode} variant="contained" color="primary">
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : null}

        {/* {step === 'STEP3' ? (
          <form>
            <div>Please click button below to save verified phone number.</div>

            <div>
              <div className={'mb-5'}>
                {is_processing ? (
                  <Button variant="contained" color="primary">
                    Processing...
                  </Button>
                ) : (
                  <Button onClick={storePhonenumber} variant="contained" color="primary">
                    Store
                  </Button>
                )}
              </div>
            </div>
          </form>
        ) : null} */}
        <NavLink to={"/profile"}
            className="navbar-item"
            activeClassName="is-active"
            >
          Back
          </NavLink>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default PhoneVerification;
