import jsonDWA from '../locales/MWD2022-04-0910_08_41-2.json';

const Language = params => {
  let getDefaultLangApps = localStorage.getItem('LangApps') ? localStorage.getItem('LangApps') : 'EN';
  let getDWAJson = jsonDWA[getDefaultLangApps][params] ? jsonDWA[getDefaultLangApps][params] : params;

  return getDWAJson;
};

const runService = {
  getServiceLanguage(params) {
    return Language(params);
  },
};

export default runService;
