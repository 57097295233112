import loaderContent from '@jumbo/utils/loaderContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Add, ArrowForward, Home, Send, ShoppingCart, History } from '@material-ui/icons';
// import { sidebarNav } from '../menus';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import SVGcoins1 from 'shared/SVG/Asset 1.svg';
// import SVGcoins2 from 'shared/SVG/Asset 2.svg';
// import SVGcoins3 from 'shared/SVG/Asset 3.svg';
// import SVGcoins4 from 'shared/SVG/Asset 4.svg';
// import SVGcoins5 from 'shared/SVG/Asset 5.svg';
import SVGcoins6 from 'shared/SVG/Asset 6.svg';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import cryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'react-simple-snackbar';
import CmtAdvCardContent from '../../../../../@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtCardHeader from '../../../../../@coremat/CmtCard/CmtCardHeader';

const sideBarMenus = [
  {
    name: 'Dashboard',
    type: 'item',
    icon: <Home style={{ color: 'white' }} />,
    link: '/dashboard',
  },
  // {
  //   name: 'Top Up',
  //   type: 'item',
  //   icon: <ShoppingCart style={{ color: 'white' }} />,
  //   link: '/topup',
  // },
  {
    name: 'Transfer',
    type: 'item',
    icon: <Send style={{ color: 'white' }} />,
    link: '/transfer',
  },
  {
    name: 'History',
    type: 'item',
    icon: <History style={{ color: 'white' }} />,
    link: '/history',
  },
];

const useStyles = makeStyles(theme => ({
  wrapperMenuParents: {
    fontWeight: 'bold',
  },
  wrapperMenuChild: {
    fontSize: 10,
    fontColor: '#bbbbbb',
  },
  subTitle: {
    color: theme.palette.text.secondary,
  },
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const SideBar = () => {
  const [walletmenu, setWalletmenu] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isLoaded, setIsloaded] = useState(false);
  const classes = useStyles();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  // const dispatch = useDispatch();

  // const handleGetTokenRecaptcha = () => {
  //   return new Promise((resolve, reject) => {
  //     if (window.grecaptcha) {
  //       window.grecaptcha.ready(() => {
  //         window.grecaptcha
  //           .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
  //           .then(token => {
  //             resolve(token);
  //           })
  //           .catch(error => reject(error));
  //       });
  //     }
  //   });
  // };

  // const callSavedWallet = token => {
  //   return new Promise((resolve, reject) => {
  //     let ApiAddress = process.env.REACT_APP_API_ADDRESS;
  //     let ApiPrefix = process.env.REACT_APP_API_PREFIX;
  //     let RequestURI = ApiAddress + '/' + ApiPrefix;
  //     let params = localStorage.getItem('user') !== '' ? JSON.parse(localStorage.getItem('user')) : '';

  //     var String = '';
  //     String += 'WEBSITE';
  //     String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
  //     String += params.userindex;
  //     String += params.usertoken;

  //     let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

  //     let parameters = {
  //       platform: 'WEBSITE',
  //       gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
  //       userindex: params.userindex,
  //       username: params.username,
  //       tokenlogin: params.usertoken,
  //       signature: signature.toString(),
  //     };

  //     axios
  //       .post(RequestURI + '/savedwallet/v01/list', parameters)
  //       .then(response => {
  //         let respWallet = response.data;
  //         resolve(respWallet.tablesavedwallet);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };

  // const callSavedWalletBalance = token => {
  //   return new Promise((resolve, reject) => {
  //     let ApiAddress = process.env.REACT_APP_API_ADDRESS;
  //     let ApiPrefix = process.env.REACT_APP_API_PREFIX;
  //     let RequestURI = ApiAddress + '/' + ApiPrefix;
  //     let params = localStorage.getItem('user') !== '' ? JSON.parse(localStorage.getItem('user')) : '';

  //     var String = '';
  //     String += 'WEBSITE';
  //     String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
  //     String += params.userindex;
  //     String += params.usertoken;

  //     let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

  //     let parameters = {
  //       platform: 'WEBSITE',
  //       gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
  //       userindex: params.userindex,
  //       username: params.username,
  //       tokenlogin: params.usertoken,
  //       signature: signature.toString(),
  //     };

  //     axios
  //       .post(RequestURI + '/dashboard/v01/userwalletlist/balance', parameters)
  //       .then(response => {
  //         let respBalance = response.data;
  //         resolve(respBalance.tablewallet);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // };

  // const multiWallet = async () => {
  // setIsloaded(true);
  // // let token = await handleGetTokenRecaptcha();
  // // //await multiWallet(recapToken);
  // // let ApiAddress = process.env.REACT_APP_API_ADDRESS;
  // // let ApiPrefix = process.env.REACT_APP_API_PREFIX;

  // // let RequestURI = ApiAddress + '/' + ApiPrefix;

  // // let params = localStorage.getItem('user') !== '' ? JSON.parse(localStorage.getItem('user')) : '';
  // // const menusSavedWallet = [];
  // // const walletBalance = [];
  // // if (params !== null) {
  // //   var _callsavedWallet = await callSavedWallet(token);

  // //   if (_callsavedWallet !== undefined) {
  // //     for (var i = 0; i < _callsavedWallet.length; i++) {
  // //       menusSavedWallet.push({
  // //         name: _callsavedWallet[i].tablesavedwalletname,
  // //         icon: null,
  // //         type: 'collapse',
  // //         children: [
  // //           {
  // //             name: 'Rename',
  // //             icon: <ArrowForward />,
  // //             type: 'item',
  // //             link: '/savedwallet/rename/' + _callsavedWallet[i].tablesavedwalletindex,
  // //           },
  // //           {
  // //             name: 'Inactive',
  // //             icon: <ArrowForward />,
  // //             type: 'item',
  // //             link: '/savedwallet/inactive/' + _callsavedWallet[i].tablesavedwalletindex,
  // //           },
  // //         ],
  // //       });
  // //     }
  // //   }

  // //   var _callSavedWalletBalance = await callSavedWalletBalance(token);

  // //   if (_callSavedWalletBalance !== undefined) {
  // //     for (var n = 0; n < _callSavedWalletBalance.length; n++) {
  // //       walletBalance.push({
  // //         tablewalletindex: _callSavedWalletBalance[n].tablewalletindex,
  // //         tablewalletaccount: _callSavedWalletBalance[n].tablewalletaccount,
  // //         tablewalletbalance: _callSavedWalletBalance[n].tablewalletbalance,
  // //       });
  // //     }
  // //   }

  // //   console.log(walletBalance);
  // // }

  // // if (params !== null) {
  // //   var String = '';

  // //   String += 'WEBSITE';
  // //   String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
  // //   String += params.userindex;
  // //   String += params.usertoken;

  // //   let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

  // //   let parameters = {
  // //     platform: 'WEBSITE',
  // //     gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
  // //     userindex: params.userindex,
  // //     username: params.username,
  // //     tokenlogin: params.usertoken,
  // //     signature: signature.toString(),
  // //   };

  // //   await axios.post(RequestURI + '/dashboard/v01/userwalletlist', parameters).then(function(response) {
  // //     if (response.data.status.status === 1) {
  // //       const userWallet = response.data.tablewallet;

  // //       localStorage.setItem('wallet', userWallet);

  // //       const walletList = [];

  // //       for (let i = 0; i < userWallet.length; i++) {
  // //         walletList.push({
  // //           walletindex: response.data.tablewallet[i].tablewalletindex,
  // //           walletlabel: response.data.tablewallet[i].tablewalletlabel,
  // //           walletaccount: response.data.tablewallet[i].tablewalletaccount,
  // //         });
  // //       }
  // //       localStorage.setItem('wallet', JSON.stringify(walletList));

  // //       const walletsMenus = [];

  // //       console.log(userWallet);

  // //       userWallet.map((wallet, index) => {
  // //         const listIconSidemenu = {
  // //           AccountBalanceWallet: (
  // //             <img
  // //               src={SVGcoins6}
  // //               style={{
  // //                 color: '#3197E9',
  // //                 backgroundColor: '#3197E9',
  // //                 height: 30,
  // //                 width: 30,
  // //                 padding: 2,
  // //                 borderTopRightRadius: 50,
  // //                 borderBottomRightRadius: 50,
  // //               }}
  // //               alt={'alt_listicon'}
  // //             />
  // //           ),
  // //         };

  // //         let findIndex = walletBalance.find(item => item.tablewalletindex === wallet.tablewalletindex);

  // //         walletsMenus.push({
  // //           // name: wallet.tablewalletlabel+' ('+findIndex.tablewalletbalance+')',
  // //           name: (
  // //             <div>
  // //               <span className={classes.wrapperMenuParents}>{wallet.tablewalletlabel}</span>
  // //               <br />
  // //               <span className={classes.wrapperMenuChild}>{findIndex.tablewalletbalance}</span>
  // //             </div>
  // //           ),
  // //           icon: listIconSidemenu.AccountBalanceWallet,
  // //           type: 'collapse',
  // //           children: [
  // //             {
  // //               name: 'List',
  // //               icon: <ArrowForward />,
  // //               type: 'item',
  // //               link: '/wallet/dashboard/' + wallet.tablewalletaccount,
  // //             },
  // //             // {
  // //             //   name: 'Deposit',
  // //             //   icon: <ArrowForward />,
  // //             //   type: 'item',
  // //             //   link: '/deposit/new/' + wallet.tablewalletindex,
  // //             // },
  // //             {
  // //               name: 'Transfer',
  // //               icon: <ArrowForward />,
  // //               type: 'item',
  // //               link: '/transfer/new/' + wallet.tablewalletindex,
  // //             },
  // //           ],
  // //         });
  // //         return '';
  // //       });

  // //       setWalletmenu([
  // //         {
  // //           name: 'Dashboard',
  // //           type: 'section',
  // //           children: [
  // //             {
  // //               name: 'Dashboard',
  // //               icon: <Home />,
  // //               type: 'item',
  // //               link: '/dashboard',
  // //             },
  // //             {
  // //               name: 'New Wallet',
  // //               icon: <Add />,
  // //               type: 'item',
  // //               link: '/wallet/new',
  // //             },
  // //             {
  // //               name: 'Add Saved Wallet',
  // //               icon: <Add />,
  // //               type: 'item',
  // //               link: '/savedwallet/new',
  // //             },
  // //           ],
  // //         },
  // //         {
  // //           name: 'Payment List',
  // //           type: 'section',
  // //           children: walletsMenus, //walletsMenus,
  // //         },
  // //         {
  // //           name: 'Saved Wallet',
  // //           type: 'section',
  // //           children: [],
  // //         },
  // //       ]);

  // //       setIsloaded(false);
  // //     }
  // //   });
  // }

  // };

  useEffect(() => {
    // openSnackbar('Rendering Menu...', 60000);
    // setTimeout(() => {
    //   multiWallet();
    // }, 1000);
    setMenus(sideBarMenus);
    // closeSnackbar();
  }, []);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      {/* {isLoaded ? (
        <div>
          <loaderContent.MyLoader width={300} height={84} speed={1} />
          <loaderContent.MyLoader width={300} height={84} speed={1} />
          <loaderContent.MyLoader width={300} height={84} speed={1} />
        </div>
      ) : ( */}
      <CmtVertical menuItems={menus} />
      {/* )} */}
    </PerfectScrollbar>
  );
};

export default SideBar;
