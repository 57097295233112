import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import axios from 'axios';
import cryptoJS from 'crypto-js';

const Registration = () => {
  const [isRedirect, setRedirect] = useState(false);
  const [isId, setIsId] = useState(null);

  let { refby } = useParams();

  // const dispatch = useDispatch();

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const checkReference = async () => {
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let ApiPrefix = process.env.REACT_APP_API_PREFIX;

    let RequestURI = ApiAddress + '/' + ApiPrefix;

    let token = await handleGetTokenRecaptcha();

    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += refby;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      tableuserreferredby: refby,
      signature: signature.toString(),
    };

    axios.post(RequestURI + '/user/v01/insertnew', parameters).then(function(response) {
      console.log(response.data);
      if (response.data.status.status === 1) {
        const Id = response.data.status.tokentrans;

        setIsId(Id);
        setRedirect(true);
      } else {
        window.location.href = '/coming-soon';
      }
    });
  };

  useEffect(() => {
    const loadScriptByURL = async (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function() {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      function() {
        console.log('Script loaded!');
      },
    );

    setTimeout(function() {
      checkReference();
    }, 2000);
  });
  // }, [refby, dispatch]);

  if (isRedirect) {
    localStorage.setItem('current', `/signup/${refby}/${isId}`);
    return <Redirect to={`/signup/${refby}/${isId}`} />;
  }

  return <div>Please wait we redirecting...</div>;
};

export default Registration;
