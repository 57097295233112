import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSuccess, fetchError } from '../../../../redux/actions';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink, Redirect } from 'react-router-dom';
import axios from 'axios';
import Service from '../../../../i18n/entries/Service';
import cryptoJS from 'crypto-js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Swal from 'sweetalert2';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Divider from '@material-ui/core/Divider';
import TermsCondition from './termscondition';
import PrivacyPolicy from './privacypolicy';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: 680,
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 0,
  p: 10,
  borderRadius: 16,
};

const modalContainer = {
  overflowY: 'scroll',
  height: 500,
  // width: 460,
};

const useStyles = makeStyles(theme => ({
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  hiddenElement: {
    display: 'none',
  },
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  alignLeft: {
    textAlign: 'left',
  },
}));

const SignUp = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [usernameErrorisShow, setUsernameErrorIsShow] = useState(false);

  const [usernick, setUsernick] = useState('');
  const [usernickError, setUsernickError] = useState('');
  const [usernickErrorisShow, setUsernickErrorIsShow] = useState(false);

  const [useremail, setUseremail] = useState('');
  const [useremailError, setUseremailError] = useState('');
  const [useremailErrorisShow, setUseremailErrorIsShow] = useState(false);

  const [phonenumber, setPhonenumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [phoneNumberErrorisShow, setPhonenNumberErrorisShow] = useState(false);

  const [checkedTerms, setCheckedTerms] = useState(true);
  const [modalTerms, setModalTerms] = useState(false);
  const [modalPrivacy, setModalPrivacy] = useState(false);
  const [termsConditionScroll, setTermsConditionScroll] = useState(false);
  const [privacyScroll, setPrivacyScroll] = useState(false);

  const [allRequirementComplete, setAllRequirementComplete] = useState(false);

  const [modalCheckPrivacy, setModalCheckPrivacy] = useState(false);

  const [nickerror, setNickerror] = useState(false);
  const [emailerror, setEmailerror] = useState(false);

  const [errormessage, setErrormessage] = useState([]);
  const [countryCodeList, setCountyCodeList] = useState([]);

  const [tokenTrans, setTokenTrans] = useState('');
  const [countryCode, setCountryCode] = useState(62);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [CountryCodeErrorIsShow, setCountryCodeErrorIsShow] = useState(false);
  const [CountryCodeError, setCountryCodeError] = useState('');
  const [navigateToLogin, setnavigateToLogin] = useState(false);
  // const { refby, id } = useParams();
  // const decryptedId = atob(id);
  // const intId = parseInt(decryptedId);

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const insertNew = () => {
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let ApiPrefix = process.env.REACT_APP_API_PREFIX;

    let RequestURI = ApiAddress + '/' + ApiPrefix;

    return new Promise((resolve, reject) => {
      axios
        .post(
          RequestURI + '/auth/v01/insertnew',
          {},
          {
            headers: {
              gtoken: 'PASSS',
              signature: '91ca4d5ee4fd260cd72d67087bf89ae93eecf761ef90eb934fd06a2366fc7131',
              platform: 'WEBSITE',
            },
          },
        )
        .then(response => {
          let res = response.data;
          resolve(res.status.tokentrans);
        })
        .catch(error => {
          console.log(error);
          reject();
        });
    });
  };
  const backToSignin = () => {
    setnavigateToLogin(true);
  };

  const getCountryCodeList = tokentrans => {
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let ApiPrefix = process.env.REACT_APP_API_PREFIX;

    let RequestURI = ApiAddress + '/' + ApiPrefix;

    let headers = {
      headers: {
        gtoken: 'PASSS',
        signature: '91ca4d5ee4fd260cd72d67087bf89ae93eecf761ef90eb934fd06a2366fc7131',
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          RequestURI + '/auth/v01/detailid',
          {
            tokentrans: tokentrans,
          },
          headers,
        )
        .then(response => {
          let res = response.data;
          resolve();
          setCountyCodeList(res.support);
          setTokenTrans(res.data.tempusertokentrans);
        })
        .catch(error => {
          console.log(error);
          reject();
        });
    });
  };

  const requestSubmit = () => {
    let headers = {
      headers: {
        gtoken: 'PASSS',
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
        platform: 'WEBSITE',
      },
    };
    let parameters = {
      // tableusername: username,
      // tableuserfullname: usernick,
      // tableuseremail: useremail,
      // userphonecountrycode: countryCode,
      // userphonenumbershort: phonenumber,
      tokentrans: tokenTrans,
    };
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let ApiPrefix = process.env.REACT_APP_API_PREFIX;

    let RequestURI = ApiAddress + '/' + ApiPrefix;

    return new Promise(function(resolve, reject) {
      axios.post(RequestURI + '/auth/v02/store', parameters, headers).then(function(response) {
        let res = response.data;
        console.log('res_data', res);
        if (res.status.status === 1) {
          Swal.fire({
            icon: 'success',
            title: 'Check E-mail',
            text: 'Please check your e-mail we send verification',
          });
          resolve();
          setTimeout(() => {
            window.location = '/signin';
          }, 2000);
        } else {
          let arrError = [];
          let arrClear = [];

          if (res.status.message.length > 0) {
            for (var i = 0; i < res.status.message.length; i++) {
              arrError.push(res.status.message[i]);
            }
          }

          if (arrError.find(item => item.code === '888005035005')) {
            setUseremailError('please provide email');
            setUseremailErrorIsShow(true);
            arrClear.push('email');
          }

          if (arrError.find(item => item.code === '888005020015')) {
            setUsernickError('Full Name must only be alphabet and white space');
            setUsernickErrorIsShow(true);
            arrClear.push('nick');
          }

          if (arrError.find(item => item.code === '888005015030')) {
            setUsernameError('please provide user name');
            setUsernameErrorIsShow(true);
            arrClear.push('username');
          }
          if (arrError.find(item => item.code === '888005020005')) {
            setUsernickError('Please provide user full name');
            setUsernickErrorIsShow(true);
            arrClear.push('nick');
          }

          if (arrError.find(item => item.code === '888005035005')) {
            setUseremailError('please provide email');
            setUseremailErrorIsShow(true);
            arrClear.push('email');
          }

          if (arrError.find(item => item.code === '888005060005')) {
            setPhoneNumberError('please provide phone number short');
            setPhonenNumberErrorisShow(true);
            arrClear.push('phone');
          }
          reject(arrClear);
        }
      });
    });
  };

  const clearState = (clearParams = []) => {
    if (clearParams.includes('email')) {
      setUseremail('');
      setUseremailError('');
      setUseremailErrorIsShow(false);
    }

    if (clearParams.includes('username')) {
      setUsername('');
      setUsernameError('');
      setUsernameErrorIsShow(false);
    }

    if (clearParams.includes('nick')) {
      setUsernick('');
      setUsernickError('');
      setUsernickErrorIsShow(false);
    }

    if (clearParams.includes('phone')) {
      setPhonenumber('');
      setPhoneNumberError('');
      setPhonenNumberErrorisShow(false);
    }
    if (clearParams.includes('countryCode')) {
      setCountryCodeError('');
      setCountryCodeErrorIsShow(false);
    }

    if (clearParams.length < 1) {
      // setUsername("");
      setUsernameError('');
      setUsernameErrorIsShow(false);

      setCountryCodeError('');
      setCountryCodeErrorIsShow(false);
      // setUsernick("");
      setUsernickError('');
      setUsernickErrorIsShow(false);

      // setUseremail("");
      setUseremailError('');
      setUseremailErrorIsShow(false);

      // setPhonenumber("");
      setPhoneNumberError('');
      setPhonenNumberErrorisShow(false);
    }
  };

  const onSubmit = () => {
    clearState();
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let ApiPrefix = process.env.REACT_APP_API_PREFIX;

    let RequestURI = ApiAddress + '/' + ApiPrefix;
    let headers = {
      headers: {
        gtoken: 'PASSS',
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
        platform: 'WEBSITE',
      },
    };
    let parameters = {
      tableusername: username,
      tableuserfullname: usernick,
      tableuseremail: useremail,
      userphonecountrycode: countryCode,
      userphonenumbershort: phonenumber,
      tokentrans: tokenTrans,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(RequestURI + '/auth/v02/validate', parameters, headers)
        .then(response => {
          let res = response.data;
          resolve();
          if (res.status.status === 1) {
            if (!checkedTerms) {
              Swal.fire({
                icon: 'info',
                title: 'Information',
                text: 'Terms & Agreement must be checked before register',
              });
            } else {
              setModalTerms(true);
            }
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }
            if (arrError.find(item => item.code === '888005035005')) {
              setUseremailError('please provide email');
              setUseremailErrorIsShow(true);
              arrClear.push('email');
            }

            if (arrError.find(item => item.code === '888005020015')) {
              setUsernickError('please use only alphabet and space for full name');
              setUsernickErrorIsShow(true);
              arrClear.push('nick');
            }

            if (arrError.find(item => item.code === '888005015005')) {
              setUsernameError('please provide user name');
              setUsernameErrorIsShow(true);
              arrClear.push('username');
            }
            if (arrError.find(item => item.code === '888005015025')) {
              setUsernameError('please use 5-20 character for user name');
              setUsernameErrorIsShow(true);
              arrClear.push('username');
            }
            if (arrError.find(item => item.code === '888005015010')) {
              setUsernameError('user name already exists');
              setUsernameErrorIsShow(true);
              arrClear.push('username');
            }
            if (arrError.find(item => item.code === '888005020015')) {
              const error = arrError.find(item => item.code === '888005020015');
              setUsernickError(error.errormassage);
              setUsernickErrorIsShow(true);
              arrClear.push('nick');
            }
            if (arrError.find(item => item.code === '888005015030')) {
              const error = arrError.find(item => item.code === '888005015030');
              setUsernameError(error.errormassage);
              setUsernameErrorIsShow(true);
              arrClear.push('username');
            }
            if (arrError.find(item => item.code === '888005020005')) {
              setUsernickError('please provide user full name');
              setUsernickErrorIsShow(true);
              arrClear.push('nick');
            }

            if (arrError.find(item => item.code === '888005020020')) {
              setUsernickError('please use 5-100 character for full name');
              setUsernickErrorIsShow(true);
              arrClear.push('nick');
            }
            if (arrError.find(item => item.code === '888005015020')) {
              setUsernameError('please use 5-20 character for user name');
              setUsernameErrorIsShow(true);
              arrClear.push('username');
            }
            if (arrError.find(item => item.code === '888025015001')) {
              setCountryCodeError('please select country code');
              setCountryCodeErrorIsShow(true);
              arrClear.push('countryCode');
            }
            // if (arrError.find(item => item.code === '888005035005')) {
            //   setUseremailError('please provide email');
            //   setUseremailErrorIsShow(true);
            //   arrClear.push('email');
            // }

            if (arrError.find(item => item.code === '888005035010')) {
              setUseremailError('Email is already exist');
              setUseremailErrorIsShow(true);
              arrClear.push('email');
            }
            if (arrError.find(item => item.code === '888005035015')) {
              const error = arrError.find(item => item.code === '888005035015');
              setUseremailError(error.errormassage);
              setUseremailErrorIsShow(true);
              arrClear.push('email');
            }

            if (arrError.find(item => item.code === '888005060005')) {
              const error = arrError.find(item => item.code === '888005060005');
              setPhoneNumberError(error.errormassage);
              setPhonenNumberErrorisShow(true);
              arrClear.push('phone');
            }
            if (arrError.find(item => item.code === '888005060010')) {
              setPhoneNumberError('please use 6-15 number for phone number');
              setPhonenNumberErrorisShow(true);
              arrClear.push('phone');
            }
            reject(arrClear);
          }
        })
        .catch(error => {
          console.log(error);
          reject();
        });
    });
  };

  const executeOnSubmit = async () => {
    await requestSubmit()
      .then(() => {
        setTimeout(() => {
          clearState();
        }, 2500);
        setModalPrivacy(false);
        setModalCheckPrivacy(false);
        // setAllRequirementComplete(true);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState(arrClear);
        }, 2500);
        setModalPrivacy(false);
        setModalCheckPrivacy(false);
        // setAllRequirementComplete(true);
      });
  };

  const handleChange = event => {
    setCountryCode(event.target.value);
  };

  const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  const handleTerms = () => {
    setCheckedTerms(!checkedTerms);
  };

  const handleScrollTerms = event => {
    var node = event.target;
    if (Math.trunc(node.scrollHeight - node.clientHeight - node.scrollTop.toFixed(0)) <= 2) {
      setTermsConditionScroll(true);
    } else {
      setTermsConditionScroll(false);
    }
  };
  const handleScrollPrivacy = event => {
    var node = event.target;
    // const bottom = node.scrollHeight - node.scrollTop === node.clientHeight;
    if (Math.trunc(node.scrollHeight - node.clientHeight - node.scrollTop.toFixed(0)) <= 2) {
      setPrivacyScroll(true);
    } else {
      setPrivacyScroll(false);
    }
  };

  const handleNextModal = () => {
    setModalTerms(false);
    setModalPrivacy(true);
  };

  useEffect(() => {
    if (privacyScroll && modalCheckPrivacy) {
      setAllRequirementComplete(true);
    }

    return () => {
      setAllRequirementComplete(false);
    };
  }, [privacyScroll, modalCheckPrivacy]);
  useEffect(() => {
    let temptoken;
    const loadScriptRecaptcha = async () => {
      // await loadScriptByURL(
      //   'recaptcha-key',
      //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //   async function() {

      console.log('Script loaded!');
      temptoken = await insertNew();

      await getCountryCodeList(temptoken);

      console.log(temptoken);
      // },
      // );
    };
    loadScriptRecaptcha().catch(console.error);
    return () => {
      temptoken = '';
    };
  }, []);
  if (navigateToLogin) {
    return <Redirect to="/signin" />;
  }
  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100, width: 250 }} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Create an account
        </Typography>
        <form>
          {/* <Box mb={2}>
            <TextField
              label={Service.getServiceLanguage('tableuserreferredby')}
              fullWidth
              defaultValue={refby}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              disabled
              // eslint-disable-next-line
            />
          </Box> */}

          <Box mb={2}>
            <TextField
              error={usernameErrorisShow ? true : false}
              id={usernameErrorisShow ? 'outlined-error-helper-text' : ''}
              // label={Service.getServiceLanguage('tableusername')}
              label={'Username'}
              fullWidth
              onChange={event => setUsername(event.target.value)}
              value={username}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={usernameError}
            />
          </Box>

          <Box mb={2}>
            <TextField
              error={usernickErrorisShow ? true : false}
              id={usernickErrorisShow ? 'outlined-error-helper-text' : ''}
              label={'Full Name In accordance to identity card'}
              fullWidth
              onChange={event => setUsernick(event.target.value)}
              value={usernick}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={usernickError}
            />
          </Box>

          <Box mb={2}>
            <TextField
              error={useremailErrorisShow ? true : false}
              id={useremailErrorisShow ? 'outlined-error-helper-text' : ''}
              label={Service.getServiceLanguage('tableuseremail')}
              fullWidth
              onChange={event => setUseremail(event.target.value)}
              value={useremail}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={useremailError}
            />
          </Box>

          <Box mb={2}>
            <FormControl style={{ width: '100%' }} variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                {countryCodeList.length <= 0 ? 'Getting data...' : 'Country Code'}
              </InputLabel>

              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={countryCode === -1 ? '' : countryCode}
                onChange={handleChange}
                error={CountryCodeErrorIsShow ? true : false}
                helperText={CountryCodeError}
                label="Country Code">
                {/* {countryCodeList.length > 1 ? (
                  <MenuItem key={0} value="">
                    <em>-- Select Country Code --</em>
                  </MenuItem>
                ) : null} */}
                {countryCodeList.length > 0 &&
                  countryCodeList.map((data, index) => {
                    return (
                      <MenuItem key={index + 1} value={data.tablecountryphonecodephonecode}>
                        {data.tablecountryphonecodephonecode} - {data.tablecountryphonecodecountryname}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {CountryCodeErrorIsShow ? (
              <a
                style={{
                  fontSize: 12,
                  marginLeft: '3%',
                  color: '#f45e5d',
                  fontWeight: 'bold',
                }}>
                {CountryCodeError} please select country code
              </a>
            ) : null}
          </Box>

          <Box mb={2}>
            <TextField
              error={phoneNumberErrorisShow ? true : false}
              id={phoneNumberErrorisShow ? 'outlined-error-helper-text' : ''}
              label={Service.getServiceLanguage('tableuserphonenumber')}
              fullWidth
              onChange={event => setPhonenumber(event.target.value)}
              value={phonenumber}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={phoneNumberError}
            />
          </Box>

          {/* <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControlLabel
              checked={checkedTerms}
              // className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" onClick={handleTerms}/>}
              label="By registering you agree with our term and condition and privacy policy"
            />
            </Box> */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button onClick={onSubmit} variant="contained" color="primary">
                REGISTER
              </Button>
            </Box>

            {/* <Typography className={classes.alrTextRoot}>
              <NavLink to="/signin">
                <IntlMessages id="signUp.alreadyMember" />
              </NavLink>
            </Typography> */}
          </Box>
        </form>

        <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <Typography className={classes.textAcc}>
          Already have an account?
          <NavLink to="/signin">Sign In</NavLink>
        </Typography>
        <p style={{ fontSize: 11, alignSelf: 'center' }}>{process.env.REACT_APP_VERSION}</p>
        <ContentLoader />
      </Box>

      <Modal
        open={modalTerms}
        onClose={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // onScroll={}
      >
        <Box sx={style}>
          <Grid item justifyContent="flex-start" xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Term and Condition
              </Typography>
            </Grid>
            <br />
            <Divider />
            <br />
            <Box sx={modalContainer} onScroll={handleScrollTerms}>
              <Grid container xs={12} sm={12}>
                <TermsCondition />
              </Grid>
            </Box>
            <br />
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ sm: 'right' }}
              justifyContent={{ sm: 'right' }}
              mb={3}>
              <Box mb={{ xs: 2, sm: 0 }}>
                <Button
                  variant="contained"
                  onClick={termsConditionScroll ? () => handleNextModal() : null}
                  color={termsConditionScroll ? 'primary' : 'disable'}>
                  Accept
                </Button>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Modal>

      {/* privacy policy modal */}

      <Modal
        open={modalPrivacy}
        onClose={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid item justifyContent="flex-start" xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Privacy and Policy
              </Typography>
            </Grid>
            <br />
            <Divider />
            <br />
            <Box sx={modalContainer} onScroll={handleScrollPrivacy}>
              <Grid container xs={12} sm={12}>
                <PrivacyPolicy />
              </Grid>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'row' }}
                alignItems={{ sm: 'right' }}
                justifyContent={{ sm: 'space-between' }}
                mb={3}>
                <Box mb={{ xs: 2, sm: 0 }}>
                  <input
                    type="checkbox"
                    value={modalCheckPrivacy}
                    onClick={() => setModalCheckPrivacy(!modalCheckPrivacy)}
                  />{' '}
                  I have read and agree to the <span style={{ color: 'blueviolet' }}>terms and conditions</span> and{' '}
                  <span style={{ color: 'blueviolet' }}>privacy policy</span>
                  {modalCheckPrivacy} {allRequirementComplete}
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection={{ xs: 'row', sm: 'row' }}
              alignItems={{ sm: 'right' }}
              justifyContent={{ sm: 'right' }}
              mb={3}>
              <Box mr={2}>
                <Button onClick={() => backToSignin()} variant="contained" color={'disable'}>
                  Decline
                </Button>
                <Button
                  variant="contained"
                  onClick={allRequirementComplete ? () => executeOnSubmit() : null}
                  color={allRequirementComplete ? 'primary' : 'disable'}>
                  Accept
                </Button>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </AuthWrapper>
  );
};

export default SignUp;
