import React, { useEffect } from 'react';
import { Box, IconButton, makeStyles, Popover, Tooltip, useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CmtCardHeader from '../../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import CmtList from '../../../../../../@coremat/CmtList';
import CmtCard from '../../../../../../@coremat/CmtCard';

import axios from 'axios';
import NotificationItem from './NotificationItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthUser, setNotificationCount } from 'redux/actions/Auth';
import { socket } from './socket';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: 'red',
    width: 20,
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
}));

const actions = [
  {
    label: 'Transaction',
  },
  {
    label: 'Account',
  },
];

// const headerNotifications = [];

const HeaderNotifications = () => {
  const { notification_count, notification_list } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  const theme = useTheme();
  const [userData, setUserData] = React.useState({});
  const dispatch = useDispatch();
  const [notificationList, setNotificationList] = React.useState([]);
  const [showNotifTransaction, setShowNotifTransaction] = React.useState(true);
  const history = useHistory();
  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
    updateNotifStatus();
    dispatch(setNotificationCount(0));
  };
  const updateNotifStatus = async () => {
    let index = JSON.parse(localStorage.getItem('user'));
    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
      },
    };
    try {
      let res = await axios.post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + `/notification-socket/update`,
        {},
        headers,
      );
      const data = res.data;
      if (data.status.status === 1) {
        setCounter(0);
      }
    } catch (err) {
      console.log('err/notification-socket/update', err);
    }
  };
  const onClosePopOver = () => {
    setAnchorEl(null);
  };
  const getNotificationList = async () => {
    let index = JSON.parse(localStorage.getItem('user'));
    console.log('index', index);
    console.log('nolist', notificationList);
    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index ? index.userindex : '',
        tokenlogin: index ? index.usertoken : '',
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
      },
    };
    const currentTimestamp = new Date().getTime();

    try {
      let res = await axios.get(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + `/notification-socket/list?limit=100&id=` + currentTimestamp,
        headers,
      );
      console.log('rnotification-socket', res);
      if (res.data.status.status === 0) {
        setTimeout(() => {
          dispatch(setAuthUser(null));
        }, 2500);
      } else {
        const data = res.data.data;
        setNotificationList(data);
        let tempCounter = 0;
        data.forEach(element => {
          if (element.notification_status === 0) {
            tempCounter++;
          }
        });
        setCounter(tempCounter);
      }
    } catch (err) {
      console.log('err_getnotifList', err);
    }
  };
  const clearState = () => {
    setNotificationList([]);
    setUserData({});
  };
  useEffect(() => {
    console.log('userData.userindex', userData.userindex);
    let index = JSON.parse(localStorage.getItem('user'));
    if (index) {
      setUserData(index);
      getNotificationList();
      socket.on(index.userindex, data => {
        getNotificationList();
      });
    }
    return () => clearState();
  }, [notification_count]);
  console.log('counter', counter);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const actionHandler = data => {
    if (data.label !== 'Transaction') {
      setShowNotifTransaction(false);
    } else {
      setShowNotifTransaction(true);
    }
  };
  return (
    <Box pr={2}>
      <Tooltip title="Notifications">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, 'Cmt-appIcon', {
            active: counter > 0,
          })}>
          <Badge badgeContent={counter} classes={{ badge: classes.counterRoot }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title={showNotifTransaction ? 'Transaction Notification(s) ' : 'Account Notification(s)'}
            actionsPos="top-corner"
            actions={actions}
            actionHandler={actionHandler}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          {showNotifTransaction ? (
            <CmtCardContent>
              {notificationList.length > 0 ? (
                <PerfectScrollbar className={classes.scrollbarRoot}>
                  <CmtList
                    data={notificationList}
                    renderRow={(item, index) => <>
                      {
                        item.notification_type === "Transfer Asset" ?

                          item.notification_recipientindex === item.notification_userindex ?
                            <>
                              <NotificationItem key={index} item={item} notif={item.notification_usermessage} message={item.notification_message} userData={userData} />
                              <NotificationItem key={index} item={item} notif={item.notification_recipientmessage} message={item.notification_message} userData={userData} />
                            </>
                            : item.notification_recipientindex === userData.userindex ?
                              <>
                                <NotificationItem key={index} item={item} notif={item.notification_recipientmessage} message={item.notification_message} userData={userData} />
                              </>
                              :
                              <>
                                <NotificationItem key={index} item={item} notif={item.notification_usermessage} message={item.notification_message} userData={userData} />
                              </>
                          : <NotificationItem key={index} item={item} notif={item.notification_recipientmessage} message={item.notification_message} userData={userData} />
                      }
                    </>
                    }
                  />
                </PerfectScrollbar>
              ) : (
                <Box p={6}>
                  <Typography variant="body2">No notifications found</Typography>
                </Box>
              )}
            </CmtCardContent>
          ) : (
            <CmtCardContent>
              {notification_list.length > 0 ? (
                <PerfectScrollbar className={classes.scrollbarRoot}>
                  <CmtList
                    data={notification_list}
                    renderRow={(item, index) => <NotificationItem key={index} item={item} />}
                  />
                </PerfectScrollbar>
              ) : (
                <Box p={6}>
                  <Typography variant="body2">No notifications found</Typography>
                </Box>
              )}
            </CmtCardContent>
          )}
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
