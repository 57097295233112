// import Typography from '@material-ui/core/Typography';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../i18n/entries/Service';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../redux/actions/Processing';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 300,
  },
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const SavedWallet = () => {
  const classes = useStyles();
  const [label, setLabel] = useState();
  const [step, setStep] = useState('STEP1');
  const [walletList, setWalletList] = useState([]);
  const [savedWalletName, setSavedWalletName] = useState('');
  const [savedWalletInputId, setSavedWalletInputId] = useState('');
  const [tokentransaction, setTokenTransaction] = useState('');
  const [selectedWalletAccount, setSelectedWalletAccount] = useState('');
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const { is_processing } = useSelector(({ processing }) => processing);

  const handleChange = event => {
    setSelectedWalletAccount(event.target.value);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const onSubmitStep1 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/savedwallet/v01/insertnew',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP2');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const onSubmitStep2 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += savedWalletName;
    String += selectedWalletAccount;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      savedwalletname: savedWalletName,
      savedwalletinput: selectedWalletAccount,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/savedwallet/v01/store',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  const _callWalletList = async token => {
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let ApiPrefix = process.env.REACT_APP_API_PREFIX;

    let RequestURI = ApiAddress + '/' + ApiPrefix;
    let storage = JSON.parse(localStorage.getItem('user'));

    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += storage.userindex;
    String += storage.usertoken;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: storage.userindex,
      username: storage.username,
      tokenlogin: storage.usertoken,
      signature: signature.toString(),
    };

    await axios
      .post(RequestURI + '/dashboard/v01/userwalletlist', parameters)
      .then(function(response) {
        let res = response.data;
        console.log(response.data.tablewallet);
        setWalletList(response.data.tablewallet);
      })
      .catch(error => {
        alert(error);
      });
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        async function() {
          openSnackbar('Fetching data...', 60000);
          await _callWalletList(await handleGetTokenRecaptcha());
          closeSnackbar();
        },
      );
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
    },
    [ dispatch ]
  );

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Box className={classes.root}>
          <Stepper activeStep={parseInt(step.substring(4, 5))} alternativeLabel>
            <Step key={'New'}>
              <StepLabel>New</StepLabel>
            </Step>
            <Step key={'Store'}>
              <StepLabel>Store</StepLabel>
            </Step>
          </Stepper>
          <Box>
            <Box>
              {// eslint-disable-next-line
              step == "STEP1" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please click button below to add new saved wallet.</Alert>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep1}>
                          {'Create'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              <br />

              {// eslint-disable-next-line
              step == "STEP2" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please, choose name and wallet for save.</Alert>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          type={'text'}
                          fullWidth
                          id="uncontrolled"
                          label={'Saved Name'}
                          defaultValue={savedWalletName}
                          onChange={event => setSavedWalletName(event.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          type={'text'}
                          fullWidth
                          id="uncontrolled"
                          label={'Saved Id'}
                          defaultValue={savedWalletInputId}
                          onChange={event => setSavedWalletInputId(event.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep2}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default SavedWallet;
