import React, { useContext } from 'react';
import { MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
// import SettingsIcon from '@material-ui/icons/Settings';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { VerifiedUser } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    textAlign: 'center',
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: 'white',
    marginBottom: 8,
  },
  userSubTitle: {
    color: 'white',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
  arrowColor:{
    color:'white'
  }
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  //const userinfo = JSON.parse(localStorage.getItem('user'));

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  // const handleCopyText = ref_code => {
  //   navigator.clipboard.writeText(window.location.origin + '/registration/' + ref_code);
  //   alert('Reference code has copied! ' + ref_code);
  // };

  // const convertWelcome = name => {
  //   let text = name.substring(0, 1);
  //   return text;
  // };

  return (
    <div className={classes.root}>
      <img src={'/images/dwa_logo.png'} style={{ width: 90, height: 40 }} alt={'logo'} />
      <div className={classes.userInfo}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2" style={{ position: 'relative', top: -20 }}>
            {localStorage.getItem('user') ? (
              JSON.parse(localStorage.getItem('user')).user2fatype !== null &&
              JSON.parse(localStorage.getItem('user')).userphonenumber !== null ? (
                <Typography className={classes.userTitle}>
                  Authorized
                  <VerifiedUser
                    style={{
                      color: '#3197E9',
                      height: 15,
                      width: 15,
                      position: 'relative',
                      top: 1,
                    }}
                  />
                </Typography>
              ) : null
            ) : null}
            {/* <Typography className={classes.userTitle} component="h3" variant="h6">
              {JSON.parse(localStorage.getItem('user')) != null ? JSON.parse(localStorage.getItem('user')).userref : ''}
              <FileCopy
                style={{ height: 15, marginTop: 2 }}
                onClick={() => navigator.clipboard.writeText(window.location.origin + '/registration/' + userinfo.userref)}
              />
            </Typography> */}

            <Typography className={classes.userSubTitle}>
              Welcome, &nbsp;
              {JSON.parse(localStorage.getItem('user')) != null ? JSON.parse(localStorage.getItem('user')).usernick : ''}
            </Typography>
          </div>
          <div onClick={handlePopoverOpen} style={{ position: 'relative', top: -13, left: -12 }}>
            <ArrowDropDownIcon className={classes.arrowColor}/>
          </div>
        </div>
      </div>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={handlePopoverClose}>
                <PersonIcon />
                <Link to="/profile">
                  <div className="ml-2">Profile</div>
                </Link>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </div>
  );
};

export default SidebarHeader;
