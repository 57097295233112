import loaderContent from '@jumbo/utils/loaderContent';
import { Grid, Box, Button, Modal } from '@material-ui/core';
import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import CounterCard from '../../../../@jumbo/components/Common/CounterCard';
import CmtImage from '../../../../@coremat/CmtImage';
import { setNotificationCount, setNotificationList, setAuthUser, setRequestNewGauth } from '../../../../redux/actions/Auth';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Collapse, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';

import axios from 'axios';
import cryptoJS from 'crypto-js';
import { useSnackbar } from 'react-simple-snackbar';
import Chart from 'react-apexcharts';
import IdlePin from '../../../Components/IdlePIN';
import { Link } from 'react-router-dom';

import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';

// import UserTableHead from './UserTableHead';
// import UserTableToolbar from './UserTableToolbar';
import { Paper, Table, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { getComparator, stableSort } from '../../../../@jumbo/utils/tableHelper';
import { Typography } from '@material-ui/core';
import { getFormattedDate } from '@jumbo/utils/dateHelper';
import Swal from 'sweetalert2';
import { Visibility } from '@material-ui/icons';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  // height: '100%',

  border: 0,
  boxShadow: 0,
  paddingTop: '10%',
  paddingBottom: '10%',
  paddingRight: '10%',
  paddingLeft: '10%',
  borderRadius: 16,
};
const useStyles = makeStyles(theme => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderRadius: '50%',
    height: 66,
    minWidth: 66,
    width: 66,
  },
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
  paper: {
    width: '100%',
  },
}));

const breadcrumbs = [
  { label: 'History', link: '/history' },
  { label: 'Dashboard', link: '/dashboard' },
];
const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};
const UserTableHeadCompo = React.lazy(() => import('./UserTableHead'));
const UserTableToolbarCompo = React.lazy(() => import('./UserTableToolbar'));
const History = ({ variant = 'default' }) => {
  const [selected, setSelected] = React.useState([]);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [datas, setDatas] = useState([]);
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [datasFetched, setDataFetched] = useState(false);

  // eslint-disable-next-line
  const [ walletList, setWalletList ] = useState([]);
  // eslint-disable-next-line
  const [ isLoaded, setIsloaded ] = useState(false);
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState(false);
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalWallets, setTotalWallets] = useState(0);
  const [unpaid, setUnpaid] = useState(0);
  const [countUnpaid, setCountUnpaid] = useState(0);
  const classes = useStyles({ variant });
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState('');
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const [eCommerceData, setEcommerceData] = useState([
    {
      title: 0,
      color: '#00C4B4',
      subTitle: 'Total Balance ANOA',
      imageIcon: '/images/vector_icons/icons/total_wallet.png',
    },
    {
      title: 1,
      subTitle: 'Pending Transactions',
      color: '#6200EE',
      imageIcon: '/images/vector_icons/icons/total_asset.png',
    },
    {
      title: 0,
      color: '#8DCD03',
      subTitle: 'Monthly Transactions',
      imageIcon: '/images/vector_icons/icons/current_usdt.png',
    },
  ]);
  const [candleStickOptions, setCandleStickOptions] = useState({
    options: {
      chart: {
        type: 'candlestick',
        height: 350,
        offsetY: 0,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: false,
            speed: 350,
          },
        },
      },
      title: {
        text: 'DWA - IDR',
        align: 'left',
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        tooltip: {
          enabled: true,
          offsetX: 0,
        },
        reversed: true,
        opposite: true,
      },
    },
  });
  const [testconcat, setTestconcat] = useState([]);

  const [candleStickData, setCandleStickData] = useState({
    series: [
      {
        data: [],
      },
    ],
  });

  const generateRandom = (min = 9000, max = 11000) => {
    let difference = max - min;

    let rand = Math.random();

    rand = Math.floor(rand * difference);

    rand = rand + min;

    return rand;
  };

  const getSum = (total, num) => {
    return total + Math.round(num);
  };
  const _handleTranscationModal = data => {
    setModalData(data);

    setOpenTransactionDetailModal(true);
  };
  const modifyDateTime = (data, status) => {
    var copiedDate = new Date(data);
    var notificationTimeArrival;

    copiedDate.setHours(copiedDate.getHours() + 1);

    var currentSeconds = new Date().getSeconds();

    var today = new Date();
    var diffMs = (today - copiedDate) * -1; // milliseconds between now & Christmas
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var DPSeconds = 60 - currentSeconds;
    if (status === 'UNPAID') {
      notificationTimeArrival = diffMins + ' minutes ' + DPSeconds + ' seconds';
    } else {
      notificationTimeArrival = 'COMPLETE';
    }
    return notificationTimeArrival;
  };

  const callSavedWalletBalance = async token => {
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let ApiPrefix = process.env.REACT_APP_API_PREFIX;
    let RequestURI = ApiAddress + '/' + ApiPrefix;
    let params = localStorage.getItem('user') !== '' ? JSON.parse(localStorage.getItem('user')) : '';

    var String = '';
    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += params.userindex;
    String += params.usertoken;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: params.userindex,
      username: params.username,
      tokenlogin: params.usertoken,
      signature: signature.toString(),
    };

    await axios
      .post(RequestURI + '/dashboard/v01/userwalletlist/balance', parameters)
      .then(response => {
        let respBalance = response.data;
        setTotalWallets(respBalance.tablewallet.length);

        let ecommercedata = [...eCommerceData];
        ecommercedata[0]['title'] = respBalance.tablewallet.length;
        setEcommerceData(ecommercedata);
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = datas.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pullDataRate = () => {
    //   var currentCoins = [...candleStickData.series[0].data];
    // var updateCoins = currentCoins.concat({x: new Date(Date.now()), y: [generateRandom(), generateRandom(), generateRandom(), generateRandom()]});
    //   setCandleStickData({series:[{data:updateCoins}]});
    //   console.log(candleStickData);
    // setCandleStickData(prevState => [...prevState.series[0],data:{x: new Date(Date.now()), y: [generateRandom(), generateRandom(), generateRandom(), generateRandom()]}]);
    //   console.log(candleStickData);
  };

  const callWalletBalance = async token => {
    let index = JSON.parse(localStorage.getItem('user'));

    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/dashboard/v01/userwalletlist/balance',
        parameters,
      )
      .then(response => {
        let res = response.data;
        setTotalAssets(res.tablewallettotalbalance);

        let ecommercedata = [...eCommerceData];
        ecommercedata[1]['title'] = res.tablewallettotalbalance;
        setEcommerceData(ecommercedata);
      })
      .catch(function(error) {
        alert(error);
      });
  };

  const cancelTopup = (topupindex, amount) => {
    let index = JSON.parse(localStorage.getItem('user'));
    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
      },
    };

    let parameters = {
      topupindex: topupindex,
    };

    Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to revert this after cancel transaction, amount: ${amount}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Back',
      confirmButtonText: 'Yes, cancel it!',
    }).then(result => {
      if (result.isConfirmed) {
        return new Promise((resolve, reject) => {
          axios
            .post(
              process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v02/cancle',
              parameters,
              headers,
            )
            .then(response => {
              let res = response.data;

              if (res.status.status === 1) {
                Swal.fire('Canceled!', 'Your transaction has been canceled.', 'success');

                callTransactionHistory();
                resolve();
              } else {
                let arrError = [];

                if (res.status.message.length > 0) {
                  for (var i = 0; i < res.status.message.length; i++) {
                    arrError.push(res.status.message[i]);
                  }
                }

                if (arrError.find(item => item.code === '888999999940')) {
                  setTimeout(() => {
                    dispatch(setAuthUser(null));
                  }, 2500);
                }
                reject();
              }
            })
            .catch(function(error) {
              alert(error);
              reject();
            });
        });
      }
    });
  };

  const callTransactionHistory = () => {
    let index = JSON.parse(localStorage.getItem('user'));
    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
      },
    };
    const currentTimestamp = new Date().getTime();
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.REACT_APP_API_ADDRESS +
            '/' +
            process.env.REACT_APP_API_PREFIX +
            '/transaction/v01/mutation?id=' +
            currentTimestamp,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log(res);

          let countUnpaid = 0;

          let arrError = [];

          if (res.status.message.length > 0) {
            for (var i = 0; i < res.status.message.length; i++) {
              arrError.push(res.status.message[i]);
            }
          }

          if (arrError.find(item => item.code === '888999999940')) {
            setTimeout(() => {
              dispatch(setAuthUser(null));
            }, 2500);
          } else {
            setDatas(res.data);
            setDataFetched(true);
          }

          resolve();
        })
        .catch(function(error) {
          alert(error);
          reject();
        });
    });
  };

  const checkUnpaid = async token => {
    let index = JSON.parse(localStorage.getItem('user'));

    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/unpaid/v01/list/count',
        parameters,
      )
      .then(response => {
        let res = response.data;
        setUnpaid(res.tableunpaidlist[0].count);

        let ecommercedata = [...eCommerceData];
        ecommercedata[3]['title'] = res.tableunpaidlist[0].count;
        setEcommerceData(ecommercedata);
      })
      .catch(function(error) {
        alert(error);
      });
  };

  const triggerNotification = async token => {
    let index = JSON.parse(localStorage.getItem('user'));

    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/notification/v01/count',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        dispatch(setNotificationCount(res.result[0].tablelogcount));
      })
      .catch(function(error) {
        localStorage.clear();
        dispatch(setAuthUser(null));
        window.location.reload();
      });
  };

  const triggerNotificationList = () => {
    let index = JSON.parse(localStorage.getItem('user'));

    // var String = '';

    // String += 'WEBSITE';
    // String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    // String += index.userindex;
    // String += index.usertoken;

    // let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let headers = {
      headers: {
        gtoken: 'PASSS',
        signature: 'fd89afyda',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
      },
    };
    const currentTimestamp = new Date().getTime();
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.REACT_APP_API_ADDRESS +
            '/' +
            process.env.REACT_APP_API_PREFIX +
            '/activity/v01/lists?id=' +
            currentTimestamp,
          headers,
        )
        .then(response => {
          let res = response.data;
          // console.log(res);a
          dispatch(setNotificationList(res.data));
          resolve();
        })
        .catch(function(error) {
          alert(error);
          reject();
        });
    });
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(
    () => {
      // load the script by passing the URL

      // setInterval(() =>{
      //   setCandleStickData(prev => [
      //     // spread the current value of `prev`
      //     ...prev.series[0].data,
      //     // map the data and spread the resulting array
      //     {x: new Date(Date.now()), y: [generateRandom(), generateRandom(), generateRandom(), generateRandom()]}
      // ]);
      // },3000);
      const loadScriptRecaptcha = async () => {
        // await loadScriptByURL(
        //   'recaptcha-key',
        //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        //   async function() {
        openSnackbar('Fetching data...', 60000);
        await callTransactionHistory();
        await triggerNotificationList();
        // await triggerNotification(await handleGetTokenRecaptcha());
        // await triggerNotificationList(await handleGetTokenRecaptcha());

        closeSnackbar();
        //   },
        // );
      };
      loadScriptRecaptcha().catch(console.error);
    },
    // eslint-disable-next-line
    [ dispatch ]
  );

  // const callWalletData = async () => {
  //   let index = JSON.parse(localStorage.getItem('user'));
  //   let response = await WalletController.basic.getUserWalletList(index);

  //   setWalletList(response);
  //   setIsloaded(false);
  // };

  // useEffect(() => {
  //   callWalletData();
  // });
  const customDate = data => {
    let ApiAddress = process.env.REACT_APP_API_ADDRESS;
    let epoch_constant;
    if (ApiAddress === 'https://satellite.wallet.anoatoken.com') {
      epoch_constant = 1604232000;
    } else {
      epoch_constant = 1604231999897;
    }
    const temp = data * 1000 + epoch_constant;
    const date = new Date(temp); // Convert seconds to milliseconds
    const formattedDate = date.toLocaleString(); // Convert to a formatted UTC string

    // Create a Date object from the given date string.
    const dates = new Date(formattedDate);
    // Get the day, month, and year from the Date object.
    const day = dates.getDate();
    const month = dates.getMonth() + 1;
    const year = dates.getFullYear();
    // Get the hours, minutes, and seconds from the Date object.
    const hours = dates.getHours();
    const minutes = dates.getMinutes();
    const seconds = dates.getSeconds();
    // Create a string in the desired format.
    const formattedDates = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    // Log the formatted date to the console.
    // Original timestamp string

    // Split the timestamp into its components
    var components = formattedDates.split(' ');

    // Date component
    var _date = components[0];
    var _time = components[1];

    // Split the time component into its sub-components
    var timeComponents = _time.split(':');

    // Pad single-digit hours, minutes, and seconds with leading zeros
    var formattedTime = timeComponents
      .map(function(component) {
        return component.length === 1 ? '0' + component : component;
      })
      .join(':');

    // Construct the formatted timestamp
    var formattedTimestamp = _date + ' ' + formattedTime;
    return formattedTimestamp;
  };
  return (
    <PageContainer heading="History" breadcrumbs={breadcrumbs}>
      <Modal
        open={openTransactionDetailModal}
        onClose={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // onScroll={}
      >
        <Box sx={style}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: 'white',
              padding: 30,
              gap: 30,
              borderRadius: 16,
            }}>
            <div style={{}}>
              <div
                style={{
                  paddingTop: '3%',
                  paddingBottom: '3%',
                  backgroundColor: '#2596be',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 30,
                  paddingLeft: '3%',
                  paddingRight: '3%',
                }}>
                <span style={{ color: 'white', fontWeight: 'bold', fontSize: 28 }}> {modalData.description}</span>
              </div>
              {/* <div style={{ height: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                <span style={{ color: 'black', fontWeight: 'bold', fontSize: 20, fontWeight: 'bolder' }}>{modalData.description}</span>
              </div> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: 15,
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Date Time</label>
                  <label>{typeof modalData.timestamp !== 'undefined' ? customDate(modalData.timestamp) : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>From</label>
                  <label>{typeof modalData.sender !== 'undefined' ? modalData.sender : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Recepient</label>
                  <label>{typeof modalData.recepient !== 'undefined' ? modalData.recepient : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Message</label>
                  <label>{typeof modalData.message !== 'undefined' ? modalData.message : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Transaction Code</label>
                  <label>
                    {typeof modalData.transactioncode !== 'undefined'
                      ? modalData.transactioncode
                      : modalData.blockchaintransaction}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Amount ANOA</label>
                  <label>{typeof modalData.amount !== 'undefined' ? modalData.amount : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Currency</label>
                  <label>{typeof modalData.currency !== 'undefined' ? modalData.currency : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Platform</label>
                  <label>{typeof modalData.platform !== 'undefined' ? modalData.platform : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Rate</label>
                  <label>{typeof modalData.rate !== 'undefined' ? modalData.rate : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Amount</label>
                  <label>{typeof modalData.amount !== 'undefined' ? modalData.amount : ''}</label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Fee</label>
                  <label>{typeof modalData.fee !== 'undefined' ? modalData.fee : ''}</label>
                </div>
                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Unique Code</label>
                  <label>{typeof modalData.uniquecode !== 'undefined' ? modalData.uniquecode : ''}</label>
                </div> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <label>Total</label>
                  <label>{typeof modalData.total !== 'undefined' ? modalData.total : ''}</label>
                </div>

                {/* <div style={{ display: 'flex', flexDirection: 'column', gap: 15, justifyContent: 'flex-start', alignItems: 'start' }}>
                  <label>Date Time</label>
                  <label>From</label>
                  <label>Transaction Code</label>
                  <label>Amount ANOA</label>
                  <label>Currency</label>
                  <label>Platform</label>
                  <label>Rate</label>
                  <label>Amount</label>
                  <label>Fee</label>
                  <label>Unique Code</label>
                  <label>Total</label>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 15, justifyContent: 'flex-end', alignItems: 'end' }}>
                  <label>{typeof (modalData.timestamp) !== 'undefined' ? modalData.timestamp : ''}</label>
                  <label>{typeof (modalData.from) !== 'undefined' ? modalData.from : 'ADA DONG'}</label>
                  <label>{typeof (modalData.invoice) !== 'undefined' ? modalData.invoice : ''}</label>
                  <label>{typeof (modalData.amount) !== 'undefined' ? modalData.amount : ''}</label>
                  <label>{typeof (modalData.currencycode) !== 'undefined' ? modalData.currencycode : ''}</label>
                  <label>{typeof (modalData.platform) !== 'undefined' ? modalData.platform : ''}</label>
                  <label>{typeof (modalData.rate) !== 'undefined' ? modalData.rate : ''}</label>
                  <label>{typeof (modalData.amount) !== 'undefined' ? modalData.amount : ''}</label>
                  <label>{typeof (modalData.fee) !== 'undefined' ? modalData.fee : ''}</label>
                  <label>{typeof (modalData.uniquecode) !== 'undefined' ? modalData.uniquecode : ''}</label>
                  <label>{typeof (modalData.total) !== 'undefined' ? modalData.total : ''}</label>
                </div> */}
                {/* {
                  modalData.map((item, index) => {
                    return (
                      <div></div>
                    )
                  }
                  )} */}
              </div>
            </div>
            <Button
              style={{
                borderRadius: 10,
                paddingTop: 10,
                paddingBottom: 10,
                width: '100%',
              }}
              variant="contained"
              color="primary"
              onClick={() => setOpenTransactionDetailModal(false)}>
              Back
            </Button>
            {/* <Button style={{ borderRadius: 10, paddingTop: 10, paddingBottom: 10, width: '100%' }} variant="contained" color="primary"   >Cancel</Button> */}
          </div>
        </Box>
      </Modal>
      <GridContainer>
        {/* {eCommerceData.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            
              <CounterCard
                icon={
                  <Box className={classes.iconWrapper} style={{ borderColor: item.color + '88' }}>
                    <CmtImage src={item.imageIcon} alt="..." style={{ height: 25, width: 30 }} />
                  </Box>
                }
                number={item.title}
                numberProps={{
                  color: 'text.primary',
                  fontSize: { xs: 20, xl: 22 },
                  fontWeight: 'bold',
                }}
                label={item.subTitle}
                labelProps={{
                  color: 'text.secondary',
                  fontSize: 12,
                  fontWeight: 'normal',
                }}
              />
          </Grid>
        ))} */}

        <Paper className={classes.paper}>
          <Suspense fallback={<div>Loading...</div>}>
            <UserTableToolbarCompo
              selected={selected}
              setSelected={setSelected}
              onUserAdd={setOpenUserDialog}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Suspense>
          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <Suspense fallback={<div>Loading...</div>}>
                <UserTableHeadCompo
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={datas.length}
                />
              </Suspense>
              <TableBody>
                {datas.length > 0 ? (
                  stableSort(datas, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow hover tabIndex={-1} key={index} selected={false}>
                        <TableCell align="center">
                          {/* <Typography className={classes.titleRoot} component="div" variant="h4">
                                {index+1}
                            </Typography> */}
                        </TableCell>
                        <TableCell>{customDate(row.timestamp)}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <img
                            src={row.description === 'TRANSFER ASSET' ? '/images/icon_red.png' : '/images/icon_green.png'}
                            style={{ width: 25, height: 25, marginLeft: 5 }}
                            alt={'logo'}
                          />
                        </TableCell>
                        <TableCell>
                          <a
                            href={
                              (process.env.REACT_APP_DEVELOPMENT
                                ? process.env.REACT_APP_TESTNET_EXPLORER
                                : process.env.REACT_APP_LIVENET_EXPLORER) + `transaction-detail/${row.deposit}`
                            }
                            style={{ textDecoration: 'underline' }}>
                            {row.deposit}
                          </a>
                        </TableCell>
                        <TableCell>{row.amount}</TableCell>
                        <TableCell>{row.fee}</TableCell>
                        <TableCell>{row.total}</TableCell>
                        <TableCell>{modifyDateTime(row.timestamp, row.status)}</TableCell>
                        <TableCell>
                          {row.status === 'UNPAID' ? (
                            <div>
                              <label
                                style={{
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 63,
                                  paddingRight: 63,
                                  backgroundColor: 'red',
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: 'white',
                                }}>
                                {row.status}
                              </label>
                              &nbsp;
                              <label
                                onClick={() => cancelTopup(row.depositinvoice, row.amount)}
                                style={{
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 63,
                                  paddingRight: 63,
                                  backgroundColor: 'gray',
                                  borderRadius: 10,
                                  fontSize: 12,
                                  color: 'white',
                                }}>
                                {'CANCEL'}
                              </label>
                            </div>
                          ) : null}

                          {row.status === 'PAID' ? (
                            <label
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 50,
                                paddingRight: 50,
                                backgroundColor: 'green',
                                borderRadius: 10,
                                fontSize: 12,
                                color: 'white',
                              }}>
                              {row.status}
                            </label>
                          ) : null}

                          {row.status === 'SUCCESS' ? (
                            <label
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 50,
                                paddingRight: 50,
                                backgroundColor: 'green',
                                borderRadius: 10,
                                fontSize: 12,
                                color: 'white',
                              }}>
                              {row.status}
                            </label>
                          ) : null}

                          {row.status === 'CONFIRMED' ? (
                            <label
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 50,
                                paddingRight: 50,
                                backgroundColor: '#0E86D4',
                                borderRadius: 10,
                                fontSize: 12,
                                color: 'white',
                              }}>
                              {row.status}
                            </label>
                          ) : null}

                          {row.status === 'COMPLETED' ? (
                            <label
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 50,
                                paddingRight: 50,
                                backgroundColor: 'green',
                                borderRadius: 10,
                                fontSize: 12,
                                color: 'white',
                              }}>
                              {row.status}
                            </label>
                          ) : null}

                          {row.status === 'CANCELED' ? (
                            <label
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 50,
                                paddingRight: 50,
                                backgroundColor: 'gray',
                                borderRadius: 10,
                                fontSize: 12,
                                color: 'white',
                              }}>
                              {row.status}
                            </label>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <Button
                            style={{ borderRadius: 10 }}
                            variant="contained"
                            color="primary"
                            onClick={() => _handleTranscationModal(row)}>
                            <Visibility style={{ color: 'white' }} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      <div>{datasFetched ? 'There are no records found.' : 'Loading datas...'}</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component="div"
            count={datas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>

        {/* <CmtCard className={classes.cardRoot}>
          <CmtCardContent>
            <Box>
              <Chart
                options={candleStickOptions.options}
                series={candleStickData.series}
                type="candlestick"
                width={1000}
                height={320}
              />
              <button onClick={pullDataRate}>Pull</button>
            </Box>
          </CmtCardContent>
        </CmtCard> */}
        {isLoaded ? (
          <loaderContent.MyLoader width={350} height={84} speed={1} />
        ) : walletList.length > 0 ? null : (
          // walletList.map((x, i) => (
          //   <Grid item xs={12} sm={6} xl={6} md={6} key={i}>
          //     <UserSummery
          //       labelWallet={x.tablewalletlabel}
          //       accountWallet={x.tablewalletaccount}
          //       amount={x.amount}
          //       index={i}
          //       isLoaded={isLoaded}
          //       labelIcon={x.tablewalleticon}
          //       labelColor={x.tablewalleticoncolor}
          //     />
          //   </Grid>
          // ))
          <Collapse in={true}>
            {/* <Alert
              variant="outlined"
              severity="info"
              className={classes.alertRoot}
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              This feature is temporary disabled. We will inform you as soon as possible.{process.env.REACT_APP_IS_DEMO}
            </Alert> */}
          </Collapse>
        )}
      </GridContainer>
    </PageContainer>
  );
};

export default History;
