import { Collapse, IconButton, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import cryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { setRequestNewPassword } from '../../../../redux/actions/Auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import AuthWrapper from './AuthWrapper';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const NewPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_request_new_password } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setErrorPassword] = useState('');
  const [passwordErrorisShow, setPasswordErrorisShow] = useState(false);

  const [c_password, setCpassword] = useState('');
  const [cpasswordError, setErrorCPassword] = useState('');
  const [cpasswordErrorisShow, setCpasswordErrorisShow] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  // const history = useHistory();
  const { verifid } = useParams();
  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      // await loadScriptByURL(
      //   'recaptcha-key',
      //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //   function() {
      console.log('Script loaded!');
      //   },
      // );
    };

    loadScriptRecaptcha().catch(console.error);

    let timeOutStopper = null;
    if (send_request_new_password) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setRequestNewPassword(false));
      }, 3000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_request_new_password]);

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const requestSubmit = async () => {
    if (password !== '' || c_password !== '') {
      if (password !== c_password) {
        let arrClear = [];
        setErrorPassword('please match the confirm password with the password');
        setPasswordErrorisShow(true);

        setErrorCPassword('please match the confirm password with the password');
        setCpasswordErrorisShow(true);
        arrClear.push('password');
        arrClear.push('cpassword');
      } else {
        let parameters = {
          platform: 'WEBSITE',
          tableuseremailverificationcode: verifid,
          tableuserpasswordnew: password,
          tableuserlanguage: 'EN',
        };

        let headers = {
          headers: {
            gtoken: 'PASSS',
            signature: '91ca4d5ee4fd260cd72d67087bf89ae93eecf761ef90eb934fd06a2366fc7131',
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .post(
              process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/auth/v01/newpassword',
              parameters,
              headers,
            )
            .then(function(response) {
              let res = response.data;
              console.log(res);
              if (res.status.status === 1) {
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Setup new password has been successfully',
                });
                resolve();
                setTimeout(() => {
                  window.location = '/signin';
                }, 2000);
              } else {
                let arrError = [];
                let arrClear = [];

                if (res.status.message.length > 0) {
                  for (var i = 0; i < res.status.message.length; i++) {
                    arrError.push(res.status.message[i]);
                  }
                }

                if (arrError.find(item => item.code === '888005115020')) {
                  setErrorPassword('password length should more than 5 character');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                  arrClear.push('cpassword');
                }

                if (arrError.find(item => item.code === '888005117025')) {
                  setErrorPassword('new password length should less than 16 character');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                  arrClear.push('cpassword');
                }

                if (arrError.find(item => item.code === '888005117020')) {
                  setErrorPassword('please use minimum 8 characters for password');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                  arrClear.push('cpassword');
                }

                if (arrError.find(item => item.code === '888005117005')) {
                  setErrorPassword('please provide new password');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                  arrClear.push('cpassword');
                }

                if (arrError.find(item => item.code === '888005115005')) {
                  setErrorPassword('please use minimum 1 alphabet, 1 number and 1 special character (!@#$,%^&)');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                }

                if (arrError.find(item => item.code === '888005010015')) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: 'user index is not found',
                  });
                  arrClear.push('password');
                  arrClear.push('cpassword');
                }

                reject(arrClear);
              }
            })
            .catch(function(error) {
              alert(error);
            });
        });
      }
    } else {
      setErrorPassword('please provide password');
      setPasswordErrorisShow(true);

      setErrorCPassword('please provide confirm password');
      setCpasswordErrorisShow(true);
    }
  };

  const clearState = (clearParams = []) => {
    if (clearParams.includes('password')) {
      setPassword('');
      setErrorPassword('');
      setPasswordErrorisShow(false);
    }

    if (clearParams.includes('cpassword')) {
      setCpassword('');
      setErrorCPassword('');
      setCpasswordErrorisShow(false);
    }

    if (clearParams.length < 1) {
      setPassword('');
      setErrorPassword('');
      setPasswordErrorisShow(false);
      setCpassword('');
      setErrorCPassword('');
      setCpasswordErrorisShow(false);
    }
  };

  const onSubmit = async () => {
    //  dispatch(setProcessing(true));
    await requestSubmit()
      .then(() => {
        setTimeout(() => {
          clearState();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState(arrClear);
        }, 2500);
      });
    // dispatch(setProcessing(false));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          New Password
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            Password has been changed, now you're logged out.
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              error={passwordErrorisShow}
              id={passwordErrorisShow ? 'outlined-error-helper-text' : ''}
              type="password"
              label={'Password'}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              value={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={passwordError}
            />
          </div>
          <div className={'mb-5'}>
            <TextField
              error={cpasswordErrorisShow}
              id={cpasswordErrorisShow ? 'outlined-error-helper-text' : ''}
              type="password"
              label={'Confirm Password'}
              fullWidth
              onChange={event => setCpassword(event.target.value)}
              value={c_password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={cpasswordError}
            />
          </div>
          <div className={'mb-5'}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.setPassword" />
            </Button>
          </div>

          <div>
            {/* <Typography>
              Don't remember your email?
              <span className={'ml-2'}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </span>
            </Typography> */}
          </div>
        </form>
        <p style={{ fontSize: 11, alignSelf: 'center' }}>{process.env.REACT_APP_VERSION}</p>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default NewPassword;
