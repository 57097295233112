import { Box } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { alpha, makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { AccountCircle, Person } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
import { getNewDate } from '../../../../@jumbo/utils/dateHelper';
import loaderContent from '../../../../@jumbo/utils/loaderContent';
import Translation from '../../../../i18n/entries/Service';
import Swal from 'sweetalert2';
import axios from 'axios';
import cryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '../../../../redux/actions/Auth';
import EditIcon from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'react-simple-snackbar';

const useStyles = makeStyles(theme => ({
  iconView: {
    backgroundColor: alpha(blue['500'], 0.1),
    color: blue['500'],
    padding: 8,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      display: 'block',
    },
    '&.web': {
      backgroundColor: alpha(theme.palette.warning.main, 0.1),
      color: theme.palette.warning.main,
    },
    '&.phone': {
      backgroundColor: alpha(theme.palette.success.main, 0.15),
      color: theme.palette.success.dark,
    },
  },
  wordAddress: {
    wordBreak: 'break-all',
    cursor: 'pointer',
  },
}));
const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};
const Contact = ({ userDetail, walletInfo, isLoaded, getRecaptcha }) => {
  //const { email, website, phone } = userDetail;
  // const [username,setUsername] = useState('');
  let index = JSON.parse(localStorage.getItem('user'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [information, setInformation] = useState('This is your current username: ' + index.username);
  const [isDone, setIsDone] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  function onlyLettersAndNumbers(str) {
    return /^[A-Za-z0-9]*$/.test(str);
  }
  function startsWithNumber(str) {
    return /^\d/.test(str);
  }
  const changeUsername = () => {
    Swal.fire({
      icon: 'info',
      title: 'Change Username',
      text: 'Insert new username.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      preConfirm: userField => {
        if (userField.length < 5 || userField.length > 20) {
          if (userField.length === 0) {
            setInformation('please provide user name');
            Swal.showValidationMessage(`please provide user name`);
          } else {
            setInformation('please use 5-20 character for user name');
            Swal.showValidationMessage(`please use 5-20 character for user name`);
          }
        }
        if (onlyLettersAndNumbers(userField) === false) {
          setInformation('please use only alphabet, number and not using space for user name');
          Swal.showValidationMessage(`please use only alphabet, number and not using space for user name`);
        }
        if (startsWithNumber(userField) === true) {
          setInformation('please fill user name start with alphabet only');
          Swal.showValidationMessage(`please fill user name start with alphabet only`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async result => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Password Verification',
          text: 'Please input your password',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          showLoaderOnConfirm: true,
          preConfirm: async password => {
            let index = JSON.parse(localStorage.getItem('user'));

            // let token = await getRecaptcha();
            // var String = '';

            // String += 'WEBSITE';
            // String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
            // String += index.userindex;
            // String += index.usertoken;
            // String += password;
            // String += result.value;

            // let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

            let headers = {
              headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: index.userindex,
                tokenlogin: index.usertoken,
                signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
              },
            };

            let parameters = {
              tableusername: result.value,
              tableuserpassword: password,
            };
            console.log('param', parameters);

            axios
              .post(
                process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/changeusername',
                parameters,
                headers,
              )
              .then(function(response) {
                let res = response.data;
                if (res.status.status === 1) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'You successfully change username, now you will logged out!',
                  });
                  setTimeout(function() {
                    forceLogout();
                  }, 2500);
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Invalid Password!',
                    text: 'Changes will not be saved. Please try again.',
                  });
                }
              })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    });
  };
  console.log('isDone', isDone);
  const changeUsernick = () => {
    let index = JSON.parse(localStorage.getItem('user'));
    Swal.fire({
      icon: 'info',
      title: 'Change Nickname',
      text: 'This is your current nickname: ' + index.usernick,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      preConfirm: userField => {
        console.log(userField);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async result => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Password Verification',
          text: 'Please input your password',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          showLoaderOnConfirm: true,
          preConfirm: async password => {
            if (password.length === 0) {
              setInformation('please provide password');
              Swal.showValidationMessage(`please provide password`);
            } else {
            }
            let index = JSON.parse(localStorage.getItem('user'));

            let token = await getRecaptcha();
            var String = '';

            String += 'WEBSITE';
            String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
            String += index.userindex;
            String += index.usertoken;
            String += password;
            String += result.value;

            let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

            let parameters = {
              platform: 'WEBSITE',
              gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
              userindex: index.userindex,
              username: index.username,
              tokenlogin: index.usertoken,
              userpassword: password,
              tableusernick: result.value,
              signature: signature.toString(),
            };
            axios
              .post(
                process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/changeusernick',
                parameters,
              )
              .then(function(response) {
                let res = response.data;
                if (res.status.code === '080000000000') {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'You successfully change nickname, now you will logged out!',
                  });
                  setTimeout(function() {
                    forceLogout();
                  }, 2500);
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: Translation.getServiceLanguage(res.status.code),
                  });
                }
              })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      }
    });
  };

  const forceLogout = () => {
    localStorage.removeItem('user');
    dispatch(setAuthUser(null));
    sessionStorage.removeItem('set_last_url');
    window.location.href = '/signin';
  };
  const copyToClipBoard = async (data) =>{
    console.log(',asui')
    openSnackbar('Wallet ID Copied!', 60000);
    navigator.clipboard.writeText(data)
   setTimeout(() => {
        closeSnackbar();
   }, 3000);

  }
  return (
    <CmtCard>
      <CmtCardHeader title="Contact" />

      {isLoaded ? (
        <loaderContent.MyLoader width={350} height={84} speed={1} />
      ) : (
        <CmtCardContent>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={classes.iconView}>
              <AccountCircle />
            </Box>
            <Box ml={5}>
              <Box fontSize={12} color="text.secondary">
                Wallet ID
                {/* {Translation.getServiceLanguage('tableusername')} */}
              </Box>
              <Box className={classes.wordAddress} fontSize={16}>
                <Box>
                  {walletInfo.walletaccount}{' '}
                  <FileCopy
                    style={{ height: 15, marginTop: 2 }}
                    onClick={() => copyToClipBoard(walletInfo.walletaccount)}
                  />{' '}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={classes.iconView}>
              <AccountCircle />
            </Box>
            <Box ml={5}>
              <Box fontSize={12} color="text.secondary">
                Username
                {/* {Translation.getServiceLanguage('tableusername')} */}
              </Box>
              <Box className={classes.wordAddress} fontSize={16}>
                <Box onClick={() => changeUsername()}>
                  {userDetail.tableusername ? userDetail.tableusername : '-'} <EditIcon style={{ fontSize: 14 }} />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={classes.iconView}>
              <Person />
            </Box>
            <Box ml={5}>
              <Box fontSize={12} color="text.secondary">
                {/* {Translation.getServiceLanguage('tableusernick')} */}
                Full name
              </Box>
              <Box className={classes.wordAddress} fontSize={16}>
                <Box>{userDetail.tableuserfullname ? userDetail.tableuserfullname : '-'}</Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={classes.iconView}>
              <MailOutlineIcon />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                {Translation.getServiceLanguage('tableuseremail')}
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="a">{userDetail.tableuseremail ? userDetail.tableuseremail : '-'}</Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
            <Box className={clsx(classes.iconView, 'web')}>
              <LinkIcon />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                {/* {Translation.getServiceLanguage('tableusercreatetimestamp')} */}
                Member since
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16}>
                <Box component="a" href={''}>
                  {/* {userDetail.tableusercreatetimestamp ? getNewDate(userDetail.tableusercreatetimestamp) : '-'} */}
                  {(new Date(userDetail.tableuserphonenumberisverified)).toLocaleString('en-US', { timeZone: 'UTC' })}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Box className={clsx(classes.iconView, 'phone')}>
              <LocalPhoneIcon />
            </Box>
            <Box ml={5}>
              <Box component="span" fontSize={12} color="text.secondary">
                {/* {Translation.getServiceLanguage('tableuserphonenumber')} */}
                Phone number
              </Box>
              <Box component="p" className={classes.wordAddress} fontSize={16} color="text.primary">
                {userDetail.tableuserphonenumber
                  ? ' (' +
                    JSON.parse(localStorage.getItem('user')).userphonecountrycode +
                    ') ' +
                    JSON.parse(localStorage.getItem('user')).userphonenumbershort
                  : '-'}
              </Box>
            </Box>
          </Box>
        </CmtCardContent>
      )}
    </CmtCard>
  );
};

export default Contact;

Contact.prototype = {
  userDetail: PropTypes.object.isRequired,
};
