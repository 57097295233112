import { Collapse, IconButton, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import cryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import AuthWrapper from './AuthWrapper';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetUsername = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailErrorisShow, setEmailErrorisShow] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    let timeOutStopper = null;
    if (send_forget_password_email) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/signin');
      }, 5000);
    }

    const loadScriptRecaptcha = async () => {
      // await loadScriptByURL(
      //   'recaptcha-key',
      //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //   function() {
      console.log('Script loaded!');
      //   },
      // );
    };

    loadScriptRecaptcha().catch(console.error);

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_forget_password_email]);

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const requestSubmit = () => {
    let parameters = {
      tableuseremail: email,
      // tableuserlanguage: 'EN',
    };
    console.log('Body', parameters);
    let headers = {
      headers: {
        gtoken: 'PASSS',
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
      },
    };
    return new Promise(function(resolve, reject) {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/auth/v01/forgotusername',
          parameters,
          headers,
        )
        .then(function(response) {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            dispatch(setForgetPassMailSent(true));
            resolve();
          } else {
            let arrError = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888005035005')) {
              setEmailError(arrError.find(item => item.code === '888005035005').errormassage);
              setEmailErrorisShow(true);
            }

            reject();
          }
        })
        .catch(function(error) {
          alert(error);
        });
    });
  };

  const clearState = () => {
    setEmail('');
    setEmailError('');
    setEmailErrorisShow(false);
  };

  const onSubmit = async () => {
    await requestSubmit()
      .then(() => {
        setTimeout(() => {
          clearState();
        }, 2500);
      })
      .catch(() => {
        setTimeout(() => {
          clearState();
        }, 2500);
      });
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Forgot Username
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            Further instruction will be send to your registered email address, please check your email
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              error={emailErrorisShow}
              id={emailErrorisShow ? 'outlined-error-helper-text' : ''}
              label={'Email'}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={emailError}
            />
          </div>
          <div className={'mb-5'}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              {/* <IntlMessages id="appModule.resetUsername" /> */}
              <p>Forgot Username</p>
            </Button>
          </div>

          <div>
            <Typography>
              Remember your username?
              <span className={'ml-2'}>
                <Link href="/signin">Signin</Link>
              </span>
            </Typography>
          </div>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResetUsername;
