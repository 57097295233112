import React from 'react';



const TermsCondition = () =>{
	return (
		<>
			<div>
		  <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
		  <title />
		  <meta name="generator" content="LibreOffice 7.4.2.3 (Linux)" />
		  <meta name="author" content="Randy Andy" />
		  <meta name="created" content="2023-01-16T09:30:00" />
		  <meta name="changedby" content="juan" />
		  <meta name="changed" content="2023-01-20T10:00:00" />
		  <meta name="AppVersion" content={16.0000} />
		  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t\t@page { size: 8.27in 11.69in; margin: 1in }\n\t\tp { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }\n\t\ta:link { color: #0563c1; text-decoration: underline }\n\t" }} />
		  <p align="center" style={{lineHeight: '108%', marginBottom: '0in'}}>
			<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>TERMS
				  AND CONDITION</b></font></font></p>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
				Terms and Conditions for using ANOA website or application
				(hereinafter referred to as the “</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Terms
				  and Conditions</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>”)
				are an agreement between you (“</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>user</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>”
				or “</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>you</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>”)
				and ANOA (“</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>ANOA</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>”
				or “</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>We</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>”)
				that managed how you use the product, services, technology and
				services provided by ANOA including but not limited to services when
				users register an account through the website https://anoatoken.com
				(hereinafter referred to as the "ANOA Website"). By
				registering as a user, the user has stated that the user has READ,
				UNDERSTAND, ACCEPT, and AGREE to all terms and conditions contained
				in these Terms and Conditions and the Privacy Policy as follows:</font></font></p>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Definition</b></font></font></p>
			</li></ul>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>As
				long as the context of the sentence does not specify otherwise, the
				terms or definitions in the terms and conditions have the following
				meanings:</font></font></p>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>ANOA
					  Account</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is an account or access given to users to carry out activities using
					the ANOA website;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>ANOA
					  assets</b> &nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					are digital commodities, using cryptography, information technology
					networks, and distributed ledgers, to manage the creation of new
					units, verify transactions, and secure transactions without the
					interference of other parties, which are traded on ANOA website;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>USDT</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is a currency specifically designed to establish an intermediary
					between traditional currencies and cryptocurrency;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Funds</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					are the amount of money deposited by Users in Rupiah through
					inter-bank transfers for the purposes of Crypto Asset Trading
					Activities through exchangers;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Blockchain</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is a transaction recording system or database that is widespread in
					online peer-to-peer networks;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Password</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is an encryption that can protect ANOA accounts with an alphanumeric
					combination consisting of uppercase and lowercase letters, numbers
					and symbols;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Wallet</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is a medium used for storing funds or crypto assets in the form of
					coins or tokens;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Fund
					  Placement</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is the process of storing funds or crypto assets by users which
					refers to the top-up process or mechanism carried out through ANOA
					account;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Privacy
					  Policy</b>&nbsp;</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is a provision managing how ANOA collect, use, maintain and disclose
					information collected from users through the ANOA website;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Services</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					are services provided by ANOA including but not limited to ANOA
					asset trading activities;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>User</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>
					is a person (individual) who own ANOA account who has registered on
					the ANOA website, thus obtaining authority from ANOA to carry out
					crypto asset trading activities.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Notification
					  of Risk Documents</b></font></font></p>
			</li></ul>
		  <ol type="a">
			<ol type="a">
			  <li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				  <font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Crypto
					  asset trading is a high risk activity;</font></font></p>
			  </li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				  <font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Crypto
					  asset trading carries additional risks that are not experienced by
					  other commodities in a market that is formed on the basis of
					  technology and consensus;</font></font></p>
			  </li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				  <font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Be
					  wary of saying that you will make huge profits trading crypto
					  assets;</font></font></p>
			  </li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				  <font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>You
					  must read carefully and understand including but not limited to the
					  Terms or other information before making a Crypto Asset
					  transaction;</font></font></p>
			  </li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				  <font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>ANOA
					  does not guarantee the long-term viability of Crypto Assets traded
					  or exchanged on the ANOA website/ Platform;</font></font></p>
			  </li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				  <font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>This
					  brief statement cannot contain all the risks or other important
					  aspects of Crypto Asset Trading. Therefore you must study Crypto
					  Asset Trading activities carefully before deciding to make a
					  transaction;</font></font></p>
			  </li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				  <font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>This
					  Risk Disclosure document is made in Indonesian Bahasa.</font></font></p>
			  </li></ol>
		  </ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>Registration
					  Process</b></font></font></p>
			</li></ul>
		  <ol>
			<ol type="a">
			  <ol>
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>General
						requirements. To use the service, you must be registered as a user
						with the following conditions:</font></font></p>
				  <ol type="a">
					<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>18
							(eighteen) years old;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Have
							an identity card (KTP) for Indonesian citizens, or a passport
							issued by the user's country of origin and/or a Temporary Stay
							Permit Card (KITAP) or a Limited Stay Permit Card (KITAS) for
							foreign nationals;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Use
							your own crypto funds or assets and not crypto funds or assets
							that originate or belong to other people, or are the proceeds of
							crime, money laundering, terrorism financing and/or weapons of
							mass destruction;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Register
							via the ANOA website;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Agree
							and comply with the Terms and Conditions and privacy policy.</font></font></p>
					</li></ol>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Registration
						Process, in carrying out ANOA account registration, users are
						required to provide information that includes but is not limited
						to:</font></font></p>
				</li></ol>
			</ol>
		  </ol>
		  <ol type="a">
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Name;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Address;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Telephone
					Number;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>E-mail;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Place
					and Date of Birth;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Occupation;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>NPWP
					(optional);</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>KTP
					number or other identification (KITAP, KITAS and others, this is
					optional).</font></font></p>
			</li></ol>
		  <ol>
			<ol type="a">
			  <ol start={3}>
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>ANOA
						has the right to request information or other matters other than
						those mentioned in point 2 which are necessary in connection with
						the specified registration requirements. Applicant hereby declares
						and guarantees that every data/ explanation/ document/
						information/ statement provided in connection with the
						registration process as a User is complete, original, correct and
						in accordance with the actual situation and is the latest data/
						explanation/ document/ information/ statement has not been changed
						and is still valid/has not expired and there are no changes or
						other conditions that have been agreed upon based on the
						provisions on the ANOA website registration page.</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
						hereby agrees that access to the ANOA Account is effective after
						all requirements have been met by the User and the registration
						process has gone through a verification process to be approved by
						ANOA. All risks arising in connection with
						closing/blocking/freezing ANOA Account caused by User mistake
						and/or negligence, will be the responsibility of the User and ANOA
						will not provide compensation to the User or other parties in any
						form whatsoever for all claims/claims and compensation. losses
						from the User or any party in connection with the closure of the
						User's ANOA Account.</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
						agrees and authorizes ANOA to use all data, explanations and
						information obtained by ANOA regarding the User including but not
						limited to the use of the User's personal communication means for
						all other purposes as long as possible and permitted by applicable
						laws and regulations, including those intended for marketing
						purposes ANOA or other parties who cooperate with ANOA. For the
						use of data that requires the approval of other parties, the User
						declares that he has given written consent to any third party for
						the use of such data, explanations and information, and therefore
						ANOA will not provide compensation and/or liability in any form to
						the User and any party. for all risks, claims, lawsuits and/or
						compensation that may arise in the future related to the use of
						data, explanations and information that has obtained written
						approval by ANOA.</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Password
						change, Users can reset their password by asking to get a link in
						the registered email so they can enter a new password. We cannot
						manually change or reset user passwords for the safety of all our
						users.</font></font></p>
				</li></ol>
			</ol>
		  </ol>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.29in', marginBottom: '0in'}}>
			<br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>ANOA
					  Account</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Each
					ANOA Account that is registered will be administered by ANOA based
					on special identification according to the User's name which will
					also apply as the name/identity of the ANOA Account according to
					what is stated on User's identity. Users are prohibited from using
					ANOA Account other than the User's account, or accessing other ANOA
					Accounts, or helping others to gain unauthorized access to that
					account. All use of the ANOA Account is the full responsibility of
					the owner of the ANOA Account recorded on the ANOA website.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					must use and include user's email and password that has been
					registered &amp; verified by ANOA during the registration process.
					The ANOA system will automatically refuse access to the ANOA
					Account, if it is not accompanied by the correct email and password.
					The User is responsible for maintaining the confidentiality of the
					password, ANOA Account, Wallet access, email login access and all
					types of activities that include transactions in user's ANOA
					Account. The user is fully responsible for the use of passwords and
					ANOA Accounts. If there is use of a ANOA password and/or account
					without the user's permission and other suspected violations occur,
					the user must contact ANOA by sending an e-mail to info@atn.co.id
					accompanied by supporting information. ANOA is not responsible for
					any losses incurred due to misuse of the ANOA Account and/or
					password, with or without user's knowledge.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Users
					agree not to use ANOA services to commit criminal acts in any form,
					including but not limited to money laundering, gambling, purchasing
					illegal goods, terrorist activities or hacking activities. Any User
					who violates these rules can be terminated and must be responsible
					for losses suffered by ANOA or other users on the ANOA website. ANOA
					has the right to close ANOA Account and freeze funds, Crypto Assets
					and transactions therein if it finds suspicious activity in the ANOA
					Account for an unspecified time.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>SCOPE
					  OF SERVICES</b></font></font></p>
			</li></ul>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
				scope of services on the ANOA website that can be used by users are
				as follows:</font></font></p>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Purchase
					(Top-Up) and transfer of crypto assets according to a predetermined
					list of crypto assets;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Accept
					placement of funds in the form of ANOA tokens;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Withdraw
					funds in the form of ANOA tokens which will be converted into USDT
					to the exchanger (withdrawals in the form of IDR);</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Exchanging
					crypto assets between fellow users on the ANOA website;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Sending
					crypto assets to other users on the ANOA website, as well as users
					of crypto assets outside the ANOA website according to the list of
					crypto assets provided;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Receive
					a deposit in the form of ANOA according to the list of crypto assets
					provided.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>CRYPTO
					  ASSET SYSTEMATIZATION TECHNICAL</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Your
					Wallet allows you to send, receive, and store Crypto Assets, by
					providing instructions through the ANOA Wallet;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
					will process the transaction according to the instructions we
					receive from you. You must verify all transaction information before
					sending us instructions. We do not guarantee the identity of users,
					recipients, applicants or other parties. Transactions cannot be
					reversed once submitted to the relevant Crypto Asset network;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>After
					being submitted to the Crypto Asset network, the User will wait for
					confirmation from the Crypto Asset network for a certain period of
					time. Incomplete transactions while in pending status will be
					rendered accordingly, and will not be included in your Wallet
					balance or made available for transaction;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
					may charge network fees to process transactions on your behalf. We
					will calculate the network fee at our sole discretion, although we
					will always notify you of the network fee at or before the time you
					authorize the transaction;</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
					may refuse to process or cancel pending transactions as required by
					law or any other court or authority to which ANOA is subject to such
					jurisdiction.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Our
					Crypto Asset Services are only available in respect of Crypto Assets
					supported and listed on the ANOA website, and this may change from
					time to time. Under no circumstances may you use Wallet to store,
					send, request, or receive Crypto Assets in any form that We do not
					support. We are not responsible or liable in connection with any
					attempt to use your Wallet for Crypto Assets that are not endorsed
					by us. If you have questions about the Crypto Assets we currently
					support, please visit </font></font><font color="#0563c1"><u><a href="https://anoatoken.com/"><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>https://anoatoken.com</font></font></a></u></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
					do not own or control the underlying software protocols or govern
					the operation of the Crypto Assets supported on the ANOA website:
					generally, the underlying protocols are open source and anyone can
					use, copy, modify and distribute them. We do not responsible for the
					operation of the underlying protocols and we cannot guarantee their
					functionality, security or availability.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>You
					acknowledge and accept the risk that the protocol of the software
					underlying the transaction of any Crypto Asset that you store in
					your Wallet may change. In particular, the underlying protocols are
					likely to be subject to sudden changes in operating rules ("forks"),
					and such forks could materially affect the value, functionality
					and/or name of the Crypto Assets you store in your Wallet. Where
					possible, we may provide you with notices or warnings on forks in
					accordance with the Notices Section of these Terms and Conditions
					and you should read the notices you receive from us so that you can
					consider how to handle future forks. However, it is your
					responsibility to make yourself aware of, and consider how to deal
					with, the upcoming fork. In the case of a fork, there is a risk that
					We may need to temporarily suspend operations in relation to the
					fork without giving you prior notice. We may, in our reasonable
					discretion, decline to support either or both branches of a fork.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>You
					acknowledge the risks presented by a fork and you accept that we
					have no responsibility to assist you in moving or selling an
					unsupported fork of a forked protocol</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>SUSPENSION
					  OF CANCELLATION TERMINATION</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
					may: </font></font>
			  </p>
			  <ol type="a">
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>refuse
						to complete or block, cancel or reverse transactions that you have
						authorized.</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>suspend,
						limit or terminate your access to any or all of the Services,
						and/or </font></font>
				  </p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>deactivate
						or cancel the Account ANOA if things happen, including but not
						more, as follows:</font></font></p>
				  <ol type="i">
					<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>In
							our reasonable opinion, required by applicable law or any court or
							other authority in any jurisdiction;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
							reasonably suspect that you are in breach of these Terms and
							Conditions;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
							reasonably suspect you have violated the Privacy Policy;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
							are concerned that the transaction you made was wrong or violated
							the security of your ANOA Account;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
							suspect the Service is being used in a fraudulent or unauthorized
							manner;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
							suspect money laundering, terrorist financing, fraud or other
							financial crimes;</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Your
							use of your ANOA Account is in the process of litigation,
							investigation, or pending legal proceedings, and/or we perceive a
							high legal risk related to your use of your Account; or</font></font></p>
					</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
						<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>You
							take any action that may circumvent our control such as opening
							multiple ANOA Accounts or abusing promotions we may offer from
							time to time.</font></font></p>
					</li></ol>
				</li></ol>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
					can also refuse to complete a transaction that you agree with if the
					Funds and/or Crypto Assets are insufficient to carry out a
					transaction.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
					we refuse to complete the transaction or close your ANOA Account or
					stop using the Service with the conditions stated in this section,
					We will (except as prohibited by law) give You notice of Our actions
					and the reasons for the rejection, suspension or termination and if
					necessary, with procedures to correct any factual errors that led to
					the rejection, suspension or closure. If we refuse to complete the
					transaction and/or suspend your ANOA Account, we will lift the
					suspension as soon as possible after the reasons for the rejection
					and/or suspension have been resolved. However, we are under no
					obligation to enable you to reverse a transaction at the same price
					or on the same terms as a suspended or cancelled transaction.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>We
					may suspend, limit or terminate your access to any or all of the
					services and/or disable or cancel your ANOA Account, without reason
					by giving you 1 (one) month's notice prior to the effective date of
					closure or cancellation. You acknowledge that Our decision to take
					certain actions, including limiting access to, suspending, or
					closing Your ANOA Account, may be based on confidential criteria
					that are important to our risk management and security protocols.
					You agree that we are under no obligation to disclose details of our
					risk management and security procedures to you.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
					we suspend or close your Account, or stop using the Service for any
					reason, We have the right to require You to complete the procedures
					outlined in the ANOA Account Registration section before allowing
					You to transfer or withdraw Crypto Assets or funds.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>You
					can close your ANOA Account at any time by withdrawing all balances
					by sending an email to info@atn.co.id. You will not be charged a fee
					to close your ANOA Account, although you will be required to pay the
					amount owed to us (if any). You allow us to cancel or suspend
					pending transactions at the time of cancellation.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>In
					the event of an Account termination, the User agrees and states that
					he is willing to remain bound by the Terms and Conditions and stop
					using ANOA services, grant ANOA the right to delete all information
					and data on the ANOA server, and states that ANOA is not responsible
					to Users or third parties for termination of access and abolition of
					information and data in the ANOA Account.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>RESPONSE,
					  QUESTIONS, COMPLAINTS, AND PROBLEM SETTLEMENT</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
					you have comments, questions, complaints or complaints that you want
					to submit, contact us via our customer support website page at
					https://anoatoken.com. When you contact us, provide your name,
					registered username/email address, and other information we need to
					identify you, your ANOA Account, and the transaction in which you
					received your feedback, question, or complaint.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
					you want to submit a complaint, you are expected to state the cause
					of the complaint, how you want us to resolve the complaint and other
					information that you believe is relevant. We will acknowledge
					receipt of your complaint if you contact us through our customer
					support web page. The Customer Complaints Officer ("Officer")
					will consider your complaint. Officers will consider your complaint
					without prejudice based on the information you provide and any
					information provided by ANOA. Within 15 (fifteen) working days of
					receipt of your complaint, the Officer will discuss all the points
					raised in your complaint by sending an e-mail ("Resolution
					Notification") in which the Officer will: (i) offer to resolve
					your complaint by what you ask; (ii) make a determination to reject
					your complaint and explain the reasons for the refusal; or (iii)
					offer to resolve your complaint with an alternative solution. In
					exceptional circumstances, if the Officer is unable to respond to
					your complaint within 15 (fifteen) working days for reasons beyond
					ANOA's control, the Officer will send a reply indicating the reason
					for the delay in responding to your complaint and setting the time
					limit used by the Officer. will respond to your complaint (which is
					not later than 35 (thirty five) working days from the time we
					receive your complaint.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>REPRESENTATIONS
					  AND WARRANTIES</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>All
					services on the ANOA Site do not provide any guarantees or
					guarantees and ANOA does not guarantee that the ANOA website will
					always be accessible at any time due to periodic system and network
					interruptions or maintenance.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					user declares and guarantees that he will use the Service properly
					and responsibly and does not take actions that are contrary to the
					laws, laws and regulations that apply in the territory of the
					Republic of Indonesia.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					user declares and guarantees that he will not use the Service in
					trading Crypto Assets related to: (i) narcotics, materials and/or
					chemicals for research; (ii) money and anything similar to money,
					including derivatives; (iii) goods and/or services that violate
					Intellectual Property Rights; (iv) ammunition, firearms, explosives,
					sharp weapons in accordance with the applicable legal provisions;
					(v) goods and/or services that reveal personal information from
					unlawful third parties; (vi) goods and/or services related to
					lottery purchases, lay-away; (vii) services related to banking
					outside the territory of the Republic of Indonesia; (viii) Card
					payments; (ix) Credit settlement and/or; (x) support for banned or
					banned organizations by the government.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Users
					need to declare and guarantee not to use the Service for actions
					that are involved in gambling practices and/or other activities that
					charge an entry fee and promise prizes, including but not limited to
					casino games, gambling in sports, businesses that facilitate
					gambling and lottery methods.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					represents and guarantees that all data, information and documents
					provided by User to ANOA are true, valid, honest, transparent,
					complete and accurate data, information and documents. The user
					states that he is willing to be prosecuted criminally or civilly
					sued if ANOA knows or obtains information from any party that the
					data, information and documents provided by the User are
					incorrect/not completely true/false. The user is willing to update
					data/information (profile update) at any time requested by ANOA and
					then all documents that have been provided become fully owned by
					ANOA.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>ANOA
					has provided sufficient information and explanation regarding ANOA
					services that will be used by Users in accordance with the
					provisions of the Terms and Conditions and Users have understood and
					understood and are willing to bear all the consequences that may
					arise in connection with the use of ANOA services including
					benefits, risks and costs costs attached to services and services.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					hereby agrees and authorizes ANOA to use all data, information and
					information obtained by ANOA regarding User including but not
					limited to the use of User's personal communication means for all
					other purposes as long as it is possible and permitted by applicable
					laws, including which aims to market ANOA products or other parties,
					who cooperate with ANOA. For the use of data that requires the
					approval of other parties, the User hereby declares that he has
					obtained written approval from any third party for the use of such
					data, information and information, and therefore the User guarantees
					and agrees that ANOA will not provide compensation and/or liability
					in any form to the User or any party for all risks, losses, demands
					and/or responsibilities that may arise in the future in connection
					with the use of data, information and information that has obtained
					written approval by ANOA.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					declares and guarantees that transactions carried out using the
					Service are transactions that do not violate the provisions of the
					applicable laws and regulations and the provisions regarding
					acceptance of the implementation of transactions as stipulated in
					the Terms and Conditions. In the event that ANOA finds indications
					of carrying out transactions that are not in accordance with the
					provisions of the applicable laws and regulations and or provisions
					regarding acceptance of the implementation of transactions regulated
					in the Terms and Conditions, then ANOA has the full right to close
					User's ANOA Account by deactivating the Service, and the User hereby
					agrees that ANOA hereby will not provide compensation and or
					liability in any form to the User, or any party for any claims and
					or demands arising in connection with the deactivation of the
					Service to User.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					represents and guarantees that the risks to the use of the third
					party services, products and promotions with the User (if any), are
					borne by the User, and the User declares that ANOA is not
					responsible for the services and performance of third party
					services.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					hereby agrees and authorizes ANOA to use all data, information and
					information obtained by ANOA regarding User including but not
					limited to the use of the User's personal communication means for
					all other purposes as long as it is possible and permitted by
					applicable laws, including which aims to market ANOA products or
					other parties, who cooperate with ANOA. For the use of data that
					requires the approval of other parties, the User hereby declares
					that he has obtained written approval from any third party for the
					use of such data, information and information, and therefore the
					User guarantees and agrees that ANOA will not provide compensation
					and/or liability in any form to the User or any party for all risks,
					losses, demands and/or responsibilities that may arise in the future
					in connection with the use of data, information and information that
					has been obtained written approval by ANOA.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Users
					are hereby fully responsible and agree that ANOA will not provide
					compensation and or liability in any form to Users or any party for
					any losses and or claims and or demands that arise or may be
					experienced by ANOA as a result of negligence or failure User in
					terms of password security or credentials from the User's ANOA
					Account.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					User represents and guarantees that the risks to the use of
					third-party services, products and promotions with the User (if
					any), are borne by the User, and the User declares that ANOA is not
					responsible for the services and performance of third party
					services.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>RESPONSIBILITIES</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					User agrees to bear any risks, losses or consequences suffered by
					the User caused by, among others:</font></font></p>
			  <ol type="a">
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>damage,
						delay, loss or error in sending orders and communications,
						electronically caused by the User;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>ANOA
						Account Reports or notifications on the use of ANOA services sent
						to Users are received or read or misused by parties who are not
						authorized to the ANOA Account;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
						ANOA Account Password is known by another person/party due to User
						error.</font></font></p>
				</li></ol>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					User understand and agree that User will use the ANOA Account and
					Services for transactions that do not conflict with the provisions
					of laws and regulations and/or applicable ANOA internal policies
					and/or other regulations that apply nationally or internationally
					related to the implementation the transaction either directly or
					indirectly, and ANOA will not provide compensation and/or liability
					in any form to the User or any party for any claims and/or demands
					and/or losses arising in connection with the use of the Service by
					the User for transactions involving categorized as suspicious
					transactions and/or transactions that are prohibited by applicable
					laws and/or ANOA internal policies and/or other regulations that
					apply both nationally and internationally related to transaction
					activities carried out by Users. directly or indirectly.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>In
					conducting transactions using the ANOA website, Users understand and
					agree that there are certain sanctions imposed by the government,
					including governments of other countries, and/or other authorized
					agencies against several countries, entities and individuals.
					Referring to this, ANOA has the right not to carry out/process
					transactions that are a violation of the provisions of these
					sanctions, and the competent authority may require the disclosure of
					related information. ANOA is not responsible if ANOA or other
					parties fail or delay the implementation of transactions, or there
					is disclosure of information as a result of direct or indirect
					violation of the provisions of these sanctions.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>RISK</b></font></font></p>
			</li></ul>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Without
				prejudice to the provisions regarding the prohibitions contained in
				the Terms and Conditions, Users are prohibited from doing the
				following:</font></font></p>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Users
					are not allowed to take actions that can cause harm to ANOA and or
					which are contrary to the Terms and Conditions and applicable laws
					and regulations.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
					User violates one or more of the provisions in these terms, User
					will be fully responsible and hereby agrees that for this violation
					ANOA has the right to limit the features of using ANOA services
					until ANOA Account is closed and the User acknowledges and agrees
					that ANOA will not provide compensation and or liability in any form
					to the User or any party for all risks or consequences arising from
					the restriction on the use of services and closing the ANOA Account.
					The user is then obliged to pay compensation to ANOA in the amounts
					of losses that ANOA may experience for the violation. User hereby
					authorizes ANOA to debit User Account for payment of such
					compensation in the form of ANOA tokens. In the event that the Funds
					in User's Account are not available and/or insufficient, the User
					must return all of the Funds to ANOA no later than 7 (seven) working
					days after ANOA submit the said claim.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>NON
					  DISCLOSURE</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Any
					information and or data provided by ANOA to User and or data
					obtained by User as the implementation of the Terms and Conditions
					whether given or delivered orally, in writing, graphically or
					delivered via electronic media or information and or data in other
					forms during the conversation or during other confidential work
					(hereinafter referred to as "Confidential Information");</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Users
					agree and agree that at any time they will keep confidential
					information obtained as a result of collaboration with anyone or
					will not use it for the benefit of the User or the interests of
					other parties, without first obtaining written approval from an
					authorized official from ANOA or other authorized party in
					accordance with applicable legal provisions.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
					the User violates the provisions referred to in numbers 1 and 2 of
					this provision regarding confidentiality, then all losses, claims
					and or lawsuits experienced by ANOA are the full responsibility of
					User, and at the first request from ANOA, User is obliged to resolve
					them in accordance with legal provisions and applicable laws and
					provide compensation that may arise as a result of the violation to
					ANOA. User hereby agrees that ANOA will not provide compensation and
					or liability in any form to User or any party for any claims and or
					claims that may arise in the future in connection with the
					violation.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					obligation to store confidential information as referred to in
					numbers 1 and 2 regarding confidentiality shall not apply if:</font></font></p>
			  <ol type="a">
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Such
						confidential information becomes available to the general public;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Confidential
						information ordered to be disclosed to comply with a court order or
						other authorized government agency;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Confidential
						information is provided in accordance with applicable legal
						provisions.</font></font></p>
				</li></ol>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					user then agrees to make every effort and take every necessary
					action to prevent third parties from gaining access to or resulting
					in the disclosure of confidential information.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>In
					the event that the collaboration has ended, User agrees that the
					obligation to keep documents and materials that constitute
					confidential information as stipulated in this provision will remain
					in force.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>NEGLIGENCE</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					user agrees that what is meant by Negligence (“Default”) is the
					following matters or events:</font></font></p>
			  <ol type="a">
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Negligence;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
						the User is negligent in carrying out an obligation or violates a
						provision in the Terms and Conditions;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Incorrect
						Statement;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>If
						it turns out that a statement or guarantee given by the User in the
						Terms and Conditions - is incorrect or not in accordance with
						reality.</font></font></p>
				</li></ol>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>In
					the case of an incident of negligence based on point 1 of this
					negligence provision by the User, ANOA can choose whether to
					continue or close User's Account. If ANOA wishes to close User's
					Account, such intention must be notified to User within a reasonable
					time according to ANOA.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>In
					the case of negligence by User as intended, then ANOA has the right
					to immediately deactivate ANOA Account without having to make prior
					notification to the User, and User hereby agrees that ANOA will not
					provide compensation and or liability in any form to the User. or
					any party for all claims and or lawsuits and or claims and or
					requests for compensation from any party that may arise in
					connection with the occurrence of such negligence.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>TAX</b></font></font></p>
			</li></ul>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Whereas
				the tax on trading activities of Crypto Assets is the tax that is
				borne by each activity actor, in this case the User and ANOA. Users
				can consult tax payments with Personal Tax Consultants, and ANOA does
				not bear User taxes unless otherwise specified in these Terms and
				Conditions.</font></font></p>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>SECURITY</b></font></font></p>
			</li></ul>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>ANOA
				has implemented a network and security measures as an information
				security network against unauthorized access in using, changing
				and/or disclosing accounts, with security standards that are in
				accordance with the provisions of the applicable laws and
				regulations. The entry of the party is not responsible for access to
				the use, change and/or disclosure of accounts from third parties due
				to negligence and/or user error resulting in the user's risk being
				borne, so ANOA will not provide compensation and or liability in any
				form to the user or other parties any risks, responsibilities and
				claims that may arise in connection with the
				negligence/intentional/error of the User in providing information.</font></font></p>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<br />
		  </p>
		  <p align="justify" style={{lineHeight: '108%', marginLeft: '0.5in', marginBottom: '0in'}}>
			<br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>FORCE
					  MAJEUR</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>What
					is meant by Force Majeure are events that occur beyond the ability
					and power of ANOA so that they affect the implementation of
					transactions, including but not limited to:</font></font></p>
			  <ol type="a">
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Earthquakes,
						hurricanes, floods, landslides, volcanic eruptions and other
						natural disasters;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Wars,
						demonstrations, riots, terrorism, sabotage, embargoes, and mass
						strikes;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Economic
						policies from the Government that affect directly.</font></font></p>
				</li></ol>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>As
					long as ANOA has carried out all its obligations in accordance with
					the applicable laws and regulations in connection with the
					occurrence of the Force Majeure, then ANOA will not provide
					compensation and or liability in any form to the User or any other
					party for any risks, responsibilities and claims whatsoever which
					may arise in connection with delays or non-performance of
					obligations due to Force Majeure.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>LIMITATION
					  OF LIABILITY</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>ANOA
					always strives to maintain a safe, convenient and well-functioning
					Service, but we cannot guarantee continuous operation or that access
					to our Service is always flawless. There is a possibility that the
					information and data on ANOA website may not be available in real
					time.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>ANOA
					does not guarantee that the results obtained from the use of the
					service will be accurate or reliable.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					User agrees that User uses ANOA Service at the User's own risk, and
					that ANOA Service is provided to the User on an "AS IS"
					and "AS AVAILABLE" basis.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>To
					the extent permitted by applicable law, ANOA (including the Parent
					Company, directors and employees) is not responsible, and the User
					agrees not to hold ANOA liable, for damages or losses (including but
					not limited to loss of money, reputation, profits, or other
					intangible losses) which are directly or indirectly caused by the
					following:</font></font></p>
			  <ol type="a">
				<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User's
						inability to use ANOA services is included in personal risk;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Loss
						of Use, Loss of Profits, Loss of Revenue, Loss of Data, Loss of
						Goodwill, or Failure to realize expected savings, in any case
						directly or indirectly;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Any
						losses caused by the User's negligence including but not limited to
						negligence in logging in via third party devices and / or failure
						to maintain the confidentiality of the device used for logging in;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Condition
						and quality of products or tradable Crypto Assets;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Intellectual
						Property Rights Violation;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Disputes
						between Users;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Defamation
						of other parties;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Any
						misuse of Assets purchased by Users;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Viruses
						or other malware (bots, scripts, automation tools) obtained by
						accessing or connecting to the Service;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
						process of skimming or hacking, which causes losses to the User in
						the ANOA Service;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Disturbances,
						bugs, errors or inaccuracies in the Service;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Damage
						to your hardware from use of any Service;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Enforcement
						actions taken in relation to User accounts;</font></font></p>
				</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
					<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>Any
						hacking performed by third parties on User's account;</font></font></p>
				</li></ol>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					acknowledges and agrees that User's only right with respect to
					service problems or dissatisfaction is to stop using the Service.</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <ul>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>NOTIFICATION</b></font></font></p>
			</li></ul>
		  <ol>
			<li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>User
					agrees to communicate with ANOA in electronic format, and agrees
					that all terms, conditions, agreements, notices, disclosures or any
					other forms of communication provided by ANOA to Users
					electronically are considered as written.</font></font></p>
			</li><li><p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}>
				<font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>The
					user agrees to receive emails from the ANOA website. E-mails sent
					can contain information about accounts, transactions, systems,
					promotions and so on</font></font></p>
			</li></ol>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><br />
		  </p>
		  <p align="justify" style={{lineHeight: '108%', marginBottom: '0in'}}><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>I
				declare that I have received and read the "</font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}><b>TERMS
				  AND CONDITIONS</b></font></font><font face="Arial, serif"><font size={3} style={{fontSize: '12pt'}}>"
				USER AGREEMENT understand and agree to its contents.</font></font></p>
		</div>
		</>
	);
}

export default TermsCondition;