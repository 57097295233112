import { Collapse, IconButton, Link, colors } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import cryptoJS from 'crypto-js';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { setAuthUser, setRequestNewPassword } from '../../../../redux/actions/Auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ContentLoader from '@jumbo/components/ContentLoader';
import AuthWrapper from '@jumbo/components/Common/authComponents/AuthWrapper';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const TransferConfirmation = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_request_new_password } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);

  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const [verificationCodeErrorisShow, setVerificationCodedErrorisShow] = useState(false);
  const [message, setMessage ] = useState('');
  const [colornow, setcolornow] = useState('');
  const [encrptedData, setEncrptedData] = useState('');
  const [decrptedData, setDecrptedData] = useState('');

  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  // const history = useHistory();
  const { id } = useParams();
  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };
  const secretPass = 'XkhZG4fW2t2W';

  const encryptData = () => {
    const data = CryptoJS.AES.encrypt(JSON.stringify('andy'), secretPass).toString();
    console.log(data);
    setEncrptedData(data);
  };

  const decryptData = () => {
    const bytes = CryptoJS.AES.decrypt('U2FsdGVkX1+aWCziICggSX4zMkqBTQVxR0+QNr3hWLM=', secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log(data);
    setDecrptedData(data);
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      // await loadScriptByURL(
      //   'recaptcha-key',
      //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //   function() {
      console.log('Script loaded!');
      //   U2FsdGVkX1/6y+BVosXGuEFgBxLU2Z5+yj1Sr84GzdM=
      // //   encryptData();
      //   setTimeout(()=>{

      //   },2000);
      //   decryptData();
      //   },
      // );
    };

    loadScriptRecaptcha().catch(console.error);
    const onSubmit = async () => {
      //  dispatch(setProcessing(true));
      await requestSubmit()
        .then(() => {
          setTimeout(() => {
            clearState();
          }, 2500);
        })
        .catch(arrClear => {
          setTimeout(() => {
            clearState(arrClear);
          }, 2500);
        });
      // dispatch(setProcessing(false));
    };
    onSubmit();
    let timeOutStopper = null;
    if (send_request_new_password) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setRequestNewPassword(false));
      }, 3000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_request_new_password]);

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const requestSubmit = async () => {
    let index = JSON.parse(localStorage.getItem('user'));
    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: '91ca4d5ee4fd260cd72d67087bf89ae93eecf761ef90eb934fd06a2366fc7131',
      },
    };

    let parameters = {
      codeverification: id,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/confirm',
          parameters,
          headers,
        )
        .then(function(response) {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            setMessage('Transfer wallet has been successfully');
            setcolornow('green')
            // Swal.fire({
            //   icon: 'success',
            //   title: 'Success',
            //   text: 'Transfer wallet has been successfully',
            // });
            resolve();
            // setTimeout(() => {
            //   window.location = '/history';
            // }, 2000);
          } else {
            let arrError = [];
            let arrClear = [];
            setMessage(res.status.message[0].errormassage);
            setcolornow('red')
            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888005040010')) {
              // setVerificationCodeError("email verification code is not valid");
              // setVerificationCodedErrorisShow(true);
              // arrClear.push("verificationcode");
              // Swal.fire({
              //   icon: 'error',
              //   title: 'Oops!',
              //   text: 'email verification code is not valid',
              // });
            }
            if (arrError.find(item => item.code === '888999999999')) {
              // setVerificationCodeError("email verification code is not valid");
              // setVerificationCodedErrorisShow(true);
              // arrClear.push("verificationcode");
              // Swal.fire({
              //   icon: 'error',
              //   title: 'Oops!',
              //   text: 'transfer asset is failed',
              // });
            }

            if (arrError.find(item => item.code === '888999999940')) {
              // Swal.fire({
              //   icon: 'error',
              //   title: 'Oops!',
              //   text: 'Unauthorized',
              // });
              dispatch(setAuthUser(null));
            }

            reject(arrClear);
          }
        })
        .catch(function(error) {
          console.log(error)
          alert(error);
        });
    });
  };

  const clearState = (clearParams = []) => {
    if (clearParams.includes('verificationcode')) {
      setVerificationCode('');
      setVerificationCodeError('');
      setVerificationCodedErrorisShow(false);
    }
  };


  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </div>
        {/* <Typography component="div" variant="h1" className={classes.titleRoot}>
          Verify Code
        </Typography> */}
        {/* <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            Password has been changed, now you're logged out.
          </Alert>
        </Collapse> */}
        <form>
          <div className={'mb-5'}>
            {/* <TextField
              error={verificationCodeErrorisShow }
              id={verificationCodeErrorisShow ? 'outlined-error-helper-text' : ''}
              type="password"
              label={'Code'}
              fullWidth
              onChange={event => setVerificationCode(event.target.value)}
              value={verificationCode}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={verificationCodeError}
            /> */}
            <Collapse in={true}>
              <Alert
                variant="outlined"
                severity={colornow === 'green' ? "success" : "error"}
                className={classes.alertRoot}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}>
                    {/* <CloseIcon fontSize="inherit" /> */}
                  </IconButton>
                }>
                  <AlertTitle> {colornow !== 'red'? 'Success!' : 'Error'}</AlertTitle>
                   {message}
              </Alert>
            </Collapse>
          </div>

          <div className={'mb-5'}>
            <Button onClick={()=>{window.location = '/history'}} variant="contained" color="primary">
              Back to Anoa
            </Button>
          </div>

          <div>
            {/* <Typography>
              Don't remember your email?
              <span className={'ml-2'}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </span>
            </Typography> */}
          </div>
        </form>
        <p style={{ fontSize: 11, alignSelf: 'center' }}>{process.env.REACT_APP_VERSION}</p>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default TransferConfirmation;
