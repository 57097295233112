import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import ChangePasswordPage from './Auth/ChangePassword';
import ForgotPasswordPage from './Auth/ForgotPassword';
import ResetUsernamePage from './Auth/ResetUsername';
import Login from './Auth/Login';
import NewPasswordPage from './Auth/NewPassword';
import smsRequestOtp from './Auth/PhoneVerification';
import Register from './Auth/Register';
import Registration from './Auth/Registration';
import ResetGauthPage from './Auth/ResetGauth';
import ResetPinPage from './Auth/ResetPin';
import Error404 from './Pages/404';
import ComingSoonPage from './Pages/ComingSoon';
import Dashboard from './Pages/Dashboard/Misc';
import DepositPage from './Pages/Deposit';
import ProfilePage from './Pages/Profile';
import TransactionSuccess from './Pages/TransactionSuccess';
import TransferPage from './Pages/Transfer';
import WalletPage from './Pages/Wallet';
import WalletDashboard from './Pages/WalletDashboard';
import Captcha from './Pages/Captcha';
import SavedWallet from './Pages/SavedWallet';
import SavedWalletRename from './Pages/SavedWallet/Rename';
import SavedWalletInactive from './Pages/SavedWallet/Inactive';
import Unpaid from './Pages/Unpaid';
import Transfer2 from './Pages/Transfer2';
import Transfer2Confirmation from './Pages/Transfer2/Confirmation';
// import { getFcmToken, onMessageListener } from '../services/firebase';
import { useSnackbar } from 'react-simple-snackbar';
import TopUp from './Pages/Topup';
import History from './Pages/History/Misc';
import Kyc from './Pages/Kyc';
// import { Store } from 'react-notifications-component';
const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  if (!authUser) {
    localStorage.clear();
  }

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);

  // getFcmToken(setTokenFound);

  // onMessageListener()
  //   .then(payload => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });

  //     Store.addNotification({
  //       title: payload.notification.title,
  //       message: payload.notification.body,
  //       type: payload.data.typenotif,
  //       insert: 'top',
  //       container: 'top-right',
  //       animationIn: ['animate__animated', 'animate__fadeIn'],
  //       animationOut: ['animate__animated', 'animate__fadeOut'],
  //       dismiss: {
  //         duration: 6000,
  //         onScreen: true,
  //       },
  //     });

  //     // openSnackbar(payload.notification.body, 30000);
  //   })
  //   .catch(err => console.log('failed: ', err));

  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const dispatch = useDispatch();
  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/captcha" component={Captcha} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/reset-username" component={ResetUsernamePage} />
        <Route path="/registration/:refby" component={Registration} />
        <Route path="/signup" component={Register} />
        <Route path="/new-password/:verifid" component={NewPasswordPage} />
        <Route path="/sms-request-otp" component={smsRequestOtp} />
        <Route path="/coming-soon" component={ComingSoonPage} />
        <RestrictedRoute path="/dashboard" component={Dashboard} />
        <RestrictedRoute path="/reset-new-pin" component={ResetPinPage} />
        <RestrictedRoute path="/reset-new-gauth" component={ResetGauthPage} />
        <RestrictedRoute path="/profile" component={ProfilePage} />
        <RestrictedRoute path="/wallet/new" component={WalletPage} />
        <RestrictedRoute path="/wallet/dashboard/:walletaccount" component={WalletDashboard} />
        <RestrictedRoute path="/deposit/new/:walletindex" component={DepositPage} />
        <RestrictedRoute path="/transfer/new/:walletindex" component={TransferPage} />
        <RestrictedRoute path="/transaction/success" component={TransactionSuccess} />
        <RestrictedRoute path="/change-password" component={ChangePasswordPage} />
        <RestrictedRoute path="/savedwallet/new" component={SavedWallet} />
        <RestrictedRoute path="/savedwallet/rename/:walletindex" component={SavedWalletRename} />
        <RestrictedRoute path="/savedwallet/inactive/:walletindex" component={SavedWalletInactive} />
        <RestrictedRoute path="/topup" component={TopUp} />
        <RestrictedRoute path="/transfer" component={Transfer2} />
        <RestrictedRoute path="/confirm-transfer/:id" component={Transfer2Confirmation} />
        <RestrictedRoute path="/unpaid" component={Unpaid} />
        <RestrictedRoute path="/history" component={History} />
        <RestrictedRoute path="/kyc" component={Kyc} />

        <Route path="*" component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
