import { Box, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { alpha, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import cryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import CmtImage from '../../../@coremat/CmtImage';
import AuthWrapper from '../../../@jumbo/components/Common/authComponents/AuthWrapper';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const ComingSoon = ({ method = CurrentAuthMethod, variant = 'default_zero', wrapperVariant = 'default' }) => {
  const classes = useStyles({ variant });
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const onSubmit = async () => {
    if (name === '') {
      setNameError('Name must filled!');
    } else if (email === '') {
      setEmailError('Email must filled!');
    } else {
      let token = await handleGetTokenRecaptcha();

      var String = '';

      String += 'WEBSITE';
      String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
      String += name;
      String += email;

      let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

      let parameters = {
        platform: 'WEBSITE',
        gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
        tablesubscribername: name,
        tablesubscriberemail: email,
        signature: signature.toString(),
      };

      await axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/subscriber/v01/store',
          parameters,
        )
        .then(function(response) {
          let res = response.data;

          if (res.status.code === '080000000000') {
            alert(`We all thank you ${name} for subscribe. We will keep in touch with you until this future release.`);
            window.location.href = '/';
          } else {
            alert(response.status.errormessage);
          }
        });
    }
  };

  const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        function() {
          console.log('Script loaded!');
        },
      );
    };

    loadScriptRecaptcha().catch(console.error);
  }, [dispatch]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}

      <Box className={classes.authContent}>
        <Box style={{ textAlign: 'center' }}>
          <CmtImage src={'https://cdn.dribbble.com/users/88000/screenshots/2487367/shot.png'} />
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <TextField
                error={emailError === '' ? false : true}
                id={emailError === '' ? '' : 'outlined-error-helper-text'}
                label={'Email'}
                fullWidth
                onChange={event => setEmail(event.target.value)}
                defaultValue={email}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                helperText={emailError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                error={nameError === '' ? false : true}
                id={nameError === '' ? '' : 'outlined-error-helper-text'}
                label={'Name'}
                fullWidth
                onChange={event => setName(event.target.value)}
                defaultValue={name}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                helperText={nameError}
              />
            </Grid>
          </GridContainer>

          <Button onClick={() => (window.location.href = '/')} variant="contained" color="primary">
            Back To Home
          </Button>
          <Button onClick={() => onSubmit()} variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Box>
    </AuthWrapper>
  );
};

export default ComingSoon;
