import { SET_WALLET_PASS_PHRASE } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  set_wallet_pass_phrase: '',
  set_wallet_step: 'STEP1',
  set_wallet_id: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_WALLET_PASS_PHRASE: {
      return {
        ...state,
        set_wallet_pass_phrase: action.payload.passPhrase,
        set_wallet_step: action.payload.step,
        set_wallet_id: action.payload.walletid,
      };
    }
    default:
      return state;
  }
};
