import axios from 'axios';
import React from 'react';
import { setWalletPassphrase } from 'redux/actions/Wallet';
import Translation from '../../../i18n/entries/Service';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import {
  setAuthUser,
  setForgetPassMailSent,
  setLastStep,
  setRequestNewGauth,
  setRequestNewPassword,
  setRequestNewPin,
  updateLoadUser,
  setRequestOtp,
  setSmsVerificationStep,
  setPhonenumber,
} from '../../../redux/actions/Auth';

import Swal from 'sweetalert2';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        const user = { name: name, email: email, password: password };
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  onLogin: ({ parameters }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        axios
          .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/signin', parameters)
          .then(function(response) {
            let res = response.data;
            // eslint-disable-next-line
            console.log(res);
            if (res.status.code === '080000000000') {
              const user = {
                usernick: res.userinfo.tableusernick,
                username: res.userinfo.tableusername,
                userindex: res.userinfo.tableuserindex,
                usertoken: res.userinfo.tokenlogin,
                userref: res.userinfo.tableuserreferralcode,
                user2fatype: res.userinfo.tableuser2fatype,
              };
              localStorage.setItem('user', JSON.stringify(user));
              localStorage.setItem('LangApps', 'EN');

              dispatch(setAuthUser(user));
              dispatch(setLastStep());
              if (sessionStorage.getItem('set_last_url')) {
                window.location.href = sessionStorage.getItem('set_last_url');
              }
              dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
            } else if (res.status.code === '080005015015') {
              Swal({
                icon: 'error',
                title: 'Oops!',
                text: Translation.getServiceLanguage(res.status.code),
              });
            }
          })
          .catch(function(error) {
            alert(error);
            dispatch(fetchError(error));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      // dispatch(fetchStart());

      setTimeout(() => {
        // dispatch(fetchSuccess());
        
        dispatch(setAuthUser(null));
       
        window.location.href = '/signin';
      }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
      }, 300);
    };
  },

  onForgotPassword: ({ parameters }) => {
    return dispatch => {
      dispatch(fetchStart());
      let headers = {
        headers:{
          gtoken:"PASSS",
          signature:"be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457"
        }
      }
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/auth/v01/forgotpassword',
          parameters,headers
        )
        .then(function(response) {
          let res = response.data;
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
        })
        .catch(function(error) {
          alert(error);
          dispatch(fetchError(error));
        });
    };
  },
  onSetNewPassword: ({ password, c_password, verifid }) => {
    return dispatch => {
      dispatch(fetchStart());
      // eslint-disable-next-line
      if (password != c_password) {
        dispatch(fetchError('Password must match!'));
      } else {
        axios
          .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/newpassword', {
            tableuseremailverificationcode: verifid,
            tableuserpasswordnew: password,
            signature: '83468b7dff3746fb5b226ec09c9935ae5d30bd12cc4588ad4cb1f232c8f960ee',
          })
          .then(function(response) {
            let res = response.data;
            if (res.status.code === '050000000000') {
              dispatch(setRequestNewPassword(true));
              dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
              window.location.reload();
            } else {
              dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error));
          });
      }
    };
  },
  onChangePassword: ({parameters,userindex,usertoken}) => {
    return dispatch => {
      dispatch(fetchStart());
      // eslint-disable-next-line
      let headers = {
        headers:{
          platform:"WEBSITE",
          gtoken:"PASSS",
          userindex:userindex,
          tokenlogin:usertoken,
          signature:"dsafdsafdsafdsaf"
        }
    };
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/changepassword',
          parameters,
          headers
        )
        .then(function(response) {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            dispatch(setRequestNewPassword(true));
            dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
            setTimeout(() => {
              dispatch(BasicAuth.onLogout());
            }, 2000);
            sessionStorage.removeItem('set_last_url');
          }else if(res.status.code === '888999999940'){
            dispatch(setRequestNewPassword(false));
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
            setTimeout(() => {
              dispatch(BasicAuth.onLogout());
            }, 2000);
            sessionStorage.removeItem('set_last_url');
          }else {
            dispatch(setRequestNewPassword(false));
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error));
        });
    };
  },
  checkPhonenumber: ({ countrycode, phonenumber, index }) => {
    return dispatch => {
      dispatch(fetchStart());
      //eslint-disable-next-line
      axios
        .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/checkphonenumber', {
          userindex: index.userindex,
          username: index.username,
          usertoken: index.usertoken,
          countrycode: parseInt(countrycode),
          phonenumber: parseInt(phonenumber),
          signature: '83468b7dff3746fb5b226ec09c9935ae5d30bd12cc4588ad4cb1f232c8f960ee',
        })
        .then(function(response) {
          let res = response.data;
          // eslint-disable-next-line
          if (res.status.code === "050000000000") {
            dispatch(setSmsVerificationStep('STEP2'));
            dispatch(setPhonenumber(countrycode + phonenumber));
            dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
            sessionStorage.removeItem('set_last_url');
          } else if (res.status.code === '050005050005') {
            dispatch(setSmsVerificationStep(null));
            sessionStorage.setItem('set_last_url', '/sms-request-otp');
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
          } else {
            dispatch(setSmsVerificationStep(null));
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error));
        });
    };
  },
  smsRequestOtp: ({ index }) => {
    return dispatch => {
      dispatch(fetchStart());
      //eslint-disable-next-line
      axios
        .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/smsrequestotp', {
          userindex: index.userindex,
          username: index.username,
          usertoken: index.usertoken,
          signature: '83468b7dff3746fb5b226ec09c9935ae5d30bd12cc4588ad4cb1f232c8f960ee',
        })
        .then(function(response) {
          let res = response.data;
          // eslint-disable-next-line
          if (res.status.code === "050000000000") {
            dispatch(setRequestOtp(true));
            dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
            sessionStorage.removeItem('set_last_url');
          } else if (res.status.code === '050005050005') {
            dispatch(setRequestOtp(false));
            sessionStorage.setItem('set_last_url', '/sms-request-otp');
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
            // setTimeout(() => {
            //   dispatch(BasicAuth.onLogout());
            // }, 2000);
          } else {
            dispatch(setRequestOtp(false));
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error));
        });
    };
  },
  smsVerification: ({ vcode, index }) => {
    return dispatch => {
      dispatch(fetchStart());
      //eslint-disable-next-line
      axios
        .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/smsverificationotp', {
          userindex: index.userindex,
          username: index.username,
          usertoken: index.usertoken,
          otp: vcode,
          signature: '83468b7dff3746fb5b226ec09c9935ae5d30bd12cc4588ad4cb1f232c8f960ee',
        })
        .then(function(response) {
          let res = response.data;
          // eslint-disable-next-line
          if (res.status.code === "050000000000") {
            //dispatch(setRequestOtp(true));
            dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
            sessionStorage.removeItem('set_last_url');
            setTimeout(() => {
              window.location.href = '/profile';
              //dispatch(BasicAuth.onLogout());
            }, 2000);
          } else if (res.status.code === '050005050005') {
            //dispatch(setRequestOtp(false));
            sessionStorage.setItem('set_last_url', '/sms-request-otp');
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
            // setTimeout(() => {
            //   dispatch(BasicAuth.onLogout());
            // }, 2000);
          } else {
            //dispatch(setRequestOtp(false));
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error));
        });
    };
  },
  onSetNewPin: parameters => {
    return dispatch => {
      dispatch(fetchStart());
      // eslint-disable-next-line

      axios
        .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/resetpin', parameters)
        .then(function(response) {
          let res = response.data;
          // eslint-disable-next-line
          if (res.status.code === "080000000000") {
            dispatch(setRequestNewPin(true));
            dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
            setTimeout(() => {
              dispatch(BasicAuth.onLogout());
            }, 2000);
            sessionStorage.removeItem('set_last_url');
          } else if (res.status.code === '050005050005') {
            dispatch(setRequestNewPin(false));
            sessionStorage.setItem('set_last_url', '/reset-new-pin');
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
            setTimeout(() => {
              dispatch(BasicAuth.onLogout());
            }, 2000);
          } else {
            dispatch(setRequestNewPin(false));
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error));
        });
    };
  },
  onSetNewGauth: index => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/resetgauth', {
          userindex: index.userindex,
          username: index.username,
          usertoken: index.usertoken,
          language: localStorage.getItem('LangApps'),
          signature: '83468b7dff3746fb5b226ec09c9935ae5d30bd12cc4588ad4cb1f232c8f960ee',
        })
        .then(function(response) {
          let res = response.data;
          // eslint-disable-next-line
          if (res.status.code === "050000000000") {
            dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));
            dispatch(setRequestNewGauth(true));
            localStorage.setItem('qrcode', res.result.qrcode);
            sessionStorage.removeItem('set_last_url');
          } else if (res.status.code === '050005050005') {
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
            sessionStorage.setItem('set_last_url', '/reset-new-gauth');
            dispatch(setRequestNewGauth(false));
            localStorage.setItem('qrcode', null);
          } else {
            dispatch(fetchError(Translation.getServiceLanguage(res.status.code)));
            dispatch(setRequestNewGauth(false));
            localStorage.setItem('qrcode', null);
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error));
        });
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
