import React, { useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Button, TextField } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
    borderRadius: 8,
  },
  input: {
    backgroundColor: 'transparent',
    width: 25,
    textAlign: 'center',
  },
  spacingContent: {
    marginTop: 50,
  },
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [pin1, setPin1] = useState();
  const [pin2, setPin2] = useState();
  const [pin3, setPin3] = useState();
  const [pin4, setPin4] = useState();
  const [pin5, setPin5] = useState();
  const [pin6, setPin6] = useState();
  const [pin, setPin] = useState([]);

  const handleDynamicInput = value => {
    let current = [...pin];
    let joined = current.concat(value);
    setPin(joined);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className={classes.paper}>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12} md={12} desktop={9} xl={9} align="center" style={{ marginTop: 20 }}>
              <h3>Input PIN</h3>
            </Grid>

            <Grid item xs={12} md={12} desktop={9} xl={9} align="center" style={{ marginTop: 20 }}>
              <Alert severity="warning">
                Session application expired. For security reason, please
                <br /> re-enter your PIN
              </Alert>
            </Grid>

            <Grid container style={{ marginTop: 80 }}>
              <Grid item xs={2} md={2} desktop={9} xl={9} align="center">
                <TextField
                  type={'text'}
                  defaultValue={pin[0]}
                  value={pin[0]}
                  className={classes.input}
                  autoFocus={pin.length === 0 ? true : false}
                  onChange={event => handleDynamicInput(event.target.value)}
                />
              </Grid>
              <Grid item xs={2} md={2} desktop={9} xl={9} align="center">
                <TextField
                  type={'text'}
                  defaultValue={pin[1]}
                  value={pin[1]}
                  className={classes.input}
                  autoFocus={pin.length === 1 ? true : false}
                  onChange={event => handleDynamicInput(event.target.value)}
                />
              </Grid>
              <Grid item xs={2} md={2} desktop={9} xl={9} align="center">
                <TextField
                  type={'text'}
                  defaultValue={pin[2]}
                  value={pin[2]}
                  className={classes.input}
                  autoFocus={pin.length === 2 ? true : false}
                  onChange={event => handleDynamicInput(event.target.value)}
                />
              </Grid>
              <Grid item xs={2} md={2} desktop={9} xl={9} align="center">
                <TextField
                  type={'text'}
                  defaultValue={pin[3]}
                  value={pin[3]}
                  className={classes.input}
                  autoFocus={pin.length === 3 ? true : false}
                  onChange={event => handleDynamicInput(event.target.value)}
                />
              </Grid>
              <Grid item xs={2} md={2} desktop={9} xl={9} align="center">
                <TextField
                  type={'text'}
                  defaultValue={pin[4]}
                  value={pin[4]}
                  className={classes.input}
                  autoFocus={pin.length === 4 ? true : false}
                  onChange={event => handleDynamicInput(event.target.value)}
                />
              </Grid>
              <Grid item xs={2} md={2} desktop={9} xl={9} align="center">
                <TextField
                  type={'text'}
                  defaultValue={pin[5]}
                  value={pin[5]}
                  className={classes.input}
                  autoFocus={pin.length === 5 ? true : false}
                  onChange={event => handleDynamicInput(event.target.value)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} desktop={9} xl={9} align="center" style={{ marginTop: 70 }}>
              <label style={{ color: 'gray' }}>Forgot PIN?</label>
              <span style={{ fontWeight: 'bold' }}>Contact administrator</span>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
