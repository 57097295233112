// import Typography from '@material-ui/core/Typography';
import { Box, FormControl, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../i18n/entries/Service';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../redux/actions/Processing';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { formatRupiah } from '../../../@jumbo/utils/commonHelper';
import { AccessTime } from '@material-ui/icons';
import CmtImage from '../../../@coremat/CmtImage';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const Wallet = () => {
  const classes = useStyles();
  const [label, setLabel] = useState();
  const [step, setStep] = useState('STEP1');
  const [tokentransaction, setTokenTransaction] = useState('');
  const [headeramount, setHeaderamount] = useState('');
  const [headermessage, setHeadermessage] = useState('');
  const [userSavedWalletList, setUserSavedWalletList] = useState([]);
  const [userWalletList, setUserWalletList] = useState([]);
  const [recipientTo, setRecipientTo] = useState({});
  const [recipientType, setRecipientType] = useState('');
  const [paymentmethodlist, setPaymentmethodlist] = useState([]);
  const [optionSave, setOptionSave] = useState(false);
  const [responseTransferRecipientType, setResponseTransferRecipientType] = useState('');
  const [timerDuedate, setTimerduedate] = useState('');
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const { is_processing } = useSelector(({ processing }) => processing);
  const { walletindex } = useParams();

  const handleChangeRecipientType = event => {
    setRecipientType(event.target.value);
  };

  const handleChangeRecipientTo = event => {
    setRecipientTo(event.target.value);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const onSubmitStep1 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += walletindex;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      temptrxtransferrwalletindex: walletindex,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/insertnew',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP2');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const onSubmitStep2 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;
    String += walletindex;
    String += recipientType;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      temptrxtransferwalletindex: walletindex,
      temptrxtransferrecipienttype: recipientType,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/recipienttype',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setResponseTransferRecipientType(res.temptrxtransfer[0].temptrxtransferrecipienttype);

          if (res.temptrxtransfer[0].temptrxtransferrecipienttype === 'OWN') {
            setUserWalletList(res.userwalletlist);
          }

          if (res.temptrxtransfer[0].temptrxtransferrecipienttype === 'OTHER') {
            setUserSavedWalletList(res.usersavedwalletlist);
          }

          setStep('STEP3');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const onSubmitStep3 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    if (responseTransferRecipientType === 'OWN') {
      String += 'WEBSITE';
      String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
      String += index.userindex;
      String += index.usertoken;
      String += tokentransaction;
      String += '';
      String += recipientTo['tablewalletlabel'];
      String += recipientTo['tablewalletaccount'];
      String += '';
      String += 0;
    }

    if (responseTransferRecipientType === 'OTHER') {
      String += 'WEBSITE';
      String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
      String += index.userindex;
      String += index.usertoken;
      String += tokentransaction;
      String += recipientTo['tablesavedwalletindex'];
      String += recipientTo['tablesavedwalletname'];
      String += recipientTo['tablesavedwalletaccount'];
      String += '';
      String += parseInt(optionSave);
    }

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {};

    if (responseTransferRecipientType === 'OWN') {
      parameters = {
        platform: 'WEBSITE',
        gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
        userindex: index.userindex,
        username: index.username,
        tokenlogin: index.usertoken,
        tokentrans: tokentransaction,
        temptrxtransferrecipientindex: '',
        temptrxtransferrecipientname: recipientTo['tablewalletlabel'],
        temptrxtransferrecipientaccount: recipientTo['tablewalletaccount'],
        temptrxtransferrecipientpublickey: '',
        temptrxtransferrecipientsave: 0,
        signature: signature.toString(),
      };
    }

    if (responseTransferRecipientType === 'OTHER') {
      parameters = {
        platform: 'WEBSITE',
        gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
        userindex: index.userindex,
        username: index.username,
        tokenlogin: index.usertoken,
        tokentrans: tokentransaction,
        temptrxtransferrecipientindex: recipientTo['tablesavedwalletindex'],
        temptrxtransferrecipientname: recipientTo['tablesavedwalletname'],
        temptrxtransferrecipientaccount: recipientTo['tablesavedwalletaccount'],
        temptrxtransferrecipientpublickey: '',
        temptrxtransferrecipientsave: parseInt(optionSave),
        signature: signature.toString(),
      };
    }

    console.log(parameters);

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/recipient',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setPaymentmethodlist(res.temptrxpaymentmethod);
          setStep('STEP4');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const onSubmitStep4 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;
    String += headeramount;
    String += headermessage;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      temptrxheaderamount: parseInt(headeramount),
      temptrxheadermessage: headermessage,
      signature: signature.toString(),
    };

    await axios
      .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/amount', parameters)
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);

          onConfirmationTransaction(
            res.temptrxtransfer[0].temptrxtransferrecipienttype,
            res.temptrxtransfer[0].temptrxtransferrecipientname,
            res.temptrxtransfer[0].temptrxtransferamount,
            res.temptrxtransfer[0].temptrxtransfermessage,
          );
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const fcmpushtest = (msg, type) => {
    let fcmKey =
      'AAAA19zUvt0:APA91bH82c-E_-3GTw5oFpmh37agMooj9e4GZvYJUxKrwfCdB9MOoguZKgYsnNWc49hQMWhseaqCtkmRu-QZBqS4ZwlR2zok_7Eufpm-4KsCFRpVB9vFUI8ofArTbp5jRSF-vgFw1EP6';
    let fcmToken = localStorage.getItem('fcmToken');
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `key=${fcmKey}`,
    };

    let params = {
      notification: {
        title: 'DWA',
        body: msg,
        sound: 'default',
        vibrate: 1,
      },
      to: fcmToken,
      priority: 'high',
      data: {
        typenotif: type,
      },
    };
    axios
      .post('https://fcm.googleapis.com/fcm/send', params, { headers: headers })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onConfirmationTransaction = async (typeTransfer, name, amount, message) => {
    dispatch(setProcessing(true));
    let storage = JSON.parse(localStorage.getItem('user'));
    Swal.fire({
      icon: 'warning',
      title: 'Security Confirmation',
      html:
        `This is your transfer details please check carefuly!<br/><br/>` +
        `Name: ${name}<br/>` +
        `Amount: ${amount}<br/>` +
        `Message: ${message}<br/>` +
        `Type Transfer: ${typeTransfer}<br/>`,
      text: `${storage.user2fatype} is required for this action`,
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
      },
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      denyButtonText: 'Cancel',
      showDenyButton: true,
      preConfirm: async pin => {
        let index = JSON.parse(localStorage.getItem('user'));

        let token = await handleGetTokenRecaptcha();
        var String = '';

        String += 'WEBSITE';
        String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
        String += index.userindex;
        String += index.usertoken;
        String += tokentransaction;
        String += storage.user2fatype;
        String += pin;

        let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

        let parameters = {
          platform: 'WEBSITE',
          gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
          userindex: index.userindex,
          username: index.username,
          tokenlogin: index.usertoken,
          tokentrans: tokentransaction,
          user2fatype: index.user2fatype,
          user2fapintoken: pin,
          signature: signature.toString(),
        };
        return axios
          .post(
            process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/store',
            parameters,
          )
          .then(function(response) {
            let res = response.data;
            console.log(res);
            if (res.status.code === '080000000000') {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: `Transfer success please wait confirmation!`,
              });
              setStep('STEP5');
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: Translation.getServiceLanguage(res.status.code),
              });
              throw new Error(res.status.errormessage);
            }
            dispatch(setProcessing(false));
            return res;
          })
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.isDenied) {
        Swal.fire({
          icon: 'info',
          title: 'Sorry!',
          text: 'Changes are not saved!',
        });
        handleBackStep5();
      }
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  const handleBackStep3 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/back/recipient',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP2');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleBackStep4 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/back/amount',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP3');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleBackStep5 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/back/store',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP4');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  useEffect(() => {
    fcmpushtest();
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        async function() {
          openSnackbar('Fetching data...', 60000);

          closeSnackbar();
        },
      );
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
    },
    [ dispatch ]
  );

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Box className={classes.root}>
          <Stepper activeStep={parseInt(step.substring(4, 5))} alternativeLabel>
            <Step key={'Transfer'}>
              <StepLabel>Transfer</StepLabel>
            </Step>
            <Step key={'Recipient Type'}>
              <StepLabel>Recipient Type</StepLabel>
            </Step>
            <Step key={'Recipients'}>
              <StepLabel>Recipients</StepLabel>
            </Step>
            <Step key={'Amount'}>
              <StepLabel>Amount</StepLabel>
            </Step>
            <Step key={'Done'}>
              <StepLabel>Done</StepLabel>
            </Step>
          </Stepper>
          <Box>
            <Box>
              {// eslint-disable-next-line
              step == "STEP1" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please click button below to Transfer.</Alert>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep1}>
                          {'Create'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              <br />

              {// eslint-disable-next-line
              step == "STEP2" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please, choose recipient type to transfer.</Alert>
                    </Grid>
                    <Grid container align="center">
                      <Grid item xs={12} md={12} desktop={9} xl={9}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <InputLabel id="demo-simple-select-outlined-label">Recipient Type</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={recipientType}
                            onChange={handleChangeRecipientType}
                            label="Recipient Type">
                            <MenuItem value="none">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={'OWN'}>Own</MenuItem>
                            <MenuItem value={'OTHER'}>Others</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep2}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == "STEP3" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      {responseTransferRecipientType === 'OWN' ? (
                        <Alert severity="success">
                          You have choose transfer {responseTransferRecipientType} please click next.
                        </Alert>
                      ) : (
                        <Alert severity="success">
                          You have choose transfer type {responseTransferRecipientType}. Please, choose recipient to
                          transfer.
                        </Alert>
                      )}
                    </Grid>

                    {responseTransferRecipientType === 'OWN' ? (
                      <Grid container align="center">
                        <Grid item xs={12} md={12} desktop={9} xl={9}>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Recipients</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={recipientTo}
                              onChange={handleChangeRecipientTo}
                              label="Recipient To">
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {userWalletList.length > 0 &&
                                userWalletList.map((x, i) => {
                                  return (
                                    <MenuItem key={i + 1} value={x}>
                                      {x.tablewalletlabel}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ) : null}

                    {responseTransferRecipientType === 'OTHER' ? (
                      <Grid container align="center">
                        <Grid item xs={9} md={9} desktop={9} xl={9}>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Recipients</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={recipientTo}
                              onChange={handleChangeRecipientTo}
                              label="Recipient To">
                              <MenuItem value="">
                                <em>New</em>
                              </MenuItem>
                              {userSavedWalletList.length > 0 &&
                                userSavedWalletList.map((x, i) => {
                                  return (
                                    <MenuItem key={i + 1} value={x}>
                                      {x.tablesavedwalletname}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} md={3} desktop={9} xl={9}>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Save Option</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={optionSave}
                              onChange={e => setOptionSave(e.target.value)}
                              label="Option Save">
                              <MenuItem value="">
                                <em>-- Select Option --</em>
                              </MenuItem>
                              <MenuItem value="1">
                                <em>Save</em>
                              </MenuItem>
                              <MenuItem value="0">
                                <em>Leave it</em>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ) : null}

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? null : (
                        <Button disabled={step !== 'STEP3'} onClick={handleBackStep3} className={classes.backButton}>
                          Back
                        </Button>
                      )}
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep3}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == "STEP4" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please, set amount and message to transfer.</Alert>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          type={'text'}
                          fullWidth
                          id="uncontrolled"
                          label={'Amount'}
                          defaultValue={headeramount}
                          onChange={event => setHeaderamount(event.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={6} desktop={9} xl={9} ml={3}>
                        <TextField
                          type={'text'}
                          fullWidth
                          id="uncontrolled"
                          label={'Message'}
                          defaultValue={headermessage}
                          onChange={event => setHeadermessage(event.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? null : (
                        <Button disabled={step !== 'STEP4'} onClick={handleBackStep4} className={classes.backButton}>
                          Back
                        </Button>
                      )}
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep4}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == "STEP5" ? (
                <div style={{ marginBottom: 10, maxWidth: '500px', margin: 'auto' }}>
                  <CmtCard className={classes.cardRoot}>
                    <CmtCardContent>
                      <GridContainer>
                        <Grid item xs={12} md={12} desktop={9} xl={9}>
                          <Alert severity="success">Transfer is on processing, we will inform u soon!</Alert>
                        </Grid>
                      </GridContainer>
                    </CmtCardContent>
                  </CmtCard>
                </div>
              ) : null}
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Wallet;
