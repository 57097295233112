// import Typography from '@material-ui/core/Typography';
import { Box, Select, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CmtCard from '../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../../i18n/entries/Service';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../../redux/actions/Processing';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const SavedWalletInactive = () => {
  const classes = useStyles();
  const [label, setLabel] = useState();
  const [tokentransaction, setTokenTransaction] = useState('');
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const { is_processing } = useSelector(({ processing }) => processing);
  const { walletindex } = useParams();

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const onInactiveSavedWallet = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += walletindex;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tablesavedwalletindex: walletindex,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/savedwallet/v01/inactive',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        async function() {
          openSnackbar('Fetching data...', 60000);
          closeSnackbar();
        },
      );
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
    },
    [ dispatch ]
  );

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Box className={classes.root}>
          <Box>
            <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
              <Grid container spacing={3} direction="column">
                <Grid item xs={12} md={12} desktop={9} xl={9}>
                  <Alert severity="warning">Please, confirm this button to inactive saved wallet.</Alert>
                </Grid>

                <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                  {is_processing ? (
                    <Button variant="contained" color="primary">
                      Processing...
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={onInactiveSavedWallet}>
                      {'Next'}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default SavedWalletInactive;
