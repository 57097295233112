// import Typography from '@material-ui/core/Typography';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../i18n/entries/Service';
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../redux/actions/Processing';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 300,
  },
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const columns = [
  { id: 'tabletrxheaderaccount', label: 'Account', minWidth: 150 },
  { id: 'tabletrxheaderamount', label: 'Amount', minWidth: 50 },
  { id: 'tabletrxheadercurrencycode', label: 'Currency', minWidth: 50 },
  {
    id: 'tabletrxheaderpaymentmethoddescription',
    label: 'Payment Method',
    minWidth: 50,
  },
  { id: 'tabletrxheadermessage', label: 'Message', minWidth: 50 },
  { id: 'tabletrxheaderstatuscode', label: 'Status', minWidth: 50 },
];

const Unpaid = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const [unpaidList, setUnpaidList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { is_processing } = useSelector(({ processing }) => processing);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  const _callUnpaidList = async token => {
    let index = JSON.parse(localStorage.getItem('user'));

    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      signature: signature.toString(),
    };

    await axios
      .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/unpaid/v01/list', parameters)
      .then(response => {
        let res = response.data;
        setUnpaidList(res.tableunpaidlist);
      })
      .catch(function(error) {
        alert(error);
      });
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        async function() {
          openSnackbar('Fetching data...', 60000);
          await _callUnpaidList(await handleGetTokenRecaptcha());
          closeSnackbar();
        },
      );
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
    },
    [ dispatch ]
  );

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Box className={classes.root}>
          <Grid item xs={12}>
            <Typography component="div" variant="h4">
              Last 10 Unpaid Transaction
            </Typography>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={({ minWidth: column.minWidth }, { fontWeight: 'bold' })}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {unpaidList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, indexrow) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={indexrow}>
                          {columns.map((column, index) => {
                            const value = row[column.id]; //row.tabletrxheaderaccount
                            return (
                              <TableCell key={index} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={unpaidList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Unpaid;
