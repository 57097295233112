// import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, Redirect } from 'react-router-dom';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../i18n/entries/Service';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../redux/actions/Processing';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { formatRupiah } from '../../../@jumbo/utils/commonHelper';
import { AccessTime } from '@material-ui/icons';
import CmtImage from '../../../@coremat/CmtImage';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DatePicker } from '@material-ui/pickers';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const Kyc = () => {
  const classes = useStyles();
  const [label, setLabel] = useState();
  const [step, setStep] = useState('STEP1');
  const [tokentransaction, setTokenTransaction] = useState('');

  const [headeramount, setHeaderamount] = useState('');
  const [headerAmountError, setHeaderAmountError] = useState('');
  const [headerAmountErrorisShow, setHeaderAmountisShow] = useState(false);

  const [selectedCurrency, setSelectedcurrency] = useState('');
  const [selectedCurrencyError, setSelectedCurrencyError] = useState('');
  const [selectedCurrencyErrorisShow, setSelectedCurrencyErrorisShow] = useState(false);

  const [selectedPaymentmethod, setSelectedpaymentmethod] = useState('');
  const [selectedPaymentmethodError, setSelectedpaymentmethodError] = useState('');
  const [selectedPaymentmethodErrorisShow, setSelectedpaymentmethodErrorisShow] = useState(false);

  const [headermessage, setHeadermessage] = useState('');
  const [currencyRate, setCurrencyrate] = useState([]);

  const [paymentmethodlist, setPaymentmethodlist] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [timerDuedate, setTimerduedate] = useState('');
  const [responsePGA, setResponsePGA] = useState([]);
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const { is_processing } = useSelector(({ processing }) => processing);
  const { walletindex } = useParams();

  const [selectedktp, setSelectedKTP] = useState(null);
  const [selectedphoto, setSelectedPhoto] = useState(null);

  const [inputid, setInputid] = useState('');
  const [inputiderror, setInputiderror] = useState('');
  const [inputiderrorisshow, setInputiderrorisshow] = useState(false);

  const [inputsubdistrict, setSubdistrict] = useState('');
  const [inputsubdistricterror, setInputsubdistricterror] = useState('');
  const [inputsubdistricterrorisshow, setInputsubdistricterrorisshow] = useState(false);

  const [inputdistrict, setDistrict] = useState('');
  const [inputdistricterror, setInputdistricterror] = useState('');
  const [inputdistricterrorisshow, setInputdistricterrorisshow] = useState(false);

  const [inputcity, setCity] = useState('');
  const [inputcityerror, setInputcityerror] = useState('');
  const [inputcityerrorisshow, setInputcityerrorisshow] = useState(false);

  const [inputprovince, setProvince] = useState('');
  const [inputprovinceerror, setInputprovinceerror] = useState('');
  const [inputprovinceerrorisshow, setInputprovinceerrorisshow] = useState('');

  const [inputaddress, setAddress] = useState('');
  const [inputaddresserror, setInputaddresserror] = useState('');
  const [inputaddresserrorisshow, setInputaddresserrorisshow] = useState('');

  const [inputpostalcode, setPostalcode] = useState('');
  const [inputpostalcodeerror, setInputpostalcodeerror] = useState('');
  const [inputpostalcodeerrorisshow, setInputpostalcodeerrorisshow] = useState('');

  const [inputgender, setGender] = useState('');
  const [inputgendererror, setInputgendererror] = useState('');
  const [inputgendererrorisshow, setInputgendererrorisshow] = useState('');

  const [inputbirthplace, setBirthplace] = useState('');
  const [inputbirthplaceerror, setInputbirthplaceerror] = useState('');
  const [inputbirthplaceerrorisshow, setInputbirthplaceerrorisshow] = useState('');

  const [inputbithdate, setBirthdate] = useState(new Date());
  const [inputbithdateerror, setInputbithdateerror] = useState('');
  const [inputbithdateerrorisshow, setInputbithdateerrorisshow] = useState('');

  const [errorKTP, setErrorKTP] = useState(false);
  const [errorSelfie, setErrorSelfie] = useState(false);
  const [navigateToHome, setNavigateToHome] = useState(false);

  const convertDatePayment = date => {
    var transactionDate = new Date(date);
    transactionDate.setDate(transactionDate.getDate() + 1);
    transactionDate.getTime();

    var interval = setInterval(function() {
      var now = new Date().getTime();

      var distance = transactionDate - now;

      // var days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString();
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
      var seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

      if (seconds.length < 2) {
        seconds = '0' + seconds;
        console.log('x');
      }

      setTimerduedate(hours + ':' + minutes + ':' + seconds);

      if (distance < 0) {
        clearInterval(interval);
        setTimerduedate('Expired');
      }
    }, 1000);
  };

  const clearState4 = (clearParams = []) => {
    // if(clearParams.includes("paymentmethod")){
    //   setSelectedpaymentmethod("");
    //   setSelectedpaymentmethodError("");
    //   setSelectedpaymentmethodErrorisShow(false);
    // }
    if (clearParams.includes('idnumber')) {
      setInputid('');
      setInputiderror('');
      setInputiderrorisshow(false);
    }

    if (clearParams.includes('subdistrict')) {
      setSubdistrict('');
      setInputsubdistricterror('');
      setInputsubdistricterrorisshow(false);
    }

    if (clearParams.includes('district')) {
      setDistrict('');
      setInputdistricterror('');
      setInputdistricterrorisshow(false);
    }

    if (clearParams.includes('city')) {
      setCity('');
      setInputcityerror('');
      setInputcityerrorisshow(false);
    }
    if (clearParams.includes('province')) {
      setProvince('');
      setInputprovinceerror('');
      setInputprovinceerrorisshow(false);
    }
    if (clearParams.includes('address')) {
      setAddress('');
      setInputaddresserror('');
      setInputaddresserrorisshow(false);
    }
    if (clearParams.includes('postal')) {
      setPostalcode('');
      setInputpostalcodeerror('');
      setInputpostalcodeerrorisshow(false);
    }
    if (clearParams.includes('gender')) {
      setGender('');
      setInputgendererror('');
      setInputgendererrorisshow(false);
    }
    if (clearParams.includes('birthplace')) {
      setBirthplace('');
      setInputbirthplaceerror('');
      setInputbirthplaceerrorisshow(false);
    }
    if (clearParams.includes('errorktp')) {
      setErrorKTP(false);
    }
    if (clearParams.includes('errorselfie')) {
      setErrorSelfie(false);
    }

    if (clearParams.length < 1) {
      setInputiderror('');
      setInputiderrorisshow(false);

      setInputsubdistricterror('');
      setInputsubdistricterrorisshow(false);

      setInputdistricterror('');
      setInputdistricterrorisshow(false);

      setInputcityerror('');
      setInputcityerrorisshow(false);

      setInputprovinceerror('');
      setInputprovinceerrorisshow(false);

      setInputaddresserror('');
      setInputaddresserrorisshow(false);

      setInputpostalcodeerror('');
      setInputpostalcodeerrorisshow(false);

      setInputgendererror('');
      setInputgendererrorisshow(false);

      setInputbithdateerror('');
      setInputbithdateerrorisshow(false);

      setInputbirthplaceerror('');
      setInputbirthplaceerrorisshow(false);

      setErrorSelfie(false);
      setErrorKTP(false);
    }
  };
  // const clearState = (clearParams = []) => {

  // };

  const onSubmitStep4 = async () => {
    dispatch(setProcessing(true));
    await requestSubmitStep4()
      .then(() => {
        setTimeout(() => {
          clearState4();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState4(arrClear);
        }, 2500);
      });
    dispatch(setProcessing(false));
  };

  const requestSubmitStep4 = () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      tokentrans: tokentransaction,
      tablepaymentmethodindex: selectedPaymentmethod,
    };

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('image_ktp', selectedktp);
      formData.append('image_foto', selectedphoto);
      formData.append('idnumber', inputid);
      formData.append('subdistrict', inputsubdistrict);
      formData.append('district', inputdistrict);
      formData.append('city', inputcity);
      formData.append('province', inputprovince);
      formData.append('address', inputaddress);
      formData.append('postalcode', inputpostalcode);
      formData.append('gender', inputgender);
      formData.append('birthplace', inputbirthplace);
      formData.append('birthdate', inputbithdate);

      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/kyc',
          formData,
          headers,
        )
        .then(response => {
          let res = response.data;
          // console.log(res);
          if (res.status.status === 1) {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Succesfuly uploaded identity, please wait a moment system will checking the data soon. ',
            });
            setNavigateToHome(true);
            resolve();
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888005016005')) {
              setInputiderror('please provide idnumber');
              setInputiderrorisshow(true);
              arrClear.push('idnumber');
            }
            // else {
            //   if (inputid.length < 17) {
            //     setInputiderror('idnumber is not valid');
            //     setInputiderrorisshow(true);
            //     arrClear.push('idnumber');
            //   }
            // }
            if (selectedktp === null) {
              setErrorKTP(true);
              arrClear.push('errorktp');
            }
            if (selectedphoto === null) {
              setErrorSelfie(true);
              arrClear.push('errorselfie');
            }

            if (arrError.find(item => item.code === '888005017005')) {
              setInputsubdistricterror('please provide subdistrict');
              setInputsubdistricterrorisshow(true);
              arrClear.push('subdistrict');
            }

            if (arrError.find(item => item.code === '888005018005')) {
              setInputdistricterror('please provide district');
              setInputdistricterrorisshow(true);
              arrClear.push('district');
            }

            if (arrError.find(item => item.code === '888005027005')) {
              setInputcityerror('please provide city');
              setInputcityerrorisshow(true);
              arrClear.push('city');
            }
            if (arrError.find(item => item.code === '888005019005')) {
              setInputprovinceerror('please provide province');
              setInputprovinceerrorisshow(true);
              arrClear.push('province');
            }

            if (arrError.find(item => item.code === '888005021005')) {
              setInputaddresserror('please provide address');
              setInputaddresserrorisshow(true);
              arrClear.push('address');
            }

            if (arrError.find(item => item.code === '888005022005')) {
              setInputpostalcodeerror('please provide postalcode');
              setInputpostalcodeerrorisshow(true);
              arrClear.push('postal');
            }
            if (arrError.find(item => item.code === '888005022030')) {
              setInputpostalcodeerror('postalcode is not valid');
              setInputpostalcodeerrorisshow(true);
              arrClear.push('postal');
            }

            if (arrError.find(item => item.code === '888005023005')) {
              setInputgendererror('gender must be one of [MALE, FEMALE]');
              setInputgendererrorisshow(true);
              arrClear.push('gender');
            }

            if (arrError.find(item => item.code === '888005024005')) {
              setInputbirthplaceerror('please provide birthplace');
              setInputbirthplaceerrorisshow(true);
              arrClear.push('birthplace');
            }

            if (arrError.find(item => item.code === '888999999404')) {
              Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'failed call pgc / pga',
              });
              arrClear.push('paymentmethod');
            }
            reject(arrClear);
          }
          dispatch(setProcessing(false));
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      openSnackbar('Fetching data...', 60000);

      closeSnackbar();
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
    },
    [ dispatch ]
  );
  if (navigateToHome) {
    return <Redirect to={'/'} />;
  }

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Box className={classes.root}>
          <div style={{ marginBottom: 10, maxWidth: '600px', margin: 'auto' }}>
            <Grid container spacing={3} direction="column">
              <Grid item xs={12} md={12} desktop={9} xl={9}>
                <h3>
                  <b>Verify your identity! Get access for all service</b>
                </h3>
                <br />
                <p style={{ fontSize: 11, color: 'grey', fontStyle: 'italic' }}>
                  Protect your identity and get access for all features thats you need
                </p>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <h3>Upload KTP</h3>

                  <input
                    type="file"
                    id="file-input"
                    name="imagektp"
                    onChange={event => {
                      setSelectedKTP(event.target.files[0]);
                    }}
                  />
                  {errorKTP ? (
                    <div style={{ paddingTop: 6 }}>
                      <h4 style={{ color: 'red' }}>This field is mandatory!</h4>
                    </div>
                  ) : null}

                  {selectedktp !== null ? (
                    <div>
                      <img
                        alt="not fount"
                        src={URL.createObjectURL(selectedktp)}
                        style={{ borderRadius: 8, width: 150, height: 150 }}
                      />
                    </div>
                  ) : (
                    <div style={{ marginTop: 12 }}>
                      <img src={'/images/id_photo.png'} style={{ width: 150, height: 150 }} alt={'logo'} />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <h3>Upload Photo</h3>
                  <input
                    type="file"
                    id="file-input"
                    name="imagephoto"
                    onChange={event => {
                      setSelectedPhoto(event.target.files[0]);
                    }}
                  />
                  {errorSelfie ? (
                    <div style={{ paddingTop: 6 }}>
                      <h4 style={{ color: 'red' }}>This field is mandatory!</h4>
                    </div>
                  ) : null}

                  {selectedphoto !== null ? (
                    <div>
                      <img
                        alt="not fount"
                        src={URL.createObjectURL(selectedphoto)}
                        style={{ borderRadius: 8, width: 150, height: 150 }}
                      />
                    </div>
                  ) : (
                    <div style={{ marginTop: 12 }}>
                      <img src={'/images/selfie_photo.png'} style={{ width: 150, height: 150 }} alt={'logo'} />
                    </div>
                  )}
                </Grid>
              </Grid>

              <br />
              <Grid container align="center" spacing={2}>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputiderrorisshow}
                    id={inputiderrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'ID Number'}
                    value={inputid}
                    onChange={event => setInputid(event.target.value)}
                    variant="outlined"
                    helperText={inputiderror}
                  />
                </Grid>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputsubdistricterrorisshow}
                    id={inputsubdistricterrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'Subdistrict'}
                    value={inputsubdistrict}
                    onChange={event => setSubdistrict(event.target.value)}
                    variant="outlined"
                    helperText={inputsubdistricterror}
                  />
                </Grid>
              </Grid>

              <br />
              <Grid container align="center" spacing={2}>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputdistricterrorisshow}
                    id={inputdistricterrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'District'}
                    value={inputdistrict}
                    onChange={event => setDistrict(event.target.value)}
                    variant="outlined"
                    helperText={inputdistricterror}
                  />
                </Grid>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputcityerrorisshow}
                    id={inputcityerrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'City'}
                    value={inputcity}
                    onChange={event => setCity(event.target.value)}
                    variant="outlined"
                    helperText={inputcityerror}
                  />
                </Grid>
              </Grid>

              <br />
              <Grid container align="center" spacing={2}>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputprovinceerrorisshow}
                    id={inputprovinceerrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'Province'}
                    value={inputprovince}
                    onChange={event => setProvince(event.target.value)}
                    variant="outlined"
                    helperText={inputprovinceerror}
                  />
                </Grid>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputaddresserrorisshow}
                    id={inputaddresserrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'Address'}
                    value={inputaddress}
                    onChange={event => setAddress(event.target.value)}
                    variant="outlined"
                    helperText={inputaddresserror}
                  />
                </Grid>
              </Grid>

              <br />
              <Grid container align="left" spacing={2}>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputpostalcodeerrorisshow}
                    id={inputpostalcodeerrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'Postal Code'}
                    value={inputpostalcode}
                    onChange={event => setPostalcode(event.target.value)}
                    variant="outlined"
                    helperText={inputpostalcodeerror}
                  />
                </Grid>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ color: inputgendererrorisshow ? 'red' : null }}>
                      Gender{' '}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender1"
                      value={inputgender}
                      defaultValue=""
                      onChange={event => setGender(event.target.value)}>
                      <FormControlLabel
                        value="MALE"
                        control={
                          <Radio
                            style={{
                              color: inputgendererrorisshow ? 'red' : null,
                            }}
                          />
                        }
                        label="Male"
                        labelPlacement="start"
                        style={{ color: inputgendererrorisshow ? 'red' : null }}
                      />
                      <FormControlLabel
                        value="FEMALE"
                        control={
                          <Radio
                            style={{
                              color: inputgendererrorisshow ? 'red' : null,
                            }}
                          />
                        }
                        label="Female"
                        labelPlacement="start"
                        style={{ color: inputgendererrorisshow ? 'red' : null }}
                      />
                    </RadioGroup>
                    <span
                      style={{
                        color: inputgendererrorisshow ? 'red' : null,
                        display: inputgendererrorisshow ? 'block' : 'none',
                        fontSize: 11,
                      }}>
                      {inputgendererror}
                    </span>
                  </FormControl>
                </Grid>
              </Grid>

              <br />
              <Grid container align="center" spacing={2}>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <TextField
                    error={inputbirthplaceerrorisshow}
                    id={inputbirthplaceerrorisshow ? 'outlined-error-helper-text' : ''}
                    type={'text'}
                    fullWidth
                    label={'Birthplace'}
                    value={inputbirthplace}
                    onChange={event => setBirthplace(event.target.value)}
                    variant="outlined"
                    helperText={inputbirthplaceerror}
                  />
                </Grid>
                <Grid item xs={6} md={6} desktop={9} xl={9}>
                  <KeyboardDatePicker
                    id="date-picker-dialog"
                    format="DD/MM/yyyy"
                    fullWidth
                    label="Birthdate"
                    value={inputbithdate}
                    onChange={event => setBirthdate(event)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                {is_processing ? (
                  <Button variant="contained" color="primary">
                    Processing...
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" onClick={onSubmitStep4}>
                    {'Upload'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Kyc;
