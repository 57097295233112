import { SET_PROCESSING } from '../../@jumbo/constants/ActionTypes';

export const setProcessing = status => {
  return dispatch => {
    dispatch({
      type: SET_PROCESSING,
      payload: status,
    });
  };
};
