// import Typography from '@material-ui/core/Typography';
import { Box, FormControl, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../i18n/entries/Service';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../redux/actions/Processing';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { formatRupiah } from '../../../@jumbo/utils/commonHelper';
import { AccessTime } from '@material-ui/icons';
import CmtImage from '../../../@coremat/CmtImage';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';
import { setAuthUser } from 'redux/actions/Auth';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const Transfer = () => {
  const classes = useStyles();
  const [label, setLabel] = useState();

  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');
  const [amountErrorisShow, setAmountErrorisShow] = useState(false);

  const [recipientTo, setRecipientTo] = useState('');
  const [recipientToError, setRecipientToError] = useState('');
  const [recipientToErrorisShow, setRecipientToErrorisShow] = useState(false);

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageErrorisShow, setMessageErrorisShow] = useState(false);

  const [step, setStep] = useState('STEP0');
  const [tokentransaction, setTokenTransaction] = useState('');
  const [headeramount, setHeaderamount] = useState('');
  const [headermessage, setHeadermessage] = useState('');

  const [tempTransferDetails, setTempTransferDetails] = useState([]);
  const [userSavedWalletList, setUserSavedWalletList] = useState([]);
  const [userWalletList, setUserWalletList] = useState([]);

  const [recipientType, setRecipientType] = useState('');
  const [paymentmethodlist, setPaymentmethodlist] = useState([]);
  const [optionSave, setOptionSave] = useState(false);
  const [responseTransferRecipientType, setResponseTransferRecipientType] = useState('');
  const [timerDuedate, setTimerduedate] = useState('');
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const { is_processing } = useSelector(({ processing }) => processing);
  const { walletindex } = useParams();

  const [alertType, setAlertType] = useState('info');
  const [alertMessage, setAlertMessage] = useState('Please, choose recipient type to transfer.');

  const handleChangeRecipientType = event => {
    setRecipientType(event.target.value);
  };

  const handleChangeRecipientTo = event => {
    setRecipientTo(event.target.value);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const clearState1 = (clearParams = []) => {
    if (clearParams.includes('amount')) {
      setAmount('');
      setAmountError('');
      setAmountErrorisShow(false);
    }

    if (clearParams.includes('recipient')) {
      setRecipientTo('');
      setRecipientToError('');
      setRecipientToErrorisShow(false);
    }
  };

  const onSubmitStep1 = async () => {
    dispatch(setProcessing(true));
    await requestSubmitStep1()
      .then((token) => {
        onSubmitStep2(token);
        setTimeout(() => {
          clearState1();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState1(arrClear);
        }, 2500);
      });
    // dispatch(setProcessing(false));
  };

  const requestSubmitStep1 = () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      amount: amount,
      recepient: recipientTo,
      message: message,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/insertnew',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            setTokenTransaction(res.status.tokentrans);
            setTempTransferDetails(res.data);
            setStep('STEP2');
            resolve(res.status.tokentrans);
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            console.log(res);

            if (arrError.find(item => item.code === '888050040005')) {
              setAmountError('please provide a correct transfer amount');
              setAmountErrorisShow(true);
              arrClear.push('amount');
            }

            if (arrError.find(item => item.code === '888035020005')) {
              setRecipientToError('please provide recipient address');
              setRecipientToErrorisShow(true);
              arrClear.push('recipient');
            }

            if (arrError.find(item => item.code === '888999999924')) {
              setRecipientToError('Not enough balance');
              setRecipientToErrorisShow(true);
              arrClear.push('recipient');
            }

            if (arrError.find(item => item.code === '888999999940')) {
              Swal.fire({
                icon: 'error',
                title: 'Sorry!',
                text: 'Unauthorize',
              });
              dispatch(setAuthUser(null));
            }

            if (arrError.find(item => item.code === '888999999455')) {
              Swal.fire({
                icon: 'warning',
                title: 'Unable to Transfer!',
                text: 'Sorry, you are not able to make transfer for 1 x 24 hours after resetting your Password',
                confirmButtonText: 'Go to Dashboard',
              }).then(result => {
                if (result.isConfirmed) {
                  window.location.href = '/dashboard';
                }
              });
            }

            reject(arrClear);
          }

          dispatch(setProcessing(false));
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  const onSubmitStep2 = async (token) => {
    // dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      tokentrans: token,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/store',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            // setTokenTransaction(res.status.tokentrans);
            // setTempTransferDetails(res.data);
            // setStep('STEP3');
            // window.location = '/transfer'
            resolve();
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888035020005')) {
              Swal.fire({
                icon: 'info',
                title: 'Information!',
                text: 'tokentrans is not allowed to be empty',
              });
            }

            if (arrError.find(item => item.code === '888999999940')) {
              Swal.fire({
                icon: 'error',
                title: 'Sorry!',
                text: 'Unauthorize',
              });
              dispatch(setAuthUser(null));
            }

            reject(arrClear);
          }

          // dispatch(setProcessing(false));
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  const onSubmitStep3 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      codeverification: '',
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/confirm',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Your transaction has been successfully transferred',
            });

            setTimeout(() => {
              window.location = '/transfer';
            }, 2500);
            resolve();
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888035020005')) {
              Swal.fire({
                icon: 'info',
                title: 'Information!',
                text: 'tokentrans is not allowed to be empty',
              });
            }

            if (arrError.find(item => item.code === '888999999940')) {
              Swal.fire({
                icon: 'error',
                title: 'Sorry!',
                text: 'Unauthorize',
              });
              dispatch(setAuthUser(null));
            }

            reject(arrClear);
          }

          dispatch(setProcessing(false));
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  const onSubmitStep4 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;
    String += headeramount;
    String += headermessage;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      temptrxheaderamount: parseInt(headeramount),
      temptrxheadermessage: headermessage,
      signature: signature.toString(),
    };

    await axios
      .post(process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/amount', parameters)
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);

          onConfirmationTransaction(
            res.temptrxtransfer[0].temptrxtransferrecipienttype,
            res.temptrxtransfer[0].temptrxtransferrecipientname,
            res.temptrxtransfer[0].temptrxtransferamount,
            res.temptrxtransfer[0].temptrxtransfermessage,
          );
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const fcmpushtest = (msg, type) => {
    let fcmKey =
      'AAAA19zUvt0:APA91bH82c-E_-3GTw5oFpmh37agMooj9e4GZvYJUxKrwfCdB9MOoguZKgYsnNWc49hQMWhseaqCtkmRu-QZBqS4ZwlR2zok_7Eufpm-4KsCFRpVB9vFUI8ofArTbp5jRSF-vgFw1EP6';
    let fcmToken = localStorage.getItem('fcmToken');
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `key=${fcmKey}`,
    };

    let params = {
      notification: {
        title: 'DWA',
        body: msg,
        sound: 'default',
        vibrate: 1,
      },
      to: fcmToken,
      priority: 'high',
      data: {
        typenotif: type,
      },
    };
    axios
      .post('https://fcm.googleapis.com/fcm/send', params, { headers: headers })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onConfirmationTransaction = async (typeTransfer, name, amount, message) => {
    dispatch(setProcessing(true));
    let storage = JSON.parse(localStorage.getItem('user'));
    Swal.fire({
      icon: 'warning',
      title: 'Security Confirmation',
      html:
        `This is your transfer details please check carefuly!<br/><br/>` +
        `Name: ${name}<br/>` +
        `Amount: ${amount}<br/>` +
        `Message: ${message}<br/>` +
        `Type Transfer: ${typeTransfer}<br/>`,
      text: `${storage.user2fatype} is required for this action`,
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
      },
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      denyButtonText: 'Cancel',
      showDenyButton: true,
      preConfirm: async pin => {
        let index = JSON.parse(localStorage.getItem('user'));

        let token = await handleGetTokenRecaptcha();
        var String = '';

        String += 'WEBSITE';
        String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
        String += index.userindex;
        String += index.usertoken;
        String += tokentransaction;
        String += storage.user2fatype;
        String += pin;

        let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

        let parameters = {
          platform: 'WEBSITE',
          gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
          userindex: index.userindex,
          username: index.username,
          tokenlogin: index.usertoken,
          tokentrans: tokentransaction,
          user2fatype: index.user2fatype,
          user2fapintoken: pin,
          signature: signature.toString(),
        };
        return axios
          .post(
            process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/store',
            parameters,
          )
          .then(function (response) {
            let res = response.data;
            console.log(res);
            if (res.status.code === '080000000000') {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: `Transfer success please wait confirmation!`,
              });
              setStep('STEP5');
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: Translation.getServiceLanguage(res.status.code),
              });
              throw new Error(res.status.errormessage);
            }
            dispatch(setProcessing(false));
            return res;
          })
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.isDenied) {
        Swal.fire({
          icon: 'info',
          title: 'Sorry!',
          text: 'Changes are not saved!',
        });
        handleBackStep5();
      }
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  const handleBackStep3 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/back/recipient',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP2');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };
  const formatAddress = (event) =>{
    const isValidInput = /^[0-9]+$/.test(event);
    if(isValidInput){
      setRecipientTo(event)
    }
  }
  const formatMessage = (event) =>{
    console.log('EVE', event.length)
    if(event.length < 33){
      setMessage(event)
    }

  }
  const formatNumber = (event) => {

    const regexCheck = (value) => {

      let lastIndexOfValue = value.substr(value.length - 1)
      const isValidInput = /^[0-9]*(\.[0-9]*)?$/.test(value);
      let isContainComma;
      let isOneCommaAllowed = (value.match(/[.]/g) || []).length === 1;

      if (value) {
        isContainComma = value.includes(".");
      }

      console.log("isContainComma", isContainComma);
      if (
        value !== "."
        && value !== ","
        && lastIndexOfValue !== ","
        && isValidInput //check if only number and dot is allowed
      ) {
        if (isContainComma) {
            if (value.length > 2) { //Check if got double comma
              if (isOneCommaAllowed) {
                return true;
              }
              else {
                return false;
              }
            }
        }
        return true;
      } else {
        return false;
      }
    };
    const reg = regexCheck(event)

    if (reg) {
      setAmount(event)
    }
  }
  const handleBackStep4 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/back/amount',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP3');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleBackStep5 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/transfer/v01/back/store',
        parameters,
      )
      .then(response => {
        let res = response.data;
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP4');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };
  // const callProfileData = async token => {
  //   let index = JSON.parse(localStorage.getItem('user'));

  //   let headers = {
  //     headers: {
  //       platform: 'WEBSITE',
  //       gtoken: 'PASSS',
  //       userindex: index.userindex,
  //       tokenlogin: index.usertoken,
  //       signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
  //     },
  //   };

  //   let parameters = {};

  //   await axios
  //     .post(
  //       process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/profile',
  //       parameters,
  //       headers,
  //     )
  //     .then(response => {
  //       let res = response.data;
  //       console.log('DATA_PROFILE', res);
  //       if (res.status.status === 1) {
  //         // setProfileData(res.data);
  //         const KYCStatus = res.data.tableuser_kyc_status;
  //         console.log('KYCS', KYCStatus);
  //         if (KYCStatus < 2) {
  //           setAlertType('warning');
  //           if (KYCStatus === 1) {
  //             setAlertMessage('You cannot access this feature, your KYC status is still on review');
  //           }
  //           if (KYCStatus === 0) {
  //             console.log('isZero');
  //             setAlertMessage('Your account is not verified, please verify your accout from dashboard page');
  //           }
  //           if (KYCStatus === -1) {
  //             setAlertMessage('Your KYC was rejected, please verify your account from dashboard page');
  //           }
  //         }
  //       } else {
  //         let arrError = [];

  //         if (res.status.message.length > 0) {
  //           for (var i = 0; i < res.status.message.length; i++) {
  //             arrError.push(res.status.message[i]);
  //           }
  //         }

  //         if (arrError.find(item => item.code === '888999999940')) {
  //           setTimeout(() => {
  //             dispatch(setAuthUser(null));
  //           }, 2500);
  //         }
  //       }
  //     })
  //     .catch(function(error) {
  //       alert(error);
  //     });

  //   // setIsloaded(false);
  // };
  // useEffect(() => {
  //   callProfileData();
  // }, []);
  useEffect(() => {
    // fcmpushtest();
    const loadScriptRecaptcha = async () => {
      //   await loadScriptByURL(
      //     'recaptcha-key',
      //     `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //     async function() {
      openSnackbar('Fetching data...', 60000);

      closeSnackbar();
      //     },
      //   );
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent
        style={{
          background:
            step !== 'STEP0'
              ? step !== 'STEP2'
                ? 'url(' + process.env.PUBLIC_URL + '/images/avatar1.png' + ') bottom no-repeat'
                : ''
              : '',
        }}>
        <Box className={classes.root}>
          <Stepper activeStep={parseInt(step.substring(4, 5))} alternativeLabel>
            <Step key={'Introduction'}>
              <StepLabel>Introduction</StepLabel>
            </Step>
            <Step key={'Transfer'}>
              <StepLabel>Transfer</StepLabel>
            </Step>
            <Step key={'Store'}>
              <StepLabel>Store</StepLabel>
            </Step>
            {/* <Step key={'Confirm'}>
              <StepLabel>Confirm</StepLabel>
            </Step> */}
          </Stepper>
          <Box>
            <Box>
              {// eslint-disable-next-line
                step == 'STEP0' ? (
                  <div style={{ marginBottom: 10, margin: 'auto' }}>
                    <Grid container spacing={3} direction="column">
                      {/* <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity={alertType}>{alertMessage}</Alert>
                    </Grid> */}
                      {/* <Grid container align="center" spacing={2}>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          disabled={alertType === 'warning' ? true : false}
                          error={amountErrorisShow}
                          id={amountErrorisShow ? 'outlined-error-helper-text' : ''}
                          type={'text'}
                          fullWidth
                          label={'Amount'}
                          value={amount}
                          onChange={event => setAmount(event.target.value)}
                          variant="outlined"
                          helperText={amountError}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          disabled={alertType === 'warning' ? true : false}
                          error={recipientToErrorisShow}
                          id={recipientToErrorisShow ? 'outlined-error-helper-text' : ''}
                          type={'text'}
                          fullWidth
                          label={'Recipient Address'}
                          value={recipientTo}
                          onChange={event => setRecipientTo(event.target.value)}
                          variant="outlined"
                          helperText={recipientToError}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container align="center" spacing={2}>
                      <Grid item xs={12} md={12} desktop={9} xl={9}>
                        <TextField
                          disabled={alertType === 'warning' ? true : false}
                          error={messageErrorisShow}
                          id={messageErrorisShow ? 'outlined-error-helper-text' : ''}
                          type={'text'}
                          fullWidth
                          label={'Message'}
                          value={message}
                          onChange={event => setMessage(event.target.value)}
                          variant="outlined"
                          helperText={messageError}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color={alertType !== 'warning' ? 'primary' : 'default'}
                          onClick={alertType !== 'warning' ? onSubmitStep1 : null}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid> */}
                      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '1090px', margin: '0 auto', borderTop: '5px solid', borderColor: '#E8F4FD' }}>
                          <div
                            style={{ marginTop: '59px', display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                              <img src={process.env.PUBLIC_URL + '/images/frame_26082681.png'} />
                            </div>
                            <div style={{ flex: 1 }}>
                              <h2 style={{ fontSize: '28px' }}>Transfer</h2>
                              <p style={{ color: 'blue', fontSize: '14px', paddingTop: 8, fontWeight: 600 }}>
                                Using Anoa Wallet
                              </p>
                              <p style={{ paddingTop: 16, color: '#707375', paddingBottom: 8 }}>
                                Secure transfer between two wallets by completing the form. Then do the valid transaction
                                authentication steps. Make sure the total amount and the intended wallet address are correct.
                              </p>

                              <a
                                href="https://anoatoken.com/httransfer"
                                target="_blank"
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}
                                rel="noreferrer">
                                <p style={{ color: 'blue' }}>read transfer policies</p>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M14.1667 6.66699L17.5 10.0003M17.5 10.0003L14.1667 13.3337M17.5 10.0003L2.5 10.0003"
                                    stroke="#4C4DDC"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </a>

                              <div style={{ marginTop: 16, display: 'flex', flexDirection: 'column', gap: 5 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                      fill="#4C4DDC"
                                    />
                                  </svg>
                                  <p style={{ color: '#3D3F40' }}>Peer-to-Peer Transactions</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                      fill="#4C4DDC"
                                    />
                                  </svg>
                                  <p style={{ color: '#3D3F40' }}>Fast Transactions</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                      fill="#4C4DDC"
                                    />
                                  </svg>
                                  <p style={{ color: '#3D3F40' }}>Easy Accessibility</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                      fill="#4C4DDC"
                                    />
                                  </svg>
                                  <p style={{ color: '#3D3F40' }}>Lower Transaction Fees</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                      fill="#4C4DDC"
                                    />
                                  </svg>
                                  <p style={{ color: '#3D3F40' }}>Privacy Guarantee</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                      fill="#4C4DDC"
                                    />
                                  </svg>
                                  <p style={{ color: '#3D3F40' }}>Security and Transparency</p>
                                </div>
                              </div>

                              {is_processing ? (
                                <button
                                  style={{
                                    marginTop: 28,
                                    width: '100%',
                                    height: '48px',
                                    borderRadius: '8px',
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: 16,
                                  }}>
                                  Processing...
                                </button>
                              ) : (
                                <button
                                  style={{
                                    marginTop: 28,
                                    width: '100%',
                                    height: '48px',
                                    borderRadius: '8px',
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: 16,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setStep('STEP1')}>
                                  Create
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </div>
                ) : null}

              {// eslint-disable-next-line
                step == 'STEP1' ? (
                  <div style={{ marginBottom: 10, margin: 'auto' }}>
                    <Grid container spacing={3} direction="column">
                      {/* <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity={alertType}>{alertMessage}</Alert>
                    </Grid> */}
                      {/* <Grid container align="center" spacing={2}>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          disabled={alertType === 'warning' ? true : false}
                          error={amountErrorisShow}
                          id={amountErrorisShow ? 'outlined-error-helper-text' : ''}
                          type={'text'}
                          fullWidth
                          label={'Amount'}
                          value={amount}
                          onChange={event => setAmount(event.target.value)}
                          variant="outlined"
                          helperText={amountError}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          disabled={alertType === 'warning' ? true : false}
                          error={recipientToErrorisShow}
                          id={recipientToErrorisShow ? 'outlined-error-helper-text' : ''}
                          type={'text'}
                          fullWidth
                          label={'Recipient Address'}
                          value={recipientTo}
                          onChange={event => setRecipientTo(event.target.value)}
                          variant="outlined"
                          helperText={recipientToError}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid container align="center" spacing={2}>
                      <Grid item xs={12} md={12} desktop={9} xl={9}>
                        <TextField
                          disabled={alertType === 'warning' ? true : false}
                          error={messageErrorisShow}
                          id={messageErrorisShow ? 'outlined-error-helper-text' : ''}
                          type={'text'}
                          fullWidth
                          label={'Message'}
                          value={message}
                          onChange={event => setMessage(event.target.value)}
                          variant="outlined"
                          helperText={messageError}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color={alertType !== 'warning' ? 'primary' : 'default'}
                          onClick={alertType !== 'warning' ? onSubmitStep1 : null}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid> */}
                      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '1090px', margin: '0 auto' }}>
                          <div style={{ marginTop: '59px', display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <TextField
                              disabled={alertType === 'warning' ? true : false}
                              error={amountErrorisShow}
                              id={amountErrorisShow ? 'outlined-error-helper-text' : ''}
                              type={'text'}
                              fullWidth
                              label={'Amount'}
                              value={amount}
                              onChange={event => formatNumber(event.target.value)}
                              variant="outlined"
                              helperText={amountError}
                            />
                            <TextField
                              disabled={alertType === 'warning' ? true : false}
                              error={recipientToErrorisShow}
                              id={recipientToErrorisShow ? 'outlined-error-helper-text' : ''}
                              type={'text'}
                              fullWidth
                              label={'Recipient Address'}
                              value={recipientTo}
                              onChange={event => formatAddress(event.target.value)}
                              variant="outlined"
                              helperText={recipientToError}
                            />
                          </div>
                          <div style={{ marginTop: 5, marginBottom: 5 }}>
                            <TextField
                              disabled={alertType === 'warning' ? true : false}
                              error={messageErrorisShow}
                              id={messageErrorisShow ? 'outlined-error-helper-text' : ''}
                              type={'text'}
                              fullWidth
                              label={'Message'}
                              value={message}
                              onChange={event => formatMessage(event.target.value)}
                              variant="outlined"
                              helperText={messageError}
                            />
                          </div>
                          <Grid align="right">
                            {is_processing ? (
                              <Button variant="contained" color="primary">
                                Processing...
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color={alertType !== 'warning' ? 'primary' : 'default'}
                                onClick={alertType !== 'warning' ? onSubmitStep1 : null}>
                                {'Next'}
                              </Button>
                            )}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </div>
                ) : null}

              <br />

              {// eslint-disable-next-line
                step == 'STEP2' ? (
                  <div style={{ marginBottom: 10, maxWidth: '500px', margin: 'auto' }}>
                    <CmtCard className={classes.cardRoot}>
                      <CmtCardContent>
                        <GridContainer>
                          <Grid container justifyContent="center" xs={12} sm={12}>
                            <AccessTime style={{ width: 75, height: 75, color: 'orange' }} />
                          </Grid>
                          <Grid container justifyContent="center" xs={12} sm={12}>
                            <h3 style={{ fontWeight: 'bold', color: 'orange' }}>Transaction On Process</h3>
                          </Grid>
                          <Grid container justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                            <h3 style={{ fontWeight: 'bold', fontSize: 20 }}>
                              {/* {tempTransferDetails.amount} - {tempTransferDetails.currency} */}
                              {tempTransferDetails.amount}
                            </h3>
                          </Grid>

                          <Grid item justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                            <CmtCard className={classes.cardRoot}>
                              <CmtCardContent>
                                <GridContainer>
                                  <Grid item container justifyContent="center" xs={12} sm={12}>
                                    <CmtImage src={'/images/dwa_logo.png'} style={{ height: 50 }} />
                                  </Grid>

                                  <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                    <label style={{ color: 'grey' }}>Date </label>
                                  </Grid>
                                  <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                    <label style={{ fontWeight: 'bolder' }}>{new Date().toLocaleString()}</label>
                                  </Grid>

                                  <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                    <label style={{ color: 'grey' }}>Transaction Code</label>
                                  </Grid>
                                  <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                    <label style={{ fontWeight: 'bolder' }}>{tempTransferDetails.tempdepositindex}</label>
                                  </Grid>

                                  {/* <Grid container justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                                  <Alert severity="warning" style={{ cursor: 'pointer', width: '100%' }}>
                                    <strong>
                                      <h5>Please pay this transaction before {timerDuedate}.</h5>
                                    </strong>
                                  </Alert>
                                </Grid> */}
                                </GridContainer>
                              </CmtCardContent>
                            </CmtCard>
                          </Grid>

                          <Grid item justifyContent="center" xs={12} sm={12}>
                            <GridContainer>
                              <Grid container justifyContent="flex-start" xs={12} sm={12} style={{ marginBottom: 20 }}>
                                <label style={{ fontWeight: 'bolder' }}>Transaction Details</label>
                              </Grid>
                              <Grid container justifyContent="flex-start" xs={12} sm={12} style={{ marginBottom: 20 }}>
                                {/* <img
                                src={payment['paymentMethod'] ? payment['paymentMethod'].icon_url : null}
                                style={{ width: 150, height: 60 }}
                                alt={'alt_paymentMethod'}
                              /> */}
                              </Grid>

                              {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Currency</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {tempTransferDetails.tempdepositpaymentmethodcurrencycode}
                              </label>
                            </Grid> */}

                              {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Platform</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {tempTransferDetails.tempdepositpaymentmethodcode}
                              </label>
                            </Grid> */}
                              {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Rate</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>{tempTransferDetails.tempdepositraterate}</label>
                            </Grid> */}
                              <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                <label style={{ color: 'grey' }}>Amount</label>
                              </Grid>
                              <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                <label style={{ fontWeight: 'bolder' }}>{tempTransferDetails.amount}</label>
                              </Grid>

                              <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                <label style={{ color: 'grey' }}>Fee</label>
                              </Grid>
                              <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                <label style={{ fontWeight: 'bolder' }}>{tempTransferDetails.fee}</label>
                              </Grid>
                              <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                <label style={{ color: 'grey' }}>Unique Code</label>
                              </Grid>
                              <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                <label style={{ fontWeight: 'bolder' }}>-</label>
                              </Grid>

                              <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                <label style={{ color: 'grey' }}>Total</label>
                              </Grid>
                              <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                <label style={{ fontWeight: 'bolder' }}>{tempTransferDetails.total}</label>
                              </Grid>

                              <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                <label style={{ color: 'grey' }}>Recipient</label>
                              </Grid>
                              <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                <label style={{ fontWeight: 'bolder' }}>{tempTransferDetails.recipent}</label>
                              </Grid>
                            </GridContainer>
                          </Grid>
                          <Grid item justifyContent="center" xs={12} sm={12} >
                            <div style={{ marginBottom: 15 }}>
                              <Alert severity="info">
                              To complete this transaction, kindly check your email for a confirmation message and follow the provided instructions.
                              </Alert>
                            </div>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ width: '100%', marginBottom: 20 }}
                                onClick={() => (window.location = '/transfer')}>
                                {'Back'}
                              </Button>
                            {/* {is_processing ? (
                              <Button variant="contained" color="primary"
                                size="large"
                                style={{ width: '100%', marginBottom: 20 }}>
                                Processing...
                              </Button>
                            ) :
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{ width: '100%', marginBottom: 20 }}
                                onClick={onSubmitStep2}>
                                {'CONFIRM'}
                              </Button>
                            } */}
                          </Grid>
                          {/* <Grid item xs={2} sm={2}>
                            <img
                              src={'https://kapilerindonesia.com/asset/image/news/customer-service.png'}
                              style={{ width: 40, height: 40, paddingBlock: 0 }}
                              alt={'alt_customerservice'}
                            />
                          </Grid>
                          <Grid item xs={10} sm={10}>
                            <label style={{ paddingBlock: 0 }}>Please contact ANOA Customer service if you have problem with payments.</label>
                          </Grid> */}
                        </GridContainer>
                      </CmtCardContent>
                    </CmtCard>
                  </div>
                ) : null}

              {// eslint-disable-next-line
                step == 'STEP3' ? (
                  <div style={{ marginBottom: 10, margin: 'auto' }}>
                    <Grid container spacing={3} direction="column">
                      {/* <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">
                        Please click button below to if you finish transaction and We will send you an e-mail to complete
                        transfer transaction.
                      </Alert>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={() => (window.location = '/transfer')}>
                          {'Confirm'}
                        </Button>
                      )}
                    </Grid> */}
                      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '1090px', margin: '0 auto', borderTop: '5px solid', borderColor: '#E8F4FD' }}>
                          <div style={{ marginBottom: 5 }}>
                            <Alert severity="info">
                              Please click button below to if you finish transaction and We will send you an e-mail to complete
                              transfer transaction.
                            </Alert>
                          </div>
                          <Grid align="right">
                            {is_processing ? (
                              <Button variant="contained" color="primary">
                                Processing...
                              </Button>
                            ) : (
                              <Button variant="contained" color="primary" onClick={() => (window.location = '/transfer')}>
                                {'Confirm'}
                              </Button>
                            )}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </div>
                ) : null}
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Transfer;
