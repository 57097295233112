// import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../i18n/entries/Service';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../redux/actions/Processing';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { formatRupiah } from '../../../@jumbo/utils/commonHelper';
import { AccessTime } from '@material-ui/icons';
import CmtImage from '../../../@coremat/CmtImage';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const TopUp = () => {
  const classes = useStyles();
  const [label, setLabel] = useState();
  const [step, setStep] = useState('STEP1');
  const [tokentransaction, setTokenTransaction] = useState('');

  const [headeramount, setHeaderamount] = useState('');
  const [headerAmountError, setHeaderAmountError] = useState('');
  const [headerAmountErrorisShow, setHeaderAmountisShow] = useState(false);

  const [selectedCurrency, setSelectedcurrency] = useState('');
  const [selectedCurrencyError, setSelectedCurrencyError] = useState('');
  const [selectedCurrencyErrorisShow, setSelectedCurrencyErrorisShow] = useState(false);

  const [selectedPaymentmethod, setSelectedpaymentmethod] = useState('');
  const [selectedPaymentmethodError, setSelectedpaymentmethodError] = useState('');
  const [selectedPaymentmethodErrorisShow, setSelectedpaymentmethodErrorisShow] = useState(false);

  const [headermessage, setHeadermessage] = useState('');
  const [currencyRate, setCurrencyrate] = useState([]);

  const [paymentmethodlist, setPaymentmethodlist] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [timerDuedate, setTimerduedate] = useState('');
  const [responsePGA, setResponsePGA] = useState([]);
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const { is_processing } = useSelector(({ processing }) => processing);
  const { walletindex } = useParams();

  const timer = useRef(null);

  const convertDatePayment = date => {
    var transactionDate = new Date(date);
    transactionDate.setDate(transactionDate.getDate() + 1);
    transactionDate.getTime();

    timer.current = setInterval(function() {
      var now = new Date().getTime();
      var distance = transactionDate - now;

      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
      var seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

      if (seconds.length < 2) {
        seconds = '0' + seconds;
      }

      console.log('setTimerduedate', hours + ':' + minutes + ':' + seconds);
      setTimerduedate(hours + ':' + minutes + ':' + seconds);
    }, 1000);
  };
  useEffect(() => {
    if (timerDuedate !== '00:00:00') {
      return;
    } else {
      setTimerduedate('Expired');
      console.log('IS ZERO!');
      clearInterval(timer.current);
    }
  }, [timerDuedate, timer.current]);
  const handleChange = event => {
    setSelectedcurrency(event.target.value);
  };

  const handleChangePaymentmethod = event => {
    setSelectedpaymentmethod(event.target.value);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const onSubmitStep1 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {};

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v02/insertnew',
        parameters,
        headers,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.status === 1) {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP2');
        } else {
          if (res.status.message[0].code === '888999999940') {
            Swal.fire({
              icon: 'info',
              title: 'Information!',
              text: 'unauthorize',
            });
          }

          if (res.status.message[0].code === '888999999444') {
            Swal.fire({
              icon: 'info',
              title: 'Information!',
              text: 'Please finishing your previous topup transactition payment',
            });
          }
          if (res.status.message[0].code === '888999999464') {
            Swal.fire({
              icon: 'info',
              title: 'Information!',
              text: 'you have canceled topup transaction three times, please wait 1 hour again',
            });
          }
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const clearState2 = (clearParams = []) => {
    if (clearParams.includes('amount')) {
      setHeaderamount('');
      setHeaderAmountError('');
      setHeadermessage('');
      setHeaderAmountisShow(false);
    }
  };

  const onSubmitStep2 = async () => {
    console.log('triggerstep2');
    dispatch(setProcessing(true));
    await requestSubmitStep2()
      .then(() => {
        setTimeout(() => {
          clearState2();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState2(arrClear);
        }, 2500);
      });
    dispatch(setProcessing(false));
  };

  const requestSubmitStep2 = () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      tokentrans: tokentransaction,
      tabletrxamount: headeramount,
      tabletrxmessage: headermessage,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v02/storeamount',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log('response_step2', res);
          if (res.status.status === 1) {
            setTokenTransaction(res.status.tokentrans);
            setCurrencyrate(res.support);
            setStep('STEP3');
            resolve();
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888050040005')) {
              setHeaderAmountError('please provide deposit amount');
              setHeaderAmountisShow(true);
              arrClear.push('amount');
            }

            reject(arrClear);
          }
          dispatch(setProcessing(false));
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  const clearState3 = (clearParams = []) => {
    if (clearParams.includes('currency')) {
      setSelectedcurrency('');
      setSelectedCurrencyError('');
      setSelectedCurrencyErrorisShow(false);
    }
  };

  const onSubmitStep3 = async () => {
    dispatch(setProcessing(true));
    await requestSubmitStep3()
      .then(() => {
        setTimeout(() => {
          clearState3();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState3(arrClear);
        }, 2500);
      });
    dispatch(setProcessing(false));
  };

  const requestSubmitStep3 = () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      tokentrans: tokentransaction,
      currencycode: selectedCurrency,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v02/storecurrency',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            setTokenTransaction(res.status.tokentrans);
            setPaymentmethodlist(res.support);
            setStep('STEP4');
            resolve();
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888050050005')) {
              setSelectedCurrencyError('please provide currency code');
              setSelectedCurrencyErrorisShow(true);
              arrClear.push('currency');
            }

            if (arrError.find(item => item.code === '888050050010')) {
              setSelectedCurrencyError('currency code has exists');
              setSelectedCurrencyErrorisShow(true);
              arrClear.push('currency');
            }

            if (arrError.find(item => item.code === '888050050015')) {
              setSelectedCurrencyError('currency code not found');
              setSelectedCurrencyErrorisShow(true);
              arrClear.push('currency');
            }

            if (arrError.find(item => item.code === '888050050020')) {
              setSelectedCurrencyError('currency code not valid');
              setSelectedCurrencyErrorisShow(true);
              arrClear.push('currency');
            }

            if (arrError.find(item => item.code === '888050050025')) {
              setSelectedCurrencyError('currency code to long max 15 char');
              setSelectedCurrencyErrorisShow(true);
              arrClear.push('currency');
            }

            reject(arrClear);
          }
          dispatch(setProcessing(false));
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  const clearState4 = (clearParams = []) => {
    if (clearParams.includes('paymentmethod')) {
      setSelectedpaymentmethod('');
      setSelectedpaymentmethodError('');
      setSelectedpaymentmethodErrorisShow(false);
    }
  };

  const onSubmitStep4 = async () => {
    dispatch(setProcessing(true));
    await requestSubmitStep4()
      .then(() => {
        setTimeout(() => {
          clearState4();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState4(arrClear);
        }, 2500);
      });
    dispatch(setProcessing(false));
  };

  const requestSubmitStep4 = () => {
    console.log('onstep4');
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      tokentrans: tokentransaction,
      tablepaymentmethodindex: selectedPaymentmethod,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v02/storepaymentmethod',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            setTokenTransaction(res.status.tokentrans);
            setTransactionDetails(res.data);
            convertDatePayment(res.data.tempdepositinserttimestamp);
            onConfirmationTransaction(
              res.data.tempdepositpaymentmethodcategory + ' - ' + res.data.tempdepositpaymentmethoddescription,
            );
            resolve();
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888050100005')) {
              setSelectedpaymentmethodError('please provide payment method');
              setSelectedpaymentmethodErrorisShow(true);
              arrClear.push('paymentmethod');
            }

            if (arrError.find(item => item.code === '888050100010')) {
              setSelectedpaymentmethodError('payment method has exists');
              setSelectedpaymentmethodErrorisShow(true);
              arrClear.push('paymentmethod');
            }

            if (arrError.find(item => item.code === '888050100015')) {
              setSelectedpaymentmethodError('payment method not found ');
              setSelectedpaymentmethodErrorisShow(true);
              arrClear.push('paymentmethod');
            }

            if (arrError.find(item => item.code === '888050100020')) {
              setSelectedpaymentmethodError('payment method not valid');
              setSelectedpaymentmethodErrorisShow(true);
              arrClear.push('paymentmethod');
            }

            if (arrError.find(item => item.code === '888050100025')) {
              setSelectedpaymentmethodError('payment method to long max 15 chard');
              setSelectedpaymentmethodErrorisShow(true);
              arrClear.push('paymentmethod');
            }

            if (arrError.find(item => item.code === '888999999404')) {
              Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'failed call pgc / pga',
              });
              arrClear.push('paymentmethod');
            }
            reject(arrClear);
          }
          dispatch(setProcessing(false));
        })
        .catch(error => {
          alert(error);
        });
    });
  };

  const onConfirmationTransaction = async paymentMethod => {
    dispatch(setProcessing(true));
    let storage = JSON.parse(localStorage.getItem('user'));
    Swal.fire({
      icon: 'warning',
      title: 'Top Up Confirmation',
      html:
        `This is your topup details please check carefully!<br/><br/>` +
        `Amount: ${headeramount}<br/>` +
        `Message: ${headermessage !== '' ? headermessage : 'none'}<br/>` +
        `Currency: ${selectedCurrency}<br/>` +
        `Payment: ${paymentMethod}`,
      //   text: `${storage.user2fatype} is required for this action`,
      //   input: 'password',
      //   inputAttributes: {
      //     autocapitalize: 'off',
      //   },
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      denyButtonText: 'Cancel',
      showDenyButton: true,
      allowOutsideClick: false,
      preDeny: async () => {
        handleBackStep5();
      },
      preConfirm: async pin => {
        let index = JSON.parse(localStorage.getItem('user'));
        let headers = {
          headers: {
            gtoken: 'PASSS',
            userindex: index.userindex,
            tokenlogin: index.usertoken,
            signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
          },
        };
        let parameters = {
          tokentrans: tokentransaction,
        };
        return axios
          .post(
            process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v02/store',
            parameters,
            headers,
          )
          .then(function(response) {
            let res = response.data;
            console.log('preCOnfirm', res);
            if (res.status.status === 1) {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: `Topup success please wait for confirmation!`,
              });
              setResponsePGA(res.data);
              setStep('STEP5');
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: Translation.getServiceLanguage(res.status.code),
              });
              throw new Error(res.status.errormessage);
            }
            dispatch(setProcessing(false));
            return res;
          })
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      // allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.isDenied) {
        Swal.fire({
          icon: 'info',
          title: 'Sorry!',
          text: 'Changes are not saved!',
        });
        handleBackStep5();
      }
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  const handleBackStep3 = () => {
    let index = JSON.parse(localStorage.getItem('user'));
    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      tokentrans: tokentransaction,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v02/back/storecurrency',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          if (res.status.status === 1) {
            setTokenTransaction(res.status.tokentrans);
            setStep('STEP2');
            resolve();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops!',
              text: Translation.getServiceLanguage(res.status.code),
            });
            reject();
          }
        })
        .catch(error => {
          reject();
          alert(error);
        });
    });
  };

  const handleBackStep4 = () => {
    let index = JSON.parse(localStorage.getItem('user'));
    let headers = {
      headers: {
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {
      tokentrans: tokentransaction,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS +
            '/' +
            process.env.REACT_APP_API_PREFIX +
            '/deposit/v02/back/storepaymentmethod',
          parameters,
          headers,
        )
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.status.status === 1) {
            setTokenTransaction(res.status.tokentrans);
            setStep('STEP3');
            resolve();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops!',
              text: Translation.getServiceLanguage(res.status.code),
            });
            reject();
          }
        })
        .catch(error => {
          alert(error);
          reject();
        });
    });
  };

  const handleBackStep5 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));
    console.log('handleBackStep5');
    console.log('handleBackStep5', index);
    // let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += 'PASSS';
    // String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: 'PASSS',
      // gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };
    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/back/store',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log('handleBackStep5', res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP1');
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Sorry!',
            text: 'Changes are not saved!',
          });
          clearState2(['amount']);
          clearState3(['currency']);
          clearState4(['paymentmethod']);
          setStep('STEP1');
        }
      })
      .catch(error => {
        alert(error);
      });
  };
  const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: '#aaa',
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return (
      <MenuItem className={classes.placeholder} key={0} value="">
        {' '}
        {children}{' '}
      </MenuItem>
    );
    // return <div className={classes.placeholder}>{children}</div>;
  };
  //   const topUpInsertNew = () =>{
  //     return new Promise((resolve, reject) =>{
  //         axios
  //       .post(
  //         process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/back/store',
  //         parameters,
  //       )
  //       .then(response => {
  //         let res = response.data;
  //         console.log(res);
  //         if (res.status.status === '080000000000') {

  //         } else {

  //         }
  //       }).catch(error => {
  //         alert(error);
  //       });;
  //     });
  //   }
  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      //   await loadScriptByURL(
      //     'recaptcha-key',
      //     `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //     async function() {
      openSnackbar('Fetching data...', 60000);

      closeSnackbar();
      //     },
      //   );
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent
        style={{
          background:
            step !== 'STEP1'
              ? step !== 'STEP5'
                ? 'url(' + process.env.PUBLIC_URL + '/images/avatar.png' + ') bottom no-repeat'
                : ''
              : '',
        }}>
        <Box className={classes.root}>
          <Stepper activeStep={parseInt(step.substring(4, 5)) - 1} alternativeLabel>
            <Step key={'Topup'}>
              <StepLabel>Topup</StepLabel>
            </Step>
            <Step key={'Amount'}>
              <StepLabel>Amount</StepLabel>
            </Step>
            <Step key={'Currency'}>
              <StepLabel>Currency</StepLabel>
            </Step>
            <Step key={'Payment method'}>
              <StepLabel>Payment method</StepLabel>
            </Step>
            <Step key={'Done'}>
              <StepLabel>Done</StepLabel>
            </Step>
          </Stepper>
          <Box>
            <Box>
              {// eslint-disable-next-line
              step == 'STEP1' ? (
                <div style={{ marginBottom: 10, margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    {/* <Grid item xs={12} md={12} desktop={13} xl={13}>
                      <Alert severity="info">Please click button below to Topup.</Alert>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep1}>
                          {'Create'}
                        </Button>
                      )}
                    </Grid> */}
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '1090px', margin: '0 auto', borderTop: '5px solid', borderColor: '#E8F4FD' }}>
                        <div
                          style={{ marginTop: '59px', display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                          <div style={{ flex: 1 }}>
                            <img src={process.env.PUBLIC_URL + '/images/frame_2608268.png'} />
                          </div>
                          <div style={{ flex: 1 }}>
                            <h2 style={{ fontSize: '28px' }}>Top Up Balance</h2>
                            <p style={{ color: 'blue', fontSize: '14px', paddingTop: 8, fontWeight: 600 }}>
                              Using Anoa Wallet
                            </p>
                            <p style={{ paddingTop: 16, color: '#707375', paddingBottom: 8 }}>
                              Easily top up assets by completing the top up form. Then buy and transfer ANOA from your
                              trusted exchanger where ANOA is listed. Make sure the total amount and the intended wallet
                              address are correct.
                            </p>

                            <a
                              href="https://anoatoken.com/httopup"
                              target="_blank"
                              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}
                              rel="noreferrer">
                              <p style={{ color: 'blue' }}>read top up policies</p>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M14.1667 6.66699L17.5 10.0003M17.5 10.0003L14.1667 13.3337M17.5 10.0003L2.5 10.0003"
                                  stroke="#4C4DDC"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </a>

                            <div style={{ marginTop: 16, display: 'flex', flexDirection: 'column', gap: 5 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                    fill="#4C4DDC"
                                  />
                                </svg>
                                <p style={{ color: '#3D3F40' }}>Fast Transactions</p>
                              </div>

                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                    fill="#4C4DDC"
                                  />
                                </svg>
                                <p style={{ color: '#3D3F40' }}>Easy Accessibility</p>
                              </div>

                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                    fill="#4C4DDC"
                                  />
                                </svg>
                                <p style={{ color: '#3D3F40' }}>Lower Transaction Fees</p>
                              </div>

                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                                    fill="#4C4DDC"
                                  />
                                </svg>
                                <p style={{ color: '#3D3F40' }}>Privacy Guarantee</p>
                              </div>
                            </div>

                            {is_processing ? (
                              <button
                                style={{
                                  marginTop: 28,
                                  width: '100%',
                                  height: '48px',
                                  borderRadius: '8px',
                                  backgroundColor: 'black',
                                  color: 'white',
                                  fontSize: 16,
                                }}>
                                Processing...
                              </button>
                            ) : (
                              <button
                                style={{
                                  marginTop: 28,
                                  width: '100%',
                                  height: '48px',
                                  borderRadius: '8px',
                                  backgroundColor: 'black',
                                  color: 'white',
                                  fontSize: 16,
                                  cursor: 'pointer',
                                }}
                                onClick={onSubmitStep1}>
                                Create
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
              ) : null}

              <br />

              {// eslint-disable-next-line
              step == 'STEP2' ? (
                <div style={{ marginBottom: 10, margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    {/* <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please, set amount and message for topup reminder.</Alert>
                    </Grid> */}
                    {/* <Grid container spacing={2}>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          error={headerAmountErrorisShow}
                          id={headerAmountErrorisShow ? 'outlined-error-helper-text' : ''}
                          type={'text'}
                          fullWidth
                          label={'Amount'}
                          value={headeramount}
                          onChange={event => setHeaderamount(event.target.value)}
                          variant="outlined"
                          helperText={headerAmountError}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} desktop={9} xl={9} ml={3}>
                        <TextField
                          type={'text'}
                          fullWidth
                          id="uncontrolled"
                          label={'Message (optional)'}
                          value={headermessage}
                          onChange={event => setHeadermessage(event.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid> */}

                    {/* <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep2}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid> */}
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '1090px', margin: '0 auto' }}>
                        <div style={{ marginTop: '59px' }}>
                          <Alert severity="info">Please, set amount and message for topup reminder.</Alert>
                          <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <TextField
                              error={headerAmountErrorisShow}
                              id={headerAmountErrorisShow ? 'outlined-error-helper-text' : ''}
                              type={'text'}
                              fullWidth
                              label={'Amount'}
                              value={headeramount}
                              onChange={event => setHeaderamount(event.target.value)}
                              variant="outlined"
                              helperText={headerAmountError}
                            />

                            <TextField
                              type={'text'}
                              fullWidth
                              id="uncontrolled"
                              label={'Message (optional)'}
                              value={headermessage}
                              onChange={event => setHeadermessage(event.target.value)}
                              variant="outlined"
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: 5,
                            }}>
                            <p style={{ color: 'blue', fontWeight: 600 }}>Minimal Top Up 1 ANOA</p>
                            {is_processing ? (
                              <button
                                style={{
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  backgroundColor: 'black',
                                  color: 'white',
                                  borderRadius: 8,
                                }}>
                                Processing...
                              </button>
                            ) : (
                              <button
                                style={{
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  cursor: 'pointer',
                                  backgroundColor: 'black',
                                  color: 'white',
                                  borderRadius: 8,
                                }}
                                onClick={onSubmitStep2}>
                                NEXT
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == 'STEP3' ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    {/* <Grid item xs={12} md={12} desktop={9} xl={9}> */}
                    Currency details
                    {/* </Grid> */}
                    <Divider />
                    <br />
                    <Select
                      error={selectedCurrencyErrorisShow}
                      labelId={
                        selectedCurrencyErrorisShow ? 'demo-simple-select-error-label' : 'demo-simple-select-outlined-label'
                      }
                      id={selectedCurrencyErrorisShow ? 'demo-simple-select-error' : 'demo-simple-select-outlined'}
                      value={selectedCurrency}
                      onChange={handleChange}
                      label="Currency"
                      // renderValue={
                      //   showPlaceHolder ? () => <Placeholder>Answer</Placeholder> : undefined
                      // }
                    >
                      <MenuItem key={0} value="">
                        {' '}
                        --SELECT CURRENCY--{' '}
                      </MenuItem>

                      {/* //   ) : null} */}
                      {currencyRate.length > 0 &&
                        currencyRate.map((data, index) => {
                          return (
                            <MenuItem key={index + 1} value={data.temptrxcurrencyratecurrencycode}>
                              {data.temptrxcurrencyratecurrencycode} - {data.temptrxcurrencyratecurrencydescription}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{selectedCurrencyError}</FormHelperText>
                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? null : (
                        <Button disabled={step !== 'STEP3'} onClick={handleBackStep3} className={classes.backButton}>
                          Back
                        </Button>
                      )}
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep3}>
                          {'Confirm'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == 'STEP4' ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    {/* <Grid item xs={12} md={12} desktop={9} xl={9}> */}
                    Payment method list
                    {/* </Grid> */}
                    <Divider />
                    <br />
                    <Select
                      error={selectedPaymentmethodErrorisShow}
                      labelId={
                        selectedPaymentmethodErrorisShow
                          ? 'demo-simple-select-error-label'
                          : 'demo-simple-select-error-label'
                      }
                      id={selectedPaymentmethodErrorisShow ? 'demo-simple-select-error' : 'demo-simple-select-error'}
                      value={selectedPaymentmethod}
                      onChange={handleChangePaymentmethod}
                      label="Payment method">
                      {/* {paymentmethodlist.length > 1 ? ( */}
                      <MenuItem key={0} value="">
                        <em>-- Select Payment Method --</em>
                      </MenuItem>
                      {/* ) : null} */}
                      {paymentmethodlist.length > 0 &&
                        paymentmethodlist.map((data, index) => {
                          return (
                            <MenuItem key={index + 1} value={data.index}>
                              {data.code} - {data.description}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>{selectedPaymentmethodError}</FormHelperText>
                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? null : (
                        <Button disabled={step !== 'STEP4'} onClick={handleBackStep4} className={classes.backButton}>
                          Back
                        </Button>
                      )}
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep4}>
                          {'Confirm'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == 'STEP5' ? (
                <div style={{ marginBottom: 10, maxWidth: '500px', margin: 'auto' }}>
                  <CmtCard className={classes.cardRoot}>
                    <CmtCardContent>
                      <GridContainer>
                        <Grid container justifyContent="center" xs={12} sm={12}>
                          <AccessTime style={{ width: 75, height: 75, color: 'orange' }} />
                        </Grid>
                        <Grid container justifyContent="center" xs={12} sm={12}>
                          <h3 style={{ fontWeight: 'bold', color: 'orange' }}>Transaction On Process</h3>
                        </Grid>
                        <Grid container justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                          <h3 style={{ fontWeight: 'bold', fontSize: 20 }}>
                            {responsePGA.amount} - {responsePGA.currency}
                          </h3>
                        </Grid>

                        <Grid item justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                          <CmtCard className={classes.cardRoot}>
                            <CmtCardContent>
                              <GridContainer>
                                <Grid item container justifyContent="center" xs={12} sm={12}>
                                  <CmtImage src={'/images/dwa_logo.png'} style={{ height: 50 }} />
                                </Grid>

                                <Grid item container justifyContent="center" xs={12} sm={12}>
                                  <label style={{ fontWeight: 'bolder' }}>You haven't complete transaction</label>
                                </Grid>

                                <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                  <label style={{ color: 'grey' }}>Amount </label>
                                </Grid>
                                <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                  <label style={{ fontWeight: 'bolder' }}>{responsePGA.net_amount}</label>
                                </Grid>

                                <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                  <label style={{ color: 'grey' }}>Status</label>
                                </Grid>
                                <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                  <label style={{ fontWeight: 'bolder', color: 'red' }}>Pending</label>
                                </Grid>

                                <Grid container justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                                  <Alert severity="warning" style={{ cursor: 'pointer', width: '100%' }}>
                                    <strong>
                                      <h5>Please pay this transaction before {timerDuedate}.</h5>
                                      {/* <h4>To</h4> */}
                                      <h4>To : {responsePGA.pay_code[0].tablewalletaddresswalletaddress}</h4>
                                    </strong>
                                  </Alert>
                                </Grid>
                              </GridContainer>
                            </CmtCardContent>
                          </CmtCard>
                        </Grid>

                        <Grid item justifyContent="center" xs={12} sm={12}>
                          <GridContainer>
                            <Grid container justifyContent="flex-start" xs={12} sm={12} style={{ marginBottom: 20 }}>
                              <label style={{ fontWeight: 'bolder' }}>Transaction Details</label>
                            </Grid>
                            <Grid container justifyContent="flex-start" xs={12} sm={12} style={{ marginBottom: 20 }}>
                              {/* <img
                                src={payment['paymentMethod'] ? payment['paymentMethod'].icon_url : null}
                                style={{ width: 150, height: 60 }}
                                alt={'alt_paymentMethod'}
                              /> */}
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Invoice No.</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>{responsePGA.invoice_no}</label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Name</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>{responsePGA.customer.user_name}</label>
                            </Grid>
                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Email</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>{responsePGA.customer.user_email}</label>
                            </Grid>
                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Phone number</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>{responsePGA.customer.user_phone}</label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Description</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>{responsePGA.payment_method}</label>
                            </Grid>
                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Currency</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>{responsePGA.currency}</label>
                            </Grid>

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Payment Method</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {transactionDetails[0].temptrxheaderpaymentmethodcategory}
                              </label>
                            </Grid> */}

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Created</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {getFormattedDate(transactionDetails[0].temptrxheadertimestamp)}
                              </label>
                            </Grid> */}

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Bank</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {transactionDetails[0].temptrxheaderpaymentmethodcode}
                              </label>
                            </Grid> */}

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Fee</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails[0].temptrxheaderfee)}
                              </label>
                            </Grid> */}

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Total</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails[0].temptrxheadertotal, 'IDR ')}
                              </label>
                            </Grid> */}

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Surcharge</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails.surcharge, 'IDR ')}
                              </label>
                            </Grid> */}

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4} style={{ marginTop: 10 }}>
                              <label style={{ color: 'grey' }}>Total</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8} style={{ marginTop: 10 }}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails.amount, 'IDR ')}
                              </label>
                            </Grid>  */}
                          </GridContainer>
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <img
                            src={'https://kapilerindonesia.com/asset/image/news/customer-service.png'}
                            style={{ width: 50, height: 50 }}
                            alt={'alt_customerservice'}
                          />
                        </Grid>
                        <Grid item xs={10} sm={10}>
                          <label>Please contact ANOA Customer service if you have problem with payments.</label>
                        </Grid>

                        <Grid item justifyContent="center" xs={12} sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ width: '100%' }}
                            onClick={() => (window.location = '/topup')}>
                            {'Already Paid'}
                          </Button>
                        </Grid>
                      </GridContainer>
                    </CmtCardContent>
                  </CmtCard>
                </div>
              ) : null}
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default TopUp;
