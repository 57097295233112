import { Collapse, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CmtImage from '../../@coremat/CmtImage';
import AuthWrapper from '../../@jumbo/components/Common/authComponents/AuthWrapper';
import ContentLoader from '../../@jumbo/components/ContentLoader';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants';
import { setAuthUser } from '../../redux/actions/Auth';
// import { AuhMethods } from '../../services/auth';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../i18n/entries/Service';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetGauthPage = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  // const { send_request_new_gauth } = useSelector(({ auth }) => auth);
  // const [open, setOpen] = React.useState(false);
  const [timer, setCountTimer] = useState(60);
  const [isGenerated, setIsGenerated] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  // useEffect(() => {
  //   let timeOutStopper = null;
  //   let time = 60;
  //   setOpen(true);

  //   if (send_request_new_gauth) {
  //     setAlertMsg('Google Authenticator generated.');

  //     setInterval(() => {
  //       setCountTimer(time--);
  //     }, 1000);

  //     timeOutStopper = setTimeout(() => {
  //       dispatch(setRequestNewGauth(false));
  //     }, 60000);
  //   } else {
  //     setAlertMsg('Failed Generate Google Authenticator please relogin.');
  //   }

  //   return () => {
  //     if (timeOutStopper) clearTimeout(timeOutStopper);
  //     //forceLogout();
  //   };
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [send_request_new_gauth]);

  const forceLogout = () => {
    localStorage.removeItem('user');
    dispatch(setAuthUser(null));
    window.location.href = '/signin';
  };

  // const forceLogoutDone = () =>{
  //   localStorage.removeItem('user');
  //   dispatch(setAuthUser(null));
  //   sessionStorage.removeItem('set_last_url');
  //   window.location.href = '/signin';
  // }

  const forceLogoutAll = () => {
    localStorage.removeItem('user');
    dispatch(setAuthUser(null));
    sessionStorage.removeItem('set_last_url');
    window.location.href = '/signin';
  };

  const requestGauthUrl = async () => {
    Swal.fire({
      icon: 'warning',
      title: 'Security Confirmation',
      text: 'Password is required for this action',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      preConfirm: async password => {
        let index = JSON.parse(localStorage.getItem('user'));

        let token = await handleGetTokenRecaptcha();

        var String = '';

        String += 'WEBSITE';
        String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
        String += index.userindex;
        String += index.usertoken;
        String += password;

        let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

        let parameters = {
          platform: 'WEBSITE',
          gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
          userindex: index.userindex,
          username: index.username,
          tokenlogin: index.usertoken,
          userpassword: password,
          signature: signature.toString(),
        };

        return axios
          .post(
            process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/resetgauth',
            parameters,
          )
          .then(function(response) {
            let res = response.data;
            console.log(res);
            if (res.status.code === '080000000000') {
              //success
              Swal.fire({
                icon: 'success',
                title: 'Generated',
                text: 'Please scan this QR-Code using your Google Authenticator.',
                imageUrl: res.result.qrcode,
              });
              setIsGenerated(true);
              //forceLogoutAll();
            } else if (res.status.code === '080005080005') {
              //token expired
              Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: Translation.getServiceLanguage(res.status.code),
              });
              sessionStorage.setItem('set_last_url', '/reset-new-gauth');

              setTimeout(() => {
                forceLogout();
              }, 2000);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: Translation.getServiceLanguage(res.status.code),
              });
              sessionStorage.setItem('set_last_url', '/reset-new-gauth');
              setTimeout(() => {
                forceLogout();
              }, 2000);
              throw new Error(res.status.errormessage);
            }
            return res;
          })
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    let time = 60;
    setInterval(() => {
      setCountTimer(time--);
    }, 1000);
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        function() {
          console.log('Script loaded!');
        },
      );
    };

    loadScriptRecaptcha().catch(console.error);

    // load the script by passing the URL
  }, [dispatch]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} alt="img" />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset Gauth
        </Typography>
        <Collapse in={true}>
          <Alert
            variant="outlined"
            severity={timer <= 0 ? 'error' : isGenerated ? 'success' : 'info'}
            className={classes.alertRoot}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {timer <= 0
              ? 'Your session is expired please re-login'
              : isGenerated
              ? 'Success Generate.'
              : 'Please click button below to generate QR-Code using Google Authenticator Apps'}
            <br />
          </Alert>
          {timer <= 0 ? (
            <Button onClick={() => forceLogout()} variant="contained" style={{ backgroundColor: '#cc0000', color: 'white' }}>
              Logout
            </Button>
          ) : isGenerated ? (
            <Button onClick={() => forceLogoutAll()} variant="contained" color="primary">
              Done
            </Button>
          ) : (
            <Button onClick={() => requestGauthUrl()} variant="contained" color="primary">
              Generate {'(' + timer + ' left)'}
            </Button>
          )}

          {/* {send_request_new_gauth ? (
            localStorage.getItem('qrcode') ? (
              <img src={localStorage.getItem('qrcode')} style={{ height: 150, width: 150 }} alt="QRCode" />
            ) : null
          ) : null}
          <br /> */}

          {/* {send_request_new_gauth ? (
            localStorage.getItem('qrcode') ? (
              <Button onClick={() => forceLogout()} variant="contained" color="success">
                I'm already scan
              </Button>
            ) : null
          ) : null}

          {send_request_new_gauth === false ? (
            <Button onClick={() => forceLogout()} variant="contained" color="success">
              Please re-login
            </Button>
          ) : null}

          {send_request_new_gauth ? (
            localStorage.getItem('qrcode') ? (
              <p style={{ fontStyle: 'italic' }}>* Please scan this QR-Code using Google Authenticator Apps.</p>
            ) : null
          ) : null} */}
        </Collapse>

        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResetGauthPage;
