import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { alpha, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton, Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { AuhMethods } from '../../../../services/auth';
import { setAuthUser } from '../../../../redux/actions/Auth';
import Translation from '../../../../i18n/entries/Service';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import IntlMessages from '../../../utils/IntlMessages';
import ContentLoader from '../../ContentLoader';
import AuthWrapper from './AuthWrapper';
import cryptoJS from 'crypto-js';
import axios from 'axios';
import { setProcessing } from '../../../../redux/actions/Processing';
import Swal from 'sweetalert2';
import Service from '../../../../i18n/entries/Service';
import { ReportProblem } from '@material-ui/icons';
import { async } from '@firebase/util';
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default_zero', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailErrorisShow, setEmailErrorisShow] = useState(false);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordErrorisShow, setPasswordErrorisShow] = useState(false);
  // const [isLoaded, setIsloaded] = useState(true);
  // const [token, setToken] = useState('');
  const [suspended, setSuspended] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const { is_processing } = useSelector(({ processing }) => processing);

  const requestSubmit = async () => {
    let parameters = {
      tableusername: email,
      tableuserpassword: password,
      latitude: -6.17511,
      longitude: 106.865036,
      tableuserlanguage: 'EN',
    };

    let headers = {
      headers: {
        gtoken: 'PASSS',
        signature: 'be49d5a5bddc548eeeb8d21afed4b2fdc10c5f724fb4006594bfe9c1c0093457',
      },
    };

    return new Promise(function(resolve, reject) {
      axios
        .post(
          process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/auth/v01/signin',
          parameters,
          headers,
        )
        .then(function(response) {
          let res = response.data;
          // eslint-disable-next-line
          console.log("Login Response", res);

          if (res.status.status === 1) {
            // dispatch(fetchSuccess(Translation.getServiceLanguage(res.status.code)));

            const user = {
              usernick: res.data.tableuserfullname,
              username: res.data.tableusername,
              userindex: res.data.tableuserindex,
              usertoken: res.data.tokenlogin,
              userref: res.data.tableuserreferralcode,
              user2fatype: res.data.tableuser2fatype,
              userphonenumber: res.data.tableuserphonenumber,
              userphonecountrycode: res.data.tableuserphonecountrycode,
              userphonenumbershort: res.data.tableuserphonenumbershort,
              userinitial: res.data.tableuserinitial,
              userlang: res.data.tableuserlanguage,
              userphoneisverified: res.data.tableuserphonenumberisverified,
              usercolorback: res.data.tableusercolorback,
              usercolorfront: res.data.tableusercolorfront,
            };
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('LangApps', res.data.tableuserlanguage);

            dispatch(setAuthUser(user));
            if (sessionStorage.getItem('set_last_url')) {
              window.location.href = sessionStorage.getItem('set_last_url');
            }

            resolve();
          } else {
            let arrError = [];
            let arrClear = [];

            if (res.status.message.length > 0) {
              for (var i = 0; i < res.status.message.length; i++) {
                arrError.push(res.status.message[i]);
              }
            }

            if (arrError.find(item => item.code === '888999999955')) {
              const arrErrors = arrError.find(item => item.code === '888999999955');
              setEmailError('wrong username and password can try ' + arrErrors.codevariable + ' times again');
              setEmailErrorisShow(true);
              setPasswordError('wrong username and password can try ' + arrErrors.codevariable + ' times again');
              setPasswordErrorisShow(true);
              arrClear.push('password');
              arrClear.push('email');
            }

            if (arrError.find(item => item.code === '888999999960')) {
              // setEmailError("user suspended");
              // setEmailErrorisShow(true);
              setSuspended(true);
              arrClear.push('email');
            }
            if (arrError.find(item => item.code === '888005115020')) {
              const arrErrors = arrError.find(item => item.code === '888005115020');
              setPasswordError(arrErrors.errormassage);
              setPasswordErrorisShow(true);
              arrClear.push('password');
            }

            if (arrError.find(item => item.code === '888999999950')) {
              setEmailError('wrong username password');
              setEmailErrorisShow(true);
              setPasswordError('wrong username password');
              setPasswordErrorisShow(true);
              arrClear.push('email');
              arrClear.push('password');
            }

            if (arrError.find(item => item.code === '888005015005')) {
              setEmailError('Please provide User Name');
              setEmailErrorisShow(true);
              arrClear.push('email');
            }

            if (arrError.find(item => item.code === '888005115005')) {
              setPasswordError('please provide password');
              setPasswordErrorisShow(true);
              arrClear.push('password');
            }
            reject(arrClear);
          }
        });
    });
  };

  const clearState = (clearParams = []) => {
    if (clearParams.includes('email')) {
      setEmail('');
      setEmailError('');
      setEmailErrorisShow(false);
    }

    if (clearParams.includes('password')) {
      setPassword('');
      setPasswordError('');
      setPasswordErrorisShow(false);
    }
    if (clearParams.length < 1) {
      setEmail('');
      setEmailError('');
      setEmailErrorisShow(false);
      setPassword('');
      setPasswordError('');
      setPasswordErrorisShow(false);
    }
  };

  const onSubmit = async () => {
    dispatch(setProcessing(true));
    setSuspended(false);
    await requestSubmit()
      .then(() => {
        setTimeout(() => {
          clearState();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState(arrClear);
        }, 2500);
      });
    dispatch(setProcessing(false));
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      //   await loadScriptByURL(
      //     'recaptcha-key',
      //     `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //     function() {
      console.log('Script loaded!');
      //   },
      // );
    };

    loadScriptRecaptcha().catch(console.error);

    // load the script by passing the URL
  }, [dispatch]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              error={emailErrorisShow}
              id={emailErrorisShow ? 'outlined-error-helper-text' : ''}
              label={'Username'}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              value={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={emailError}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <p />
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/reset-username">
                Forgot username?
                {/* <IntlMessages id="appModule.forgotPassword" /> */}
              </NavLink>
            </Box>
          </Box>
          <Box mb={2}>
            <TextField
              error={passwordErrorisShow}
              id={passwordErrorisShow ? 'outlined-error-helper-text' : ''}
              type={showPassword ? 'text' : 'password'}
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              value={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <Button onClick={togglePassword}>
                    {!showPassword ? (
                      <VisibilityOff style={{ color: 'black' }} />
                    ) : (
                      <Visibility style={{ color: 'black' }} />
                    )}
                  </Button>
                ),
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" />}
              label="Remember me"
            />
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/forgot-password">
                Forgot password
                {/* <IntlMessages id="appModule.forgotPassword" /> */}
              </NavLink>
            </Box>
          </Box>
          {suspended ? (
            <Box
              display="flex"
              alignItems="center"
              flexDirection={'row'}
              paddingY={2}
              paddingX={2}
              bgcolor={'#FED7D7'}
              borderRadius={4}
              border={1}
              borderColor={'#CB3A31'}
              mb={5}>
              <ReportProblem style={{ color: '#CB3A31', blockSize: 20 }} />{' '}
              <Box paddingLeft={2}>
                <span
                  style={{
                    fontSize: 11,
                    fontStyle: 'bold',
                    paddingBottom: 5,
                    color: '#CB3A31',
                  }}>
                  Your account is suspended, please do forgot password.
                </span>
              </Box>
            </Box>
          ) : null}

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            {is_processing ? (
              <Button variant="contained" color="primary">
                Processing...
              </Button>
            ) : (
              <Button onClick={onSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.signIn" />
              </Button>
            )}
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to={'/signup'}>
                <IntlMessages id="signIn.signUp" />
              </NavLink>
            </Box>
          </Box>
          <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
            <p style={{ fontSize: 11, alignSelf: 'center' }}>{process.env.REACT_APP_VERSION}</p>
          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
