import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, IconButton, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../@coremat/CmtImage';

import IntlMessages from '../../@jumbo/utils/IntlMessages';
// import { AuhMethods } from '../../services/auth';
import ContentLoader from '../../@jumbo/components/ContentLoader';
import { CurrentAuthMethod } from '../../@jumbo/constants/AppConstants';
import AuthWrapper from '../../@jumbo/components/Common/authComponents/AuthWrapper';
import { setAuthUser } from '../../redux/actions/Auth';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../i18n/entries/Service';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetPinPage = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  // const { send_request_new_pin } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [pin, setPin] = useState('');
  const [c_pin, setCpin] = useState();
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  // const history = useHistory();
  // useEffect(() => {
  //   let timeOutStopper = null;
  //   if (send_request_new_pin) {
  //     setOpen(true);

  //     timeOutStopper = setTimeout(() => {
  //       dispatch(setRequestNewPin(false));
  //       history.push('/signin');
  //     }, 3000);
  //   }

  //   return () => {
  //     if (timeOutStopper) clearTimeout(timeOutStopper);
  //   };
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [send_request_new_pin]);

  const forceLogout = () => {
    localStorage.removeItem('user');
    dispatch(setAuthUser(null));
    sessionStorage.removeItem('set_last_url');
    window.location.href = '/signin';
  };

  const onSubmit = async () => {
    if (pin !== '' && c_pin !== '') {
      if (pin === c_pin) {
        Swal.fire({
          icon: 'warning',
          title: 'Security Confirmation',
          text: 'Password is required for this action',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',
          showLoaderOnConfirm: true,
          preConfirm: async password => {
            let index = JSON.parse(localStorage.getItem('user'));

            let token = await handleGetTokenRecaptcha();
            var String = '';

            String += 'WEBSITE';
            String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
            String += index.userindex;
            String += index.usertoken;
            String += password;
            String += pin;

            let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

            let parameters = {
              platform: 'WEBSITE',
              gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
              userindex: index.userindex,
              username: index.username,
              tokenlogin: index.usertoken,
              userpassword: password,
              userpinnew: pin,
              signature: signature.toString(),
            };
            return axios
              .post(
                process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/resetpin',
                parameters,
              )
              .then(function(response) {
                let res = response.data;
                console.log(res);
                if (res.status.code === '080000000000') {
                  Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'PIN reset successfully changed!',
                  });
                  forceLogout();
                  //localStorage.clear();
                  //dispatch(setAuthUser(null));
                  setTimeout(() => {
                    window.location.href = '/';
                  }, 2000);
                } else if (res.status.code === '080005080005') {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: Translation.getServiceLanguage(res.status.code),
                  });
                  dispatch(setAuthUser(null));
                  sessionStorage.setItem('set_last_url', '/reset-new-pin');
                  setTimeout(() => {
                    window.location.href = '/signin';
                  }, 2000);
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: Translation.getServiceLanguage(res.status.code),
                  });
                  dispatch(setAuthUser(null));
                  sessionStorage.setItem('set_last_url', '/reset-new-pin');
                  setTimeout(() => {
                    window.location.href = '/signin';
                  }, 2000);
                  throw new Error(res.status.errormessage);
                }
                return res;
              })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      } else {
        alert('PIN must match!');
      }
    } else {
      alert('Fields must be filled!');
    }
  };

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        function() {
          console.log('Script loaded!');
        },
      );
    };

    loadScriptRecaptcha().catch(console.error);

    // load the script by passing the URL
  }, [dispatch]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset Pin
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            PIN has been changed, now you're logged out.
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              type="password"
              label={'PIN'}
              fullWidth
              onChange={event => setPin(event.target.value)}
              defaultValue={pin}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </div>
          <div className={'mb-5'}>
            <TextField
              type="password"
              label={'Confirm PIN'}
              fullWidth
              onChange={event => setCpin(event.target.value)}
              defaultValue={c_pin}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </div>
          <div className={'mb-5'}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              Confirm
            </Button>
          </div>

          <div>
            <Typography>
              <span className={'ml-2'}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </span>
            </Typography>
          </div>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResetPinPage;
