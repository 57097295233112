import { MenuList, Paper, Popover } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import InputLabel from '@material-ui/core/InputLabel';
import { FileCopy } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { VerifiedUser, Security, CheckCircle } from '@material-ui/icons';
// import { Link } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import loaderContent from '../../../../@jumbo/utils/loaderContent';
import Translation from '../../../../i18n/entries/Service';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { setAuthUser } from 'redux/actions/Auth';

const useStyles = makeStyles(theme => ({
  headerRoot: {
    position: 'relative',
    margin: '-30px -15px 0 -15px',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 30,
    paddingBottom: 20,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 56,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: -50,
      marginRight: -50,
      paddingLeft: 50,
      paddingRight: 50,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: -65,
      marginRight: -65,
      paddingLeft: 65,
      paddingRight: 65,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: -88,
      marginRight: -88,
      paddingLeft: 88,
      paddingRight: 88,
    },
  },
  headerBgImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    minHeight: 370,
    zIndex: 0,
    [theme.breakpoints.up('sm')]: {
      minHeight: 270,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  headerContent: {
    position: 'relative',
    zIndex: 3,
  },
  titleRoot: {
    color: theme.palette.common.white,
    marginBottom: 4,
  },
  subTitleRoot: {
    color: alpha(theme.palette.common.white, 0.74),
  },
  followerList: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -15,
    marginRight: -15,
    [theme.breakpoints.up('md')]: {
      marginLeft: -24,
      marginRight: -24,
    },
  },
  followerListItem: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    cursor: 'pointer',
    color: alpha(theme.palette.common.white, 0.74),
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '&:not(:first-child)': {
      borderLeft: `solid 1px ${alpha(theme.palette.common.white, 0.38)}`,
    },
  },
  followerListTitle: {
    color: theme.palette.common.white,
    marginBottom: 3,
  },
  tabsList: {
    position: 'relative',
    minHeight: 10,
    '& .MuiTabs-indicator': {
      backgroundColor: alpha(theme.palette.common.white, 0.4),
    },
  },
  tabItem: {
    maxWidth: 'none',
    minWidth: 10,
    minHeight: 10,
    padding: '5px 10px',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Header = ({ userDetail, fields, handleInputChange, isLoaded }) => {
  //const { name, profile_pic, location, followers, following, friends } = userDetail;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (url, label) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be forced out. Please follow the Change Password procedure.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Back',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(result => {
      if (result.isConfirmed) {
        // sessionStorage.setItem('set_last_url', url);
        // dispatch(AuhMethods.basic.onLogout());
        // dispatch(setAuthUser(null));

        window.location.href = '/change-password';
      }
    });
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return isLoaded ? (
    <loaderContent.MyLoader width={350} height={84} speed={1} />
  ) : (
    <Box className={classes.headerRoot}>
      <Box className={classes.headerBgImg}>
        <CmtImage src={'/images/profile-bg-img.png'} />
      </Box>

      <Box className={classes.headerContent}>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" mb={4}>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
            <Box mr={{ sm: 4, md: 5, lg: 6 }} mb={{ xs: 3, sm: 0 }}>
              {/* <CmtAvatar size={80} src={profile_pic} alt={name} /> */}
            </Box>

            <Box>
              <Typography className={classes.titleRoot} component="div" variant="h1">
                {userDetail.tableusernick}
              </Typography>

              {userDetail.tableuserphoneisverified === 0 && userDetail.tableuser2fatype !== null ? (
                <Typography className={classes.subTitleRoot}>
                  Authorized
                  <VerifiedUser
                    style={{
                      color: '#3197E9',
                      height: 15,
                      width: 15,
                      position: 'relative',
                      top: 1,
                    }}
                  />
                </Typography>
              ) : null}
            </Box>
          </Box>
          <Box ml={{ sm: 'auto' }} mt={{ xs: 3, sm: 0 }}>
            {/* {isLoaded ? <loaderContent.MyLoader width={350} height={84} speed={1} /> : */}
            <Box className={classes.followerList}>
              {/* <Box className={classes.followerListItem}>
                <Typography className={classes.followerListTitle} component="div" variant="h3">
                  {Translation.getServiceLanguage('tableuserphoneisverified')}
                </Typography>
                <Box component="p">
                  <label
                    style={{
                      // eslint-disable-next-line
                      position: "relative",
                      top: 2,
                      backgroundColor: userDetail.tableuserphonenumberisverified === 0 ? '' : 'lightgray',
                      padding: 3,
                      // eslint-disable-next-line
                      color:
                        userDetail.tableuserphonenumberisverified === 0
                          ? ""
                          : "black",
                      borderRadius: 5,
                    }}
                    onClick={()=> 
                      userDetail.tableuserphonenumberisverified === 0
                        ? (window.location.href = '/sms-request-otp')
                        : null
                    }>
                    {// eslint-disable-next-line
                    userDetail.tableuserphonenumberisverified === 0 ? (
                      'Click to Verify'
                    ) : (
                      <CheckCircle style={{ color: '#4BB543', height: 18, width: 18 }} />
                      
                    )}
                  </label>
                </Box>
              </Box> */}
              <Box className={classes.followerListItem}>
                {/* <Typography className={classes.followerListTitle} onClick={handlePopoverOpen} component="div" variant="h3"> */}
                <Typography
                  className={classes.followerListTitle}
                  onClick={() => handlePopoverClose('/change-password', 'Change Password')}
                  component="div"
                  variant="h3">
                  Change Password
                  {/* {open ? (
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      container={anchorEl}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}>
                      <Paper elevation={8}> */}
                  {/* <MenuList> */}
                  {/* <MenuItem onClick={() => handlePopoverClose('/reset-new-pin', 'PIN')}> */}
                  {/* <Link to="/reset-new-pin"> */}
                  {/* <div className="ml-2">Reset PIN</div> */}
                  {/* </Link> */}
                  {/* </MenuItem> */}
                  {/* <MenuItem onClick={() => handlePopoverClose('/reset-new-gauth', 'Google Authenticator')}> */}
                  {/* <Link to="/reset-new-gauth"> */}
                  {/* <div className="ml-2">Reset Gauth</div> */}
                  {/* </Link> */}
                  {/* </MenuItem> */}
                  {/* <MenuItem onClick={() => handlePopoverClose('/change-password', 'Change Password')}> */}
                  {/* <Link to="/change-password"> */}
                  {/* <div className="ml-2">Change Password</div> */}
                  {/* </Link> */}
                  {/* </MenuItem> */}
                  {/* </MenuList> */}
                  {/* </Paper> */}
                  {/* // </Popover> */}
                  {/* ) : null} */}
                </Typography>
                <Box component="p">
                  {/* {userDetail.tableuser2fatype ? (
                    <Security
                      style={{
                        color: '#3197E9',
                        width: 14,
                        height: 14,
                        top: 2,
                        position: 'relative',
                      }}
                    />
                  ) : null}
                  {userDetail.tableuser2fatype ? userDetail.tableuser2fatype : '-'} */}
                  <button
                    onClick={() => handlePopoverClose('/change-password', 'Change Password')}
                    style={{
                      position: 'relative',
                      top: 2,
                      padding: 3,
                      backgroundColor: 'transparent',
                      cursor: 'pointer',
                      border: 'none',
                      color: 'lightGray',
                    }}>
                    Click here
                  </button>
                </Box>
              </Box>
              <Box className={classes.followerListItem}>
                <Typography className={classes.followerListTitle} component="div" variant="h3">
                  {/* {Translation.getServiceLanguage('tableuserreferralcode')} */}
                  Referal Code
                </Typography>
                <Box component="p">
                  {/* <FileCopy
                    style={{ height: 15, marginTop: 2 }}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        window.location.origin + '/registration/' + userDetail.tableuserreferralcode,
                      )
                    }
                  /> */}
                  <label
                    style={{
                      position: 'relative',
                      top: 2,
                      padding: 3,
                    }}>
                    {userDetail.tableuserreferralcode ? userDetail.tableuserreferralcode : '-'}
                  </label>
                </Box>
              </Box>
            </Box>
            {/* } */}
          </Box>
        </Box>

        {/* <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
          <Tabs className={classes.tabsList} value={tabValue} onChange={handleTabChange} aria-label="profile tabs">
            {tabs.map((item, index) => {
              return (
                <Tab
                  className={classes.tabItem}
                  key={index}
                  value={item.slug}
                  label={item.title}
                  style={{ color: 'white' }}
                />
              );
            })}
          </Tabs>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Header;

Header.prototype = {
  userDetail: PropTypes.object.isRequired,
  tabValue: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func,
};
