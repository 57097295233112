import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      <title />
      <meta name="generator" content="LibreOffice 7.4.2.3 (Linux)" />
      <meta name="author" content="randy@nusi.co.id" />
      <meta name="created" content="2023-01-17T07:47:00" />
      <meta name="changedby" content="juan" />
      <meta name="changed" content="2023-01-17T07:47:00" />
      <meta name="AppVersion" content={16.0} />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n\t\t@page { size: 8.5in 11in; margin: 1in }\n\t\tp { line-height: 115%; text-align: left; orphans: 2; widows: 2; margin-bottom: 0.1in; direction: ltr; background: transparent }\n\t',
        }}
      />
      <p align="center" style={{ lineHeight: '100%', marginBottom: '0in' }}>
        <font face="Arial, serif">
          <font size={3} style={{ fontSize: '12pt' }}>
            <b>PRIVACY POLICY</b>
          </font>
        </font>
      </p>
      <p align="center" style={{ lineHeight: '100%', marginBottom: '0in' }}>
        <br />
      </p>
      <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          The protection and confidentiality of your personal information is very important to us. Accordingly, ANOA has
          established the following privacy policy:
        </font>
      </p>
      <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>ANOA Commitment</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          We are using your personal information in accordance with the relevant provisions of the personal data protection
          regulations. This privacy policy describes the collection, application, storage and protection of your personal
          information. This applies to applications (mobile applications), all related websites and services from ANOA
          regardless of how you access or use them.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>Scope and Consent</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          You accept this privacy policy when you register, access, or use our products, services, content, features,
          technology or functionality offered on mobile applications, all related websites and services (collectively, the
          “ANOA Services”. "). We may upload policy changes on this page periodically, the revised version will take effect
          on the effective date of publication. It is your responsibility to review this privacy policy as often as possible.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          For the purposes of this privacy policy, we use the term “personal information” to describe information that can be
          associated with a particular individual and can be used to identify that individual. This privacy policy does not
          apply to information that is made anonymous so that it cannot identify certain users.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>Collection of Personal Information</b>
            </font>
          </p>
        </li>
      </ul>
      <ol type="a">
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              We may collect personally identifiable information from users in a variety of ways, aggregating, but not
              limiting, when users visit our site, register on the site, and connect with other similar activities, services,
              features or resources that we provide on our website. Users may be asked to provide the appropriate name and
              email address. Users may visit our site anonymously. We will collect personally identifiable information from
              users when they voluntarily submit such information to us. Users can always refuse to provide personally
              identifiable information, except that doing so may prevent them from engaging in certain website-related
              activities.
            </font>
          </p>
        </li>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              We may collect non-personally identifiable information about users whenever they interact with our website.
              Non-personal identification information may include browser name, computer type and technical information about
              users who are connected to our website, such as the operating system and Internet service provider used and
              other similar information.
            </font>
          </p>
        </li>
      </ol>
      <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>Cookie Web Browser</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          Our site may use "cookies" to improve user experience. Users' web browsers place cookies on their hard drives for
          recording purposes and sometimes to track information about them. Users can choose to set their web browser to
          refuse cookies, or to notify you when a cookie is sent. If they do, note that some parts of the site may not
          function properly.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <br />
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <br />
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <br />
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>How We Use Collected Information?</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          ANOA may collect and use User's personal information for the following purposes:
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.25in', marginBottom: '0in' }}>
        <br />
      </p>
      <ol>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              To process payments. We may use the information that Users provide about themselves when placing an order only
              to provide services according from the order. We do not share this information with outside parties except to
              the extent necessary to provide this service;
            </font>
          </p>
        </li>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">To run promotions, contests, surveys or other features on this site;</font>
          </p>
        </li>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              To send information to Users, they agree to receive on theme that we think are interest for them;
            </font>
          </p>
        </li>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">To send periodic emails;</font>
          </p>
        </li>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              We may use email addresses to send users information and updates relating to their orders. It may also be used
              to respond to inquiries, questions and/or other requests. If a User decides to opt-in to our mailing list, they
              will receive an email which may contain company news, updates, related product or service information, etc. If
              at any time the User wishes to unsubscribe from receiving emails at a later date, we include detailed
              unsubscribe instructions at the bottom of each email.
            </font>
          </p>
        </li>
      </ol>
      <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>How We Protect User Personal Information?</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          We implement appropriate data collection, storage and processing practices and security measures to protect against
          unauthorized access, alteration, disclosure or destruction of personal information, usernames, passwords,
          transaction information and data stored on our website.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          The exchange of sensitive and personal data between the website and its users occurs through a secure SSL
          communication channel and is encrypted and protected with a digital signature.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>Sharing User Information</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          We do not sell, trade or rent user's personally identifiable information to others. We may share generic aggregated
          demographic information that is not linked to any personally identifiable information about visitors and users with
          our business partners, trusted affiliates and advertisers for the purposes described above.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>Conversion of Privacy Policy</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          ANOA has the discretion to update this privacy policy from time to time. When we do, we will revise the updated
          date at the bottom of this page. We encourage Users to check this page frequently to keep any changes informed
          about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your
          responsibility to periodically review this privacy policy and become aware of any modifications.
        </font>
      </p>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <br />
      </p>
      <ul>
        <li>
          <p align="justify" style={{ lineHeight: '100%', marginBottom: '0in' }}>
            <font face="Times New Roman, serif">
              <b>User Acceptance of these Terms</b>
            </font>
          </p>
        </li>
      </ul>
      <p align="justify" style={{ lineHeight: '100%', marginLeft: '0.5in', marginBottom: '0in' }}>
        <font face="Times New Roman, serif">
          By using this website, you signify your acceptance of these policies and terms of service. If you do not agree to
          this policy, please do not use our Site.
        </font>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
