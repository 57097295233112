import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  SEND_REQUEST_NEW_PASSWORD,
  SEND_REQUEST_NEW_PIN,
  SEND_REQUEST_NEW_GAUTH,
  SET_LAST_STEP,
  SEND_REQUEST_SMS_OTP,
  SMS_VERIFICATION_STEP,
  SET_PHONE_NUMBER,
  SET_NOTIFICATION_COUNT,
  SET_NOTIFICATION_LIST,
} from '../../@jumbo/constants/ActionTypes';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};

export const setRequestNewPassword = status => {
  return dispatch => {
    dispatch({
      type: SEND_REQUEST_NEW_PASSWORD,
      payload: status,
    });
  };
};

export const setRequestNewPin = status => {
  return dispatch => {
    dispatch({
      type: SEND_REQUEST_NEW_PIN,
      payload: status,
    });
  };
};

export const setRequestNewGauth = status => {
  return dispatch => {
    dispatch({
      type: SEND_REQUEST_NEW_GAUTH,
      payload: status,
    });
  };
};

export const setLastStep = url => {
  return dispatch => {
    dispatch({
      type: SET_LAST_STEP,
      payload: url,
    });
  };
};

export const setRequestOtp = status => {
  return dispatch => {
    dispatch({
      type: SEND_REQUEST_SMS_OTP,
      payload: status,
    });
  };
};

export const setSmsVerificationStep = status => {
  return dispatch => {
    dispatch({
      type: SMS_VERIFICATION_STEP,
      payload: status,
    });
  };
};

export const setPhonenumber = status => {
  return dispatch => {
    dispatch({
      type: SET_PHONE_NUMBER,
      payload: status,
    });
  };
};

export const setNotificationCount = status => {
  return dispatch => {
    dispatch({
      type: SET_NOTIFICATION_COUNT,
      payload: status,
    });
  };
};

export const setNotificationList = status => {
  return dispatch => {
    dispatch({
      type: SET_NOTIFICATION_LIST,
      payload: status,
    });
  };
};
