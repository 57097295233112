// import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtCard/CmtCardContent';
import { useSnackbar } from 'react-simple-snackbar';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';
import Translation from '../../../i18n/entries/Service';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { CheckCircle } from '@material-ui/icons';
import { setProcessing } from '../../../redux/actions/Processing';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { formatRupiah } from '../../../@jumbo/utils/commonHelper';
import { AccessTime } from '@material-ui/icons';
import CmtImage from '../../../@coremat/CmtImage';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { getFormattedDate } from '../../../@jumbo/utils/dateHelper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    height: '100%',
    '& .Cmt-card-content': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  titleRoot: {
    marginBottom: 20,
  },
  subTitle: {
    color: theme.palette.text.secondary,
    marginBottom: 25,
  },
  btnRoot: {
    height: 40,
  },
  cardHeader: {
    paddingBottom: 10,
    color: theme.palette.text.disabled,
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const Wallet = () => {
  const classes = useStyles();
  const [label, setLabel] = useState();
  const [step, setStep] = useState('STEP1');
  const [tokentransaction, setTokenTransaction] = useState('');
  const [headeramount, setHeaderamount] = useState('');
  const [headermessage, setHeadermessage] = useState('');
  const [currencyRate, setCurrencyrate] = useState([]);
  const [selectedCurrency, setSelectedcurrency] = useState('');
  const [paymentmethodlist, setPaymentmethodlist] = useState([]);
  const [selectedPaymentmethod, setSelectedpaymentmethod] = useState('');
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [timerDuedate, setTimerduedate] = useState('');
  const [responsePGA, setResponsePGA] = useState([]);
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const { is_processing } = useSelector(({ processing }) => processing);
  const { walletindex } = useParams();
  const timer = useRef(null);

  // const convertDatePayment = date => {
  //   var transactionDate = new Date(date);
  //   transactionDate.setDate(transactionDate.getDate() + 1);
  //   transactionDate.getTime();

  //   var interval = setInterval(function() {
  //     var now = new Date().getTime();

  //     var distance = transactionDate - now;

  //     // var days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString();
  //     var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
  //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
  //     var seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

  //     if (seconds.length < 2) {
  //       seconds = '0' + seconds;
  //       console.log('x');
  //     }

  //     setTimerduedate(hours + ':' + minutes + ':' + seconds);

  //     if (distance < 0) {
  //       clearInterval(interval);
  //       setTimerduedate('Expired');
  //     }
  //   }, 1000);
  // };
  const convertDatePayment = date => {
    var transactionDate = new Date(date);
    transactionDate.setDate(transactionDate.getDate() + 1);
    transactionDate.getTime();

    timer.current = setInterval(function() {
      var now = new Date().getTime();
      var distance = transactionDate - now;

      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
      var seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();

      if (seconds.length < 2) {
        seconds = '0' + seconds;
      }

      console.log('setTimerduedate', hours + ':' + minutes + ':' + seconds);
      setTimerduedate(hours + ':' + minutes + ':' + seconds);
    }, 1000);
  };
  useEffect(() => {
    if (timerDuedate !== '00:00:00') {
      return;
    } else {
      console.log('IS ZERO!');
      setTimerduedate('Expired');
      clearInterval(timer.current);
    }
  }, [timerDuedate, timer.current]);

  const handleChange = event => {
    setSelectedcurrency(event.target.value);
  };

  const handleChangePaymentmethod = event => {
    setSelectedpaymentmethod(event.target.value);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const onSubmitStep1 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += walletindex;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      temptrxheaderwalletindex: walletindex,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/insertnew',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP2');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const onSubmitStep2 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;
    String += parseInt(headeramount);
    String += headermessage;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      tabletrxheaderamount: parseInt(headeramount),
      tabletrxheadermessage: headermessage,
      signature: signature.toString(),
    };

    console.log(parameters);

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/storeamount',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setCurrencyrate(res.temptrxcurrencyrate);
          setStep('STEP3');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const onSubmitStep3 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;
    String += selectedCurrency;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      tabletrxheadercurrencycode: selectedCurrency,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/storecurrency',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setPaymentmethodlist(res.temptrxpaymentmethod);
          setStep('STEP4');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const onSubmitStep4 = async () => {
    dispatch(setProcessing(true));
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;
    String += selectedPaymentmethod;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      tabletrxheaderpaymentmethodindex: selectedPaymentmethod,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/storepaymentmethod',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setTransactionDetails(res.temptrxheader);
          convertDatePayment(res.temptrxheader[0].temptrxheaderinserttimestamp);
          onConfirmationTransaction(
            res.temptrxheader[0].temptrxheaderpaymentmethodcategory +
              ' - ' +
              res.temptrxheader[0].temptrxheaderpaymentmethoddescription,
          );
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
        dispatch(setProcessing(false));
      })
      .catch(error => {
        alert(error);
      });
  };

  const fcmpushtest = (msg, type) => {
    let fcmKey =
      'AAAA19zUvt0:APA91bH82c-E_-3GTw5oFpmh37agMooj9e4GZvYJUxKrwfCdB9MOoguZKgYsnNWc49hQMWhseaqCtkmRu-QZBqS4ZwlR2zok_7Eufpm-4KsCFRpVB9vFUI8ofArTbp5jRSF-vgFw1EP6';
    let fcmToken = localStorage.getItem('fcmToken');
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `key=${fcmKey}`,
    };

    let params = {
      notification: {
        title: 'DWA',
        body: msg,
        sound: 'default',
        vibrate: 1,
      },
      to: fcmToken,
      priority: 'high',
      data: {
        typenotif: type,
      },
    };
    axios
      .post('https://fcm.googleapis.com/fcm/send', params, { headers: headers })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onConfirmationTransaction = async paymentMethod => {
    dispatch(setProcessing(true));
    let storage = JSON.parse(localStorage.getItem('user'));
    Swal.fire({
      icon: 'warning',
      title: 'Security Confirmation',
      html:
        `This is your deposit details please check carefuly!<br/><br/>` +
        `Amount: ${headeramount}<br/>` +
        `Message: ${headermessage}<br/>` +
        `Currencry: ${selectedCurrency}<br/>` +
        `Payment: ${paymentMethod}`,
      text: `${storage.user2fatype} is required for this action`,
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
      },
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      denyButtonText: 'Cancel',
      showDenyButton: true,
      preConfirm: async pin => {
        let index = JSON.parse(localStorage.getItem('user'));

        let token = await handleGetTokenRecaptcha();
        var String = '';

        String += 'WEBSITE';
        String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
        String += index.userindex;
        String += index.usertoken;
        String += tokentransaction;
        String += storage.user2fatype;
        String += pin;

        let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

        let parameters = {
          platform: 'WEBSITE',
          gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
          userindex: index.userindex,
          username: index.username,
          tokenlogin: index.usertoken,
          tokentrans: tokentransaction,
          user2fatype: index.user2fatype,
          user2fapintoken: pin,
          signature: signature.toString(),
        };
        return axios
          .post(
            process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/store',
            parameters,
          )
          .then(function(response) {
            let res = response.data;
            console.log(res);
            if (res.status.code === '080000000000') {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: `Deposit success please wait confirmation!`,
              });
              setResponsePGA(res.tablerespgacreate);
              setStep('STEP5');
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: Translation.getServiceLanguage(res.status.code),
              });
              throw new Error(res.status.errormessage);
            }
            dispatch(setProcessing(false));
            return res;
          })
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.isDenied) {
        Swal.fire({
          icon: 'info',
          title: 'Sorry!',
          text: 'Changes are not saved!',
        });
        handleBackStep5();
      }
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  const handleBackStep3 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/back/storecurrency',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP2');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleBackStep4 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/back/storepaymentmethod',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP3');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleBackStep5 = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let token = await handleGetTokenRecaptcha();
    var String = '';

    String += 'WEBSITE';
    String += process.env.REACT_APP_IS_DEMO ? 'PASSS' : token;
    String += index.userindex;
    String += index.usertoken;
    String += tokentransaction;

    let signature = cryptoJS.HmacSHA256(String, process.env.REACT_APP_SECRET_KEY_HMAC);

    let parameters = {
      platform: 'WEBSITE',
      gtoken: process.env.REACT_APP_IS_DEMO ? 'PASSS' : token,
      userindex: index.userindex,
      username: index.username,
      tokenlogin: index.usertoken,
      tokentrans: tokentransaction,
      signature: signature.toString(),
    };

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/deposit/v01/back/store',
        parameters,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.code === '080000000000') {
          setTokenTransaction(res.status.tokentrans);
          setStep('STEP4');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: Translation.getServiceLanguage(res.status.code),
          });
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  useEffect(() => {
    fcmpushtest();
    const loadScriptRecaptcha = async () => {
      await loadScriptByURL(
        'recaptcha-key',
        `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        async function() {
          openSnackbar('Fetching data...', 60000);

          closeSnackbar();
        },
      );
    };
    loadScriptRecaptcha().catch(console.error);
    // eslint-disable-next-line
    },
    [ dispatch ]
  );

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardContent>
        <Box className={classes.root}>
          <Stepper activeStep={parseInt(step.substring(4, 5))} alternativeLabel>
            <Step key={'Deposit'}>
              <StepLabel>Deposit</StepLabel>
            </Step>
            <Step key={'Amount'}>
              <StepLabel>Amount</StepLabel>
            </Step>
            <Step key={'Currency'}>
              <StepLabel>Currency</StepLabel>
            </Step>
            <Step key={'Payment method'}>
              <StepLabel>Payment method</StepLabel>
            </Step>
            <Step key={'Done'}>
              <StepLabel>Done</StepLabel>
            </Step>
          </Stepper>
          <Box>
            <Box>
              {// eslint-disable-next-line
              step == "STEP1" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please click button below to Deposit.</Alert>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep1}>
                          {'Create'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              <br />

              {// eslint-disable-next-line
              step == "STEP2" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12} md={12} desktop={9} xl={9}>
                      <Alert severity="info">Please, set amount and message for deposit reminder.</Alert>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} md={6} desktop={9} xl={9}>
                        <TextField
                          type={'text'}
                          fullWidth
                          id="uncontrolled"
                          label={'Amount'}
                          defaultValue={headeramount}
                          onChange={event => setHeaderamount(event.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6} md={6} desktop={9} xl={9} ml={3}>
                        <TextField
                          type={'text'}
                          fullWidth
                          id="uncontrolled"
                          label={'Message'}
                          defaultValue={headermessage}
                          onChange={event => setHeadermessage(event.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep2}>
                          {'Next'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == "STEP3" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    {/* <Grid item xs={12} md={12} desktop={9} xl={9}> */}
                    Currency details
                    {/* </Grid> */}
                    <Divider />
                    <br />
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedCurrency}
                      onChange={handleChange}
                      label="Currency">
                      {currencyRate.length > 1 ? (
                        <MenuItem key={0} value="">
                          <em>-- Select Currency --</em>
                        </MenuItem>
                      ) : null}
                      {currencyRate.length > 0 &&
                        currencyRate.map((data, index) => {
                          return (
                            <MenuItem key={index + 1} value={data.temptrxcurrencyratecurrencycode}>
                              {data.temptrxcurrencyratecurrencycode} - {data.temptrxcurrencyratecurrencydescription}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? null : (
                        <Button disabled={step !== 'STEP3'} onClick={handleBackStep3} className={classes.backButton}>
                          Back
                        </Button>
                      )}
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep3}>
                          {'Confirm'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == "STEP4" ? (
                <div style={{ marginBottom: 10, maxWidth: '800px', margin: 'auto' }}>
                  <Grid container spacing={3} direction="column">
                    {/* <Grid item xs={12} md={12} desktop={9} xl={9}> */}
                    Payment method list
                    {/* </Grid> */}
                    <Divider />
                    <br />
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedPaymentmethod}
                      onChange={handleChangePaymentmethod}
                      label="Payment method">
                      {paymentmethodlist.length > 1 ? (
                        <MenuItem key={0} value="">
                          <em>-- Select Payment Method --</em>
                        </MenuItem>
                      ) : null}
                      {paymentmethodlist.length > 0 &&
                        paymentmethodlist.map((data, index) => {
                          return (
                            <MenuItem key={index + 1} value={data.tablepaymentmethodindex}>
                              {data.tablepaymentmethodcategory} - {data.tablepaymentmethodtitle}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <Grid item xs={12} md={12} desktop={9} xl={9} align="right">
                      {is_processing ? null : (
                        <Button disabled={step !== 'STEP4'} onClick={handleBackStep4} className={classes.backButton}>
                          Back
                        </Button>
                      )}
                      {is_processing ? (
                        <Button variant="contained" color="primary">
                          Processing...
                        </Button>
                      ) : (
                        <Button variant="contained" color="primary" onClick={onSubmitStep4}>
                          {'Confirm'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : null}

              {// eslint-disable-next-line
              step == "STEP5" ? (
                <div style={{ marginBottom: 10, maxWidth: '500px', margin: 'auto' }}>
                  <CmtCard className={classes.cardRoot}>
                    <CmtCardContent>
                      <GridContainer>
                        <Grid container justifyContent="center" xs={12} sm={12}>
                          <AccessTime style={{ width: 75, height: 75, color: 'orange' }} />
                        </Grid>
                        <Grid container justifyContent="center" xs={12} sm={12}>
                          <h3 style={{ fontWeight: 'bold', color: 'orange' }}>Transaction Pending</h3>
                        </Grid>
                        <Grid container justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                          <h3 style={{ fontWeight: 'bold', fontSize: 20 }}>
                            {formatRupiah(transactionDetails[0].temptrxheadertotal, 'IDR ')}
                          </h3>
                        </Grid>

                        <Grid item justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                          <CmtCard className={classes.cardRoot}>
                            <CmtCardContent>
                              <GridContainer>
                                <Grid item container justifyContent="center" xs={12} sm={12}>
                                  <CmtImage src={'/images/dwa_logo.png'} style={{ height: 50 }} />
                                </Grid>

                                <Grid item container justifyContent="center" xs={12} sm={12}>
                                  <label style={{ fontWeight: 'bolder' }}>You haven't complete transaction</label>
                                </Grid>

                                <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                  <label style={{ color: 'grey' }}>Amount </label>
                                </Grid>
                                <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                  <label style={{ fontWeight: 'bolder' }}>{transactionDetails[0].temptrxheaderamount}</label>
                                </Grid>

                                <Grid container justifyContent="flex-start" xs={4} sm={4}>
                                  <label style={{ color: 'grey' }}>Status</label>
                                </Grid>
                                <Grid container justifyContent="flex-end" xs={8} sm={8}>
                                  <label style={{ fontWeight: 'bolder', color: 'red' }}>
                                    {transactionDetails[0].temptrxheaderstatuscode}
                                  </label>
                                </Grid>

                                <Grid container justifyContent="center" xs={12} sm={12} style={{ marginTop: 20 }}>
                                  <Alert severity="warning" style={{ cursor: 'pointer', width: '100%' }}>
                                    <strong>
                                      <h5>Please pay this transaction before {timerDuedate}.</h5>
                                      <h4>{responsePGA.pay_code}</h4>
                                    </strong>
                                  </Alert>
                                </Grid>
                              </GridContainer>
                            </CmtCardContent>
                          </CmtCard>
                        </Grid>

                        <Grid item justifyContent="center" xs={12} sm={12}>
                          <GridContainer>
                            <Grid container justifyContent="flex-start" xs={12} sm={12} style={{ marginBottom: 20 }}>
                              <label style={{ fontWeight: 'bolder' }}>Transaction Details</label>
                            </Grid>
                            {/* <Grid container justifyContent="flex-start" xs={12} sm={12} style={{ marginBottom: 20 }}>
                              <img
                                src={payment['paymentMethod'] ? payment['paymentMethod'].icon_url : null}
                                style={{ width: 150, height: 60 }}
                                alt={'alt_paymentMethod'}
                              />
                            </Grid> */}

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Description</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {transactionDetails[0].temptrxheaderdescription}
                              </label>
                            </Grid>
                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Currency</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {transactionDetails[0].temptrxheadercurrencydescription}
                              </label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Payment Method</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {transactionDetails[0].temptrxheaderpaymentmethodcategory}
                              </label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Created</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {getFormattedDate(transactionDetails[0].temptrxheadertimestamp)}
                              </label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Bank</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {transactionDetails[0].temptrxheaderpaymentmethodcode}
                              </label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Fee</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails[0].temptrxheaderfee)}
                              </label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Total</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails[0].temptrxheadertotal, 'IDR ')}
                              </label>
                            </Grid>

                            {/* <Grid container justifyContent="flex-start" xs={4} sm={4}>
                              <label style={{ color: 'grey' }}>Surcharge</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails.surcharge, 'IDR ')}
                              </label>
                            </Grid>

                            <Grid container justifyContent="flex-start" xs={4} sm={4} style={{ marginTop: 10 }}>
                              <label style={{ color: 'grey' }}>Total</label>
                            </Grid>
                            <Grid container justifyContent="flex-end" xs={8} sm={8} style={{ marginTop: 10 }}>
                              <label style={{ fontWeight: 'bolder' }}>
                                {formatRupiah(transactionDetails.amount, 'IDR ')}
                              </label>
                            </Grid> */}
                          </GridContainer>
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <img
                            src={'https://kapilerindonesia.com/asset/image/news/customer-service.png'}
                            style={{ width: 50, height: 50 }}
                            alt={'alt_customerservice'}
                          />
                        </Grid>
                        <Grid item xs={10} sm={10}>
                          <label>Please contact DWA Customer service if you have problem with payments.</label>
                        </Grid>

                        <Grid item justifyContent="center" xs={12} sm={12}>
                          <Button variant="contained" color="primary" size="large" style={{ width: '100%' }}>
                            {'Already Paid'}
                          </Button>
                        </Grid>
                      </GridContainer>
                    </CmtCardContent>
                  </CmtCard>
                </div>
              ) : null}
            </Box>
            {/* )} */}
          </Box>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Wallet;
