import React,{Profiler}from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import SnackbarProvider from 'react-simple-snackbar';
import 'react-notifications-component/dist/theme.css';
// import { ReactNotifications } from 'react-notifications-component';
export const store = configureStore();

const App = () => (
  <SnackbarProvider>
    {/* <ReactNotifications /> */}
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppContextProvider>
          <AppWrapper>
            <Switch>
            <Profiler onRender={(id, phase, actualTime, baseTime, startTime, commitTime) => {
                // console.log(`${id}'s ${phase} phase:`);
                // console.log(`Actual time: ${actualTime}`);
                // console.log(`Base time: ${baseTime}`);
                // console.log(`Start time: ${startTime}`);
                // console.log(`Commit time: ${commitTime}`);
              }}>
              <Routes />
              </Profiler>
            </Switch>
          </AppWrapper>
        </AppContextProvider>
      </ConnectedRouter>
    </Provider>
  </SnackbarProvider>
);

export default App;
