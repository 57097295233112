import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import CmtCard from '../../../@coremat/CmtCard';
import CmtCardContent from '../../../@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtCardActions from '../../../@coremat/CmtCard/CmtCardActions';
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Check from '@material-ui/icons/Check';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  card: {
    overflow: 'unset',
  },
  cardContent: {
    position: 'relative',
    paddingBottom: 0,
  },
  cardContentInner: {
    position: 'relative',
    paddingBottom: 40,
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.menus'} /> },
  { label: <IntlMessages id={'pages.transaction'} /> },
  { label: <IntlMessages id={'pages.transaction.success'} />, isActive: true },
];

const handleHome = () => {
  window.location = '/dashboard';
};

const SamplePage = () => {
  const classes = useStyles();

  return (
    <PageContainer heading={<IntlMessages id="pages.transaction" />} breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Box className={classes.section}>
            <Typography component="h3" variant="inherit" className={classes.sectionHeading}>
              <IntlMessages id="pages.transaction.description" />
            </Typography>
            <CmtCard className={classes.card}>
              <CmtCardContent className={classes.cardContent}>
                <Box className={classes.cardContentInner}>
                  <Alert severity="success" style={{ marginBottom: 50 }}>
                    Transaction Success !
                  </Alert>

                  <CmtCardActions className={classes.actionButtons}>
                    <Button variant="contained" color="primary" size="large" startIcon={<Check />} onClick={handleHome}>
                      OK
                    </Button>
                  </CmtCardActions>
                </Box>
              </CmtCardContent>
            </CmtCard>
          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default SamplePage;
