import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { setAuthUser } from 'redux/actions/Auth';
// import { ProfileControllers } from '../../../services/profile';
import About from './Components/About';
import Contact from './Components/Contact';
import Header from './Components/Header';
import cryptoJS from 'crypto-js';
import axios from 'axios';
import { useSnackbar } from 'react-simple-snackbar';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
  pageFull: {
    width: '100%',
  },
  profileSidebar: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  profileMainContent: {
    '@media screen and (min-width: 1280px) and (max-width: 1499px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}));

const optionsSnackbar = {
  position: 'top-right',
  style: {
    marginTop: 60,
    backgroundColor: '#42B549',
  },
  closeStyle: {
    display: 'none',
  },
};

const ProfilePage = ({ variant = 'default' }) => {
  const [openSnackbar, closeSnackbar] = useSnackbar(optionsSnackbar);
  const classes = useStyles({ variant });
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState([]);
  const [walletInfo, setWalletInfo] = useState([]);
  //const [setComplete] = useState(0);
  const [isLoaded, setIsloaded] = useState(true);
  const [fields, setFields] = useState({});
  const [tabValue, setTabValue] = useState('about');

  const handleInputChange = e => {
    let { value, name } = e.target;
    let field = { ...fields };
    field[name] = value;
    setFields(field);
    // eslint-disable-next-line
    if (name == 'resetMethod' && value == 'pin') {
      window.location.href = '/reset-new-pin';
      // eslint-disable-next-line
    } else if (name == 'resetMethod' && value == '2fa') {
      window.location.href = '/reset-new-gauth';
      //requestReset2fa();
    }
  };
  const callProfileData = async token => {
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        platform: 'WEBSITE',
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {};

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/profile',
        parameters,
        headers,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.status === 1) {
          setProfileData(res.data);
        } else {
          let arrError = [];

          if (res.status.message.length > 0) {
            for (var i = 0; i < res.status.message.length; i++) {
              arrError.push(res.status.message[i]);
            }
          }

          if (arrError.find(item => item.code === '888999999940')) {
            setTimeout(() => {
              dispatch(setAuthUser(null));
            }, 2500);
          }
        }
      })
      .catch(function(error) {
        alert(error);
      });

    setIsloaded(false);
  };

  const callWalletInfo = async () => {
    let index = JSON.parse(localStorage.getItem('user'));

    let headers = {
      headers: {
        platform: 'WEBSITE',
        gtoken: 'PASSS',
        userindex: index.userindex,
        tokenlogin: index.usertoken,
        signature: 'ed0fd6ef4d14c339d3550a31e92d7ef5a263c5ec4f00e233c129f058ef7241d7',
      },
    };

    let parameters = {};

    await axios
      .post(
        process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/wallet/v01/info',
        parameters,
        headers,
      )
      .then(response => {
        let res = response.data;
        console.log(res);
        if (res.status.status === 1) {
          setWalletInfo(res.data);
        } else {
          let arrError = [];

          if (res.status.message.length > 0) {
            for (var i = 0; i < res.status.message.length; i++) {
              arrError.push(res.status.message[i]);
            }
          }

          if (arrError.find(item => item.code === '888999999940')) {
            setTimeout(() => {
              dispatch(setAuthUser(null));
            }, 2500);
          }
        }
      })
      .catch(function(error) {
        alert(error);
      });

    setIsloaded(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleGetTokenRecaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(
    () => {
      // load the script by passing the URL

      const loadScriptRecaptcha = async () => {
        // await loadScriptByURL(
        //   'recaptcha-key',
        //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
        //   async function() {
        openSnackbar('Fetching data...', 60000);
        // let recapToken = await handleGetTokenRecaptcha();
        await callProfileData();
        await callWalletInfo();
        closeSnackbar();
        //   },
        // );
      };
      loadScriptRecaptcha().catch(console.error);
    },
    // eslint-disable-next-line
    [dispatch],
  );

  console.log(walletInfo);

  return (
    <React.Fragment>
      {/* {userDetail && ( */}
      <Box className={classes.pageFull}>
        <Header
          classes={classes}
          userDetail={profileData}
          isLoaded={isLoaded}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          handleInputChange={handleInputChange}
          fields={fields}
        />
        <GridContainer>
          <Grid item xs={12} lg={4} className={classes.profileSidebar}>
            <Box mb={6}>
              <Contact
                userDetail={profileData}
                walletInfo={walletInfo}
                isLoaded={isLoaded}
                getRecaptcha={handleGetTokenRecaptcha}
              />
            </Box>
            <Box mb={6}>{/* <Friends friends={userDetail.friends} /> */}</Box>
            <Box mb={6}>{/* <UserPhotos /> */}</Box>
          </Grid>
          <Grid item xs={12} lg={8} className={classes.profileMainContent}>
            <Box mb={6}>
              <About userDetail={[]} />
            </Box>
            <Box mb={6}>{/* <Biography /> */}</Box>
            {/* <Events events={userDetail.events} /> */}
          </Grid>
        </GridContainer>
      </Box>
      {/* )} */}
    </React.Fragment>
  );
};

export default ProfilePage;
