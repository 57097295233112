import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, IconButton, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setRequestNewPassword, setAuthUser } from '../../../../redux/actions/Auth';
import cryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_request_new_password } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setErrorPassword] = useState('');
  const [passwordErrorisShow, setPasswordErrorisShow] = useState(false);

  const [c_password, setCpassword] = useState('');
  const [cpasswordError, setErrorCPassword] = useState('');
  const [cpasswordErrorisShow, setCpasswordErrorisShow] = useState(false);

  const [oldPassword, setOldpassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [oldPasswordErrorisShow, setOldPasswordErrorisShow] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  useEffect(() => {
    let timeOutStopper = null;
    if (send_request_new_password) {
      setOpen(true);

      timeOutStopper = setTimeout(() => {
        dispatch(setRequestNewPassword(false));
        history.push('/signin');
      }, 3000);
    }

    return () => {
      if (timeOutStopper) clearTimeout(timeOutStopper);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send_request_new_password]);

  const requestSubmit = async () => {
    if (oldPassword !== '' && password !== '' && c_password !== '') {
      if (password !== c_password) {
        let arrClear = [];
        setErrorPassword('Password must match!');
        setPasswordErrorisShow(true);

        setErrorCPassword('Password must match!');
        setCpasswordErrorisShow(true);
        arrClear.push('password');
        arrClear.push('cpassword');
      } else {
        let index = JSON.parse(localStorage.getItem('user'));

        let headers = {
          headers: {
            platform: 'WEBSITE',
            gtoken: 'PASSS',
            userindex: index.userindex,
            tokenlogin: index.usertoken,
            signature: 'dsafdsafdsafdsaf',
          },
        };

        let parameters = {
          userpasswordold: oldPassword,
          userpasswordnew: password,
          signature: 'f3c75e6459bfa9af3fbdf631fe4df160ac40db36c2785004d6e8086c69d27183',
        };

        return new Promise((resolve, reject) => {
          axios
            .post(
              process.env.REACT_APP_API_ADDRESS + '/' + process.env.REACT_APP_API_PREFIX + '/user/v01/changepassword',
              parameters,
              headers,
            )
            .then(function(response) {
              let res = response.data;
              console.log(res);
              if (res.status.status === 1) {
                Swal.fire({
                  icon: 'success',
                  title: 'Congratulations!',
                  text: 'Your password has successfully changed',
                });
                setTimeout(() => {
                  window.location = '/signin';
                }, 2000);
                sessionStorage.removeItem('set_last_url');
                dispatch(setAuthUser(null));
                resolve();
              } else {
                let arrError = [];
                let arrClear = [];

                if (res.status.message.length > 0) {
                  for (var i = 0; i < res.status.message.length; i++) {
                    arrError.push(res.status.message[i]);
                  }
                }

                if (arrError.find(item => item.code === '888005116005')) {
                  setErrorPassword('only allowed alphanumeric and . - _');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                }

                if (arrError.find(item => item.code === '888005117005')) {
                  setErrorPassword('please provide new password');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                }

                if (arrError.find(item => item.code === '888005116005')) {
                  setOldPasswordError('please provide Old Password');
                  setOldPasswordErrorisShow(true);
                  arrClear.push('oldpassword');
                }

                if (arrError.find(item => item.code === '888005116010')) {
                  setOldPasswordError('old password is not valid');
                  setOldPasswordErrorisShow(true);
                  arrClear.push('oldpassword');
                }

                if (arrError.find(item => item.code === '888005116020')) {
                  setOldPasswordError('old password length should more than 8 character');
                  setOldPasswordErrorisShow(true);
                  arrClear.push('oldpassword');
                }

                if (arrError.find(item => item.code === '888005116025')) {
                  setOldPasswordError('old password length should less than 16 character');
                  setOldPasswordErrorisShow(true);
                  arrClear.push('oldpassword');
                }

                if (arrError.find(item => item.code === '888005117020')) {
                  setErrorPassword('new password length should more than 8 character');
                  setPasswordErrorisShow(true);
                  arrClear.push('password');
                  arrClear.push('cpassword');
                }

                if (arrError.find(item => item.code === '888005117025')) {
                  setErrorPassword('new password length should less than 16 character');
                  setPasswordErrorisShow(true);
                  setErrorCPassword('new password length should less than 16 character');
                  setCpasswordErrorisShow(true);
                  arrClear.push('password');
                  arrClear.push('cpassword');
                }

                if (arrError.find(item => item.code === '888999999940')) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Unauthorize',
                  });
                  sessionStorage.removeItem('set_last_url');
                  dispatch(setAuthUser(null));
                }
                // if(arrError.find((item) => item.code === "888005117025")){
                //   setErrorPassword("new password length should less than 16 character");
                //   setPasswordErrorisShow(true);
                //   arrClear.push("password");
                //   arrClear.push("cpassword");
                // }

                reject(arrClear);
              }
            });
        });
      }
    } else {
      if (password === '') {
        setErrorPassword('Password must not be empty!');
        setPasswordErrorisShow(true);
      }

      if (c_password === '') {
        setErrorCPassword('Confirm password must not be empty!');
        setCpasswordErrorisShow(true);
      }
      if (oldPassword === '') {
        setOldPasswordError("Old password must not be empty!'");
        setOldPasswordErrorisShow(true);
      }
    }
  };

  const handleGetTokenRecaptcha = () => {
    //setLoading(true);
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => reject(error));
      });
    });
  };

  const clearState = (clearParams = []) => {
    if (clearParams.includes('password')) {
      setPassword('');
      setErrorPassword('');
      setPasswordErrorisShow(false);
    }

    if (clearParams.includes('cpassword')) {
      setCpassword('');
      setErrorCPassword('');
      setCpasswordErrorisShow(false);
    }

    if (clearParams.includes('oldpassword')) {
      setOldpassword('');
      setOldPasswordError('');
      setOldPasswordErrorisShow(false);
    }

    if (clearParams.length < 1) {
      setPassword('');
      setErrorPassword('');
      setPasswordErrorisShow(false);
      setCpassword('');
      setErrorCPassword('');
      setCpasswordErrorisShow(false);
      setOldpassword('');
      setOldPasswordError('');
      setOldPasswordErrorisShow(false);
    }
  };

  const onSubmit = async () => {
    //  dispatch(setProcessing(true));
    await requestSubmit()
      .then(() => {
        setTimeout(() => {
          clearState();
        }, 2500);
      })
      .catch(arrClear => {
        setTimeout(() => {
          clearState(arrClear);
        }, 2500);
      });
    // dispatch(setProcessing(false));
  };

  const loadScriptByURL = async (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.id = id;
      script.onload = function() {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  useEffect(() => {
    const loadScriptRecaptcha = async () => {
      // await loadScriptByURL(
      //   'recaptcha-key',
      //   `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      //   function() {
      console.log('Script loaded!');
      //   },
      // );
    };

    loadScriptRecaptcha().catch(console.error);

    // load the script by passing the URL
  }, [dispatch]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/dwa_logo.png'} style={{ height: 100 }} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Change Password
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            Password has been changed, now you're logged out.
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              error={oldPasswordErrorisShow}
              id={oldPasswordErrorisShow ? 'outlined-error-helper-text' : ''}
              type="password"
              label={'Old Password'}
              fullWidth
              onChange={event => setOldpassword(event.target.value)}
              value={oldPassword}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={oldPasswordError}
            />
          </div>
          <div className={'mb-5'}>
            <TextField
              error={passwordErrorisShow}
              id={passwordErrorisShow ? 'outlined-error-helper-text' : ''}
              type="password"
              label={'New Password'}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              value={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={passwordError}
            />
          </div>
          <div className={'mb-5'}>
            <TextField
              error={cpasswordErrorisShow}
              id={cpasswordErrorisShow ? 'outlined-error-helper-text' : ''}
              type="password"
              label={'Confirm Password'}
              fullWidth
              onChange={event => setCpassword(event.target.value)}
              value={c_password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={cpasswordError}
            />
          </div>
          <div className={'mb-5'}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              Change Password
            </Button>
          </div>

          <div>
            {/* <Typography>
              Don't remember your email?
              <span className={'ml-2'}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </span>
            </Typography> */}
          </div>
        </form>
        <p style={{ fontSize: 11, alignSelf: 'center' }}>{process.env.REACT_APP_VERSION}</p>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
